import { WithDialog } from "../../DialogCreate";
import { cn } from '@bem-react/classname';

export const cnSaveDialog = cn('SaveDialog');

export namespace NSaveDialog {

    interface PropsOwn {
    }

    interface Rezult {
        name: string;
    }

    export type Props = PropsOwn & WithDialog<Rezult>;
}