import { useSelector } from "react-redux";
import { getItemsField, getItemsItemById } from "./selectors";
import {
    itemsChangeField,
    itemsChange,
    itemsSelect,
    itemsSystemAdd,
    itemsSystemCreate,
    itemsSystemRemove,
    itemsSystemSet,
    itemsRemoveSelected
} from './actions';
import { NItems } from "./interface";
import { useAppDispatch } from "../hooks";

/**
 * hook to global items reducer actions
 */
export const useItemsActions = () => {

    const dispatch = useAppDispatch();

    const changeField = (...args: Parameters<typeof itemsChangeField>) => {
        dispatch(itemsChangeField(...args));
    }

    return {
        changeField
    };
};

/**
 * system item actions
 * @returns 
 */
export const useSystemActions = () => {

    const dispatch = useAppDispatch();

    const createSystem = (...args: Parameters<typeof itemsSystemCreate>) => {
        dispatch(itemsSystemCreate(...args));
    };

    /**
     * set system layers or clear it if payload null
     * @param args 
     */
    const setSystem = (...args: Parameters<typeof itemsSystemSet>) => {
        dispatch(itemsSystemSet(...args));
    };

    return {
        createSystem,
        setSystem
    };
};

/**
 * items reducer actions
 * @returns 
 */
export const useItemActions = () => {

    const dispatch = useAppDispatch();

    /**
     * change item data
     * @param args 
     */
    const changeItem = (...args: Parameters<typeof itemsChange>) => {
        dispatch(itemsChange(...args));
    };

    /**
     * set item as selected
     * @param args 
     */
    const selectItem = (...args: Parameters<typeof itemsSelect>) => {
        dispatch(itemsSelect(...args));
    };

    /**
     * remove selected item
     * @param args 
     */
    const removeSelected = (...args: Parameters<typeof itemsRemoveSelected>) => {
        dispatch(itemsRemoveSelected(...args));
    };

    /**
     * set current items to edit  
     * use in Text item type
     * @param value 
     */
    const editItem = (value: NItems.Reducer['edit']) => {
        dispatch(itemsChangeField({ field: 'edit', value }));
    };

    return {
        changeItem,
        selectItem,
        editItem,
        removeSelected
    }
};


/**
 * selet any item actions
 * @returns 
 */
export const useSelectItemAction = () => {

    const {
        selectItem
    } = useItemActions();

    return selectItem
};


/**
 * get item, its state from store & prepare its action 
 * @param id 
 * @returns 
 */
export const useItem = (id: string) => {

    const item = useSelector(getItemsItemById(id));

    const isSelected = useSelector(getItemsField('select')) === id;
    const isEditing = useSelector(getItemsField('edit')) === id;
    const isCropped = useSelector(getItemsField('crop')) === id;

    const actions = useItemActions();

    /**
     * change current item
     * @param data 
     */
    const changeItem = (data: Partial<typeof item>) => {
        actions.changeItem({
            ...data,
            id: item.id,
            props: {
                ...item.props,
                ...data.props
            } as any
        });
    };

    /**
     * select current item
     */
    const selectItem = () => {
        actions.selectItem(item.id);
    };

    /**
     * set current item as edit
     */
    const setAsEdit = () => {
        actions.editItem(item.id);
    };

    return {
        item,
        isSelected,
        isEditing,
        isCropped,
        changeItem,
        selectItem,
        setAsEdit
    }
};

export const useSelectItem = () => {

    const dispatch = useAppDispatch();

    const select = useSelector(getItemsField('select'));

    const item = useSelector(getItemsItemById(select || ''));

    const changeSelectedItem = (data: Partial<typeof item>) => {
        dispatch(itemsChange({
            ...data,
            id: item.id,
            props: {
                ...item.props,
                ...data.props
            } as any
        }));
    };

    return {
        item,
        changeSelectedItem
    };
};