import { put, takeLeading } from 'redux-saga/effects';
import { logError, select } from '../sagas';
import { SETTINGS_CHANGE_FIELD } from './actions.types';
import { getKonvaSettings, getKonvaSettingsField } from '../konvaSettings/selectors';
// import { SETTINGS_CHANGE_LAYOUT } from '../konvaSettings/actions.types';
import { NKonvaSettings } from '../konvaSettings/interface';
import { settingsChangeLayout } from '../konvaSettings/actions';
import { baseProps } from '../../constants';

const sagaName = 'konvaSettings';

export function* konvaSettingsSaga() {
}