import { konvaSettings } from './reducer';
import { ModuleTypePrepare, ModuleReducerMapPrepare } from '../types';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { konvaSettingsSaga } from './saga';

const reducers = {
    konvaSettings
}

type KonvaSettingsModuleType = ModuleTypePrepare<typeof reducers>;

type KonvaSettingsReducerMapType = ModuleReducerMapPrepare<typeof reducers>;

export function getKonvaSettingsModule(): ISagaModule<KonvaSettingsModuleType> {
    return {
        id: "konvaSettings",
        //   middlewares: [
        //     // routerMiddleware(history),
        //   ],
        reducerMap: reducers as KonvaSettingsReducerMapType,
        //   reducerMap as { [s in keyof IBaseReduxModule]: Reducer<ReturnType<IBaseReduxModule[s]>, AnyAction> },
        sagas: [
            konvaSettingsSaga
        ]
    };
  };