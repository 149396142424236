import React, { FC, useCallback, useEffect, useRef, useState } from 'react';
import { cnApp, NApp } from './App.index';
import { connect } from 'react-redux';
import './App.scss';
import { KonvaWrapper } from './blocks/Konva/Konva';
import { LayersMenu, TemplatesMenu } from './blocks/Menu/Menu';
import { SettingsMenu } from './blocks/SettingsMenu/SettingsMenu';
import { Zoomer } from './blocks/Zoomer';
import { v1 as uuid } from 'uuid';
import { KonvaComponents } from './blocks/Konva';
import { TopMenu } from './blocks/TopMenu/TopMenu';
import { ExtensionBlocksContext, useBlocks } from './extensions/Blocks/Blocks.hooks';
import { DebugContext, DebugMenu, useDebug } from './debug/debug';
import { App, AppContent } from './App.styled';
import { EnvironmentContext, useEnvironment } from './environment';
import { useFontSelectorContext } from './blocks/FontsSelector/FontSelector.context';
import { usePryanikyEmbedding } from './embed/pryaniky';
import { ZoomerContext, initialZoomerState } from './blocks/Zoomer/Zoomer.context';
import { useSelectItem } from './redux/items/hooks';
import { useAppDispatch } from './redux/hooks';
import { itemsAdd } from './redux/items/actions';

const Application: FC<NApp.Props> = ({
}) => {

  const {
    item: selectedItem
  } = useSelectItem();

  const dispatch = useAppDispatch();

  const blocksState = useBlocks();

  const debugState = useDebug();

  const environment = useEnvironment();

  const [ zoomerState, setZoomerState ] = useState(initialZoomerState);
  const zoomerStateRef = useRef(zoomerState);
  useEffect(() => {
    zoomerStateRef.current = zoomerState;
  }, [zoomerState]);

  const changeZoomerContext = useCallback((ref: typeof zoomerState['ref']) => {
    setZoomerState({
      ...zoomerStateRef.current,
      ref
    })
  }, []);

  const {
    state: fontSelectorContextState,
    Provider: FontsSelectorProvider
  } = useFontSelectorContext();

  useEffect(() => {
    document.title = 'Editor';
  }, []);

  const { getAdditionalStyles } = usePryanikyEmbedding();

  useEffect(() => {
    getAdditionalStyles?.();
  }, []);

  useEffect(() => {
    const vKey = 86;
    const cKey = 67;
    const escKey = 27;
    const copyPasteListaner = (e: KeyboardEvent) => {
      if(e.ctrlKey) {
        if(e.keyCode === cKey) {
          if(selectedItem) {
            const text = JSON.stringify(selectedItem);
            navigator.clipboard.writeText(text);
          }
        }
        if(e.keyCode === vKey) {
          console.log('PASTE');
          try {
            navigator.clipboard.readText().then(data => {
              const item = {
                ...JSON.parse(data),
                id: uuid()
              };
              if(!Object.typedKeys(KonvaComponents).includes(item.type)) return ;
              dispatch(itemsAdd(item));
            });
          } catch (error) {
            
          }
        }
        if(e.keyCode === escKey) {

        }
      }
    }
    document.addEventListener('keydown', copyPasteListaner);
    return () => {
      document.removeEventListener('keydown', copyPasteListaner);
    }
  }, [selectedItem]);

  return (
    <>
      <a id="downloadAnchorElem" style={{ display: 'none'}}></a>
      <EnvironmentContext.Provider value={environment} >
        <DebugContext.Provider value={debugState}>
          <FontsSelectorProvider value={fontSelectorContextState}>
            <ExtensionBlocksContext.Provider value={blocksState}>
              <ZoomerContext.Provider value={{ ...zoomerState, changeZoomerContext  }}>
                <TopMenu />
                <App className={cnApp()}>
                  <AppContent id={'appContent'} className={cnApp('Content')}>
                    <Zoomer>
                      <KonvaWrapper />
                    </Zoomer>
                    <SettingsMenu />
                  </AppContent>
                  <LayersMenu className={cnApp('Menu', { layers: true })} openedValue={'layers'} />
                  <TemplatesMenu className={cnApp('Menu', { templates: true })} openedValue={'templates'} />
                </App>
              </ZoomerContext.Provider>
            </ExtensionBlocksContext.Provider>
            <DebugMenu />
          </FontsSelectorProvider>
        </DebugContext.Provider>
      </EnvironmentContext.Provider>
    </>
  );
}

export default Application;
