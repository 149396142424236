import React, { FC, useState } from 'react';
import { NZindex } from './Zindex.index';
import { Layers, ArrowUpward, ArrowDownward } from '@mui/icons-material';
import { MenuButton } from '../../styled.components';
import { styled } from '@mui/system';
import { i18next } from '../../../../localization';

const absolyteStyles = {
    position: 'absolute',
    right: 6,
    bottom: 3,
    fontSize: 16
} as const;

const Up = styled(ArrowUpward)(absolyteStyles);

const Down = styled(ArrowDownward)(absolyteStyles);

export const Zindex: FC<NZindex.Props> = ({
    ButtonProps,
    onChange
}) => {

    return (
        <>
            <MenuButton
                {...ButtonProps}
                title={i18next.t('editor.item.settings.zindex.up')}
                onClick={() => onChange('up')}
            >
                <Layers />
                <Up />
            </MenuButton>
            <MenuButton
                {...ButtonProps}
                title={i18next.t('editor.item.settings.zindex.down')}
                onClick={() => onChange('down')}
            >
                <Layers />
                <Down />
            </MenuButton>
        </>
    )
}