import React, { ComponentProps, FC, useEffect, createRef, useRef, useState, useMemo } from "react";
import { cnKonva, NKonvaWrapper } from './Konva.index';
import './Konva.scss';
import { Stage, Layer } from 'react-konva';
import { Box } from "@mui/material";
import { ReduxWrapper } from './ReduxWrapper/ReduxWrapper';
import { SystemWrapper } from './SystemWrapper/SystemWrapper';
import { EditorWrapper } from './EditorWrapper/EditorWrapper';
import { Provider } from 'react-redux';
import { store } from "../../redux/store";
import { Layout } from "./Layout/Layout";
import { imageSelectorId } from '../../constants';
import { FileSelector } from '../FileSelector/FileSelector';
import { Stage as JSStage } from 'konva/lib/Stage';
import queryString from 'query-string';
import { ImageChange } from "./Image/Change/ImageChange";
import { useKonva } from "./Konva.hooks";
import { useZoomerContextRef } from "../Zoomer/Zoomer.context";

const KonvaWrapperPresenter: FC<NKonvaWrapper.Props> = ({
    className,
}) => {

    const {
        systemLayers,
        border,
        layers: list,
        layout,
        edit: editItem,
        select: selectItem,
        onLayoutChange,
        itemsChange,
        changeSettingsField
    } = useKonva();

    const {
        x: propsX,
        y: propsY,
        width: propsWidth,
        height: propsHeight
    } = layout.props;

    const zoomerRef = useZoomerContextRef();
    
    const stageRef = createRef<JSStage>();

    const size = useMemo(() => ({
        width: propsWidth + (border * 2),
        height: propsHeight + (border * 2)
    }), [propsWidth, propsHeight, border]);

    useEffect(() => {
        zoomerRef?.centerView();
    }, [border]);

    const selectImage: ComponentProps<typeof FileSelector>['onChange'] = (e) => {
        const file = e.currentTarget.files && e.currentTarget.files[0];
        const url = file && URL.createObjectURL(file);
        if (selectItem && url) {
            itemsChange({
                id: selectItem,
                props: {
                    url,
                    width: undefined,
                    height: undefined
                }
            });
        }
    }

    const {
        height,
        width
    } = size;

    useEffect(() => {
      const search = queryString.parse(window.location.search.slice(1)) as any as NKonvaWrapper.SearchParams;
      const width = !isNaN(Number(search.width)) ? Number(search.width) : false;
      const height = !isNaN(Number(search.height)) ? Number(search.height) : false;
      if(search.circle) {
        onLayoutChange({
            ...layout,
            props: {
                x: layout.props.x,
                y: layout.props.y,
                width: width || layout.props.width,
                height: width || layout.props.width
            }
        });
        changeSettingsField({
            field: 'circle',
            value: search.circle
        });
      } else {
        onLayoutChange({
            ...layout,
            props: {
                x: layout.props.x,
                y: layout.props.y,
                width: width || layout.props.width,
                height: height || layout.props.height
            }
        });
      }
      if(search.fixed) {
        changeSettingsField({
            field: 'fixed',
            value: Boolean(search.fixed)
        });
      }
    }, []);

    return  (
        <Box className={cnKonva({}, [className])}>
            <FileSelector onChange={selectImage} id={imageSelectorId} accept='image/*' />
            {
                editItem &&
                <EditorWrapper />
            }
            <ImageChange />
            <Stage
                ref={stageRef}
                className={cnKonva('Stage', {main: true})}
                width={width}
                height={height}
                // onWheel={onWheel}
            >
                <Provider store={store}>
                    {/* under to render background white od transparent */}
                    <Layer
                        name={'under'} />
                    {/* main field when make all items */}
                    <Layout>
                        {
                            list.map((key) => <ReduxWrapper key={key} dataId={key} />)
                        }
                    </Layout>
                    {/* layer to render layout over main field */}
                    <Layer name={'layout'} />
                    {/* overflow to render transformer */}
                    <Layer name={'overflow'} />
                    {/* system layer to render system lines and user helpers */}
                    <Layer name={'system'}>
                        {
                            systemLayers.map((key) => <SystemWrapper key={key} dataId={key} />)
                        }
                    </Layer>
                </Provider>
            </Stage>
        </Box>
    )
}

export const KonvaWrapper = KonvaWrapperPresenter;