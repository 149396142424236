import React, { FC, ComponentProps, useEffect, useState, useMemo, useRef, createRef } from "react";
import { NImage } from './Image.index';
import { Image as KonvaImage } from 'react-konva';
import { useImage } from "../../../hooks";
import { imageSelectorId } from "../../../constants";
import { useSelector } from "react-redux";
import { getKonvaSettingsLayoutSize } from "../../../redux/konvaSettings/selectors";
import { Image as ImageJS } from "konva/lib/shapes/Image";
import { getCrop } from "../Transformer/Transformer.crop";
// import useImage from 'use-image';

const ImagePr: FC<NImage.Props> = ({
    forwardRef,
    url,
    urls,
    objectFit,
    positionByCenter,
    width: propsWidth,
    height: propsHeight,
    x,
    y,
    ...props
}) => {

    const { width: layoutWidth, height: layoutHeight } = useSelector(getKonvaSettingsLayoutSize);

    useEffect(() => {
        if(!url) document.getElementById(imageSelectorId)?.click();
    }, []);
    
    const [ image, status, sizes ] = useImage(url, {}, 'anonymous');

    const {
        height,
        width
    } = useMemo(() => {
        if(propsHeight && propsWidth) {
            return { height: propsHeight, width: propsWidth };
        }
        if(!propsHeight && propsWidth) {
            return { height: propsWidth * sizes.aspect, width: propsWidth };
        }
        if(propsHeight && !propsWidth) {
            return { height: propsHeight, width: propsHeight / sizes.aspect };
        }
        if(
            sizes.height > layoutHeight ||
            sizes.width > layoutWidth
        ) {
            const bigSide = sizes.height > sizes.width ? 'height' : 'width';
            switch (bigSide) {
                case 'height': {
                    return { height: layoutHeight, width: layoutHeight * sizes.aspect };
                }
                case 'width': {
                    return { height: layoutWidth * sizes.aspect, width: layoutWidth };
                }
            }
        }
        return {
            height: sizes.height,
            width: sizes.width
        }
    }, [ propsHeight, propsWidth, sizes.height, sizes.width, sizes.aspect ]);

    const fitCrop = useMemo(() => {
        if(objectFit && image) {
            const crop = getCrop(image, { width: width, height: height }, );
            return { crop };
        }
        return undefined;
    }, [image, objectFit, height, width ]);

    const position = useMemo(() => {
        if(positionByCenter) {
            return { 
                x: (x || 0) - (width / 2),
                y: (y || 0) - (height / 2)
            };
        };
        return { x, y };
    }, [positionByCenter, height, width, x, y]);

    // const crop = () => {
    //     if (content.width > content.height) {
    //       const [cropX, cropY, cropW, cropH] = cropBasedOnWidth();
    //       if (cropY < 0) {
    //         const [cropX, cropY, cropW, cropH] = cropBasedOnHeight();
    //         return {x: cropX, y: cropY, height: cropH, width: cropW};
    //       }
    //       return {x: cropX, y: cropY, height: cropH, width: cropW};
    //     } else if (content.width < content.height) {
    //       const [cropX, cropY, cropW, cropH] = cropBasedOnHeight();
      
    //       if (cropX < 0) {
    //         const [cropX, cropY, cropW, cropH] = cropBasedOnWidth();
    //         return {x: cropX, y: cropY, height: cropH, width: cropW};
    //       }
    //         return {x: cropX, y: cropY, height: cropH, width: cropW};
    //       } else {
    //         return undefined;
    //       }
    //     }
      
    //   const cropBasedOnWidth = () => {
    //    const cropW = content.naturalWidth;
    //    const cropH = cropW / content.width * content.height;
    //    const cropX = content.naturalWidth / 2 - cropW / 2;
    //    const cropY = content.naturalHeight / 2 - cropH / 2;
    //    return [cropX, cropY, cropW, cropH];
    //   }
      
    //   const cropBasedOnHeight = () => {
    //     const cropH = content.naturalHeight;
    //     const cropW = cropH / content.height * content.width;
    //     const cropX = content.naturalWidth / 2 - cropW / 2;
    //     const cropY = content.naturalHeight / 2 - cropH / 2;
    //     return [cropX, cropY, cropW, cropH];
    //   }
    
    // if(componentProps.objectFit) {
    //     console.log('FIT!')
    //     const image = shapeRef.current.toImage();
    //     console.log('image! ', image)
    //     if(!image) return ;
    //     const crop = getCrop(image);
    //     console.log('crop! ', crop)
    //     shapeRef.current.setAttrs(crop);
    //     break;
    // }

    // const width = useMemo(() => {
    //     if(propsWidth) return propsWidth;
    //     return sizes.width;
    // }, [ propsWidth, sizes.height, sizes.width ]);

    // const height = useMemo(() => {
    //     if(propsHeight) return propsHeight;
    //     return sizes.height;
    // }, [ propsHeight, sizes.height, sizes.width ]);
    
    return (
        <KonvaImage
            {...props}
            {...position}
            ref={forwardRef}
            width={width}
            height={height}
            image={image}
            {
                ...fitCrop
            }
        />
    )
};

export const Image = React.forwardRef<ComponentProps<typeof KonvaImage>['ref'], NImage.Props>((props, ref) => {
    return <ImagePr {...props} forwardRef={ref} />
});