import React, { FC, ComponentProps } from "react";
import { Text as KonvaText } from 'react-konva';
import { NText } from './Text.index';

const TextPresenter: FC<NText.Props> = ({
    forwardRef,
    ...props
}) => {

    return (
        <KonvaText
            {...props}
            ref={forwardRef}
        />
    )
}

export const Text = React.forwardRef<ComponentProps<typeof KonvaText>['ref'], NText.Props>((props, ref) => {
    return <TextPresenter {...props} forwardRef={ref} />
});