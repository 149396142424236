import React, { FC, useState, createRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { NTextEditor, cnTextEditor } from './TextEditor.index';
import './TextEditor.scss';
import { styled } from '@mui/material';
import { Box } from '@mui/system';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import { Check, Close } from '@mui/icons-material';
import { useItem } from '../../../../redux/items/hooks';
import { useAppSelector } from '../../../../redux/hooks';
import { getItemsField } from '../../../../redux/items/selectors';

const Menu = styled(Box)({
    position: 'absolute',
    top: '100%',
    right: '0'
})

const TextEditorPresenter: FC<NTextEditor.Props> = ({
    editAccept,
    editCancel
}) => {

    const editItemId = useAppSelector(getItemsField('edit'));

    const {
        item,
    } = useItem(editItemId || '');

    const ref = createRef<HTMLDivElement>();

    const acceptValue = () => {
        if(!ref.current) return;
        editAccept(ref.current.innerText);
    }

    useEffect(() => {
        if(!ref.current) return;
        ref.current.focus();
    }, []);

    return (
        <>
            <Box
                ref={ref}
                className={cnTextEditor()}
                contentEditable={"true"}
                sx={{
                    width: item.props.width
                }}
            >
                {item.props.text}
            </Box>
            <Menu>
                <ButtonGroup size='small' variant="contained">
                    <Button onClick={acceptValue}>
                        <Check />
                    </Button>
                    <Button variant='outlined' onClick={editCancel}>
                        <Close />
                    </Button>
                </ButtonGroup>
            </Menu>
        </>
        // <textarea
        //     className={cnTextEditor()}
        //     onChange={(e) => setValue(e.target.value)}
        //     value={value}
        // />
    )
};

export const TextEditor = TextEditorPresenter;