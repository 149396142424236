import React, { FC, ComponentProps } from "react";
import { Rect as KonvaRect } from 'react-konva';
import { NRect } from './Rect.index';

const RectPresenter: FC<NRect.Props> = ({
    forwardRef,
    ...props
}) => {

    return (
        <KonvaRect
            {...props}
            ref={forwardRef}
        />
    )
}

export const Rect = React.forwardRef<ComponentProps<typeof KonvaRect>['ref'], NRect.Props>((props, ref) => {
    return <RectPresenter {...props} forwardRef={ref} />
});