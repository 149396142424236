import React, { FC, useState } from 'react';
import { NFont } from './Font.index';
import { FontsSelector } from '../../../FontsSelector/FontsSelector';
import { FontDownload } from '@mui/icons-material';
import { Wrapper } from '../Wrapper/Wrapper';


export const Font: FC<NFont.Props> = ({
    fontFamily,
    ButtonProps,
    onChange
}) => {

    return (
        <Wrapper
            ButtonProps={ButtonProps}
            icon={<FontDownload />}
        >
            <FontsSelector
                disablePortal={false}
                value={fontFamily}
                onChange={(fontFamily) => onChange({ fontFamily })}
            />
        </Wrapper>
    )
}