import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getKonvaSettings } from "../../redux/konvaSettings/selectors";
import { templatesAddTemplate, templatesRemoveTemplate, templatesUpdateTeamplate, templatesSetTemplatesList, templatesSetTemplates, templatesAddTemplates } from "./redux/actions.Templates";
import { getTemplateById, getTemplatesFields } from "./redux/selectors.Templates";
import { NTemplates } from "./redux/types.Templates";
import { v1 as uuid } from 'uuid';
import { getItems } from "../../redux/items/selectors";
import { settingsChangeReducer } from "../../redux/konvaSettings/actions";
import { itemsChangeReducer } from "../../redux/items/actions";
import { cloneDeep, pick } from "lodash";
import { dataURItoBlob } from "../../utils";
import { TemplateCard } from "./Templates.rb";
import { usePryanikyEmbedding } from "../../embed/pryaniky";

const messageEventSet = 'setTemplates';
const messageEventAddOne = 'addTemplate';
const messageEventClear = 'clearTemplates';

const useKonvaData = () => {

    const { border, circle, fixed, layout } = useSelector(getKonvaSettings);

    const { items: itemsObject, layers } = useSelector(getItems);

    const getActualData = useCallback(() => {
        const konvaSettings = { border, circle, fixed, layout };
        const clonedKonvaSettings = cloneDeep(konvaSettings);
        const items = { items: itemsObject, layers };
        const clonedItems = cloneDeep(items);
        clonedItems.items = pick(clonedItems.items, clonedItems.layers);
        return {
            konvaSettings: clonedKonvaSettings,
            items: clonedItems
        }
    }, [border, circle, fixed, layout, itemsObject, layers]);

    const getPreviewUrl = useCallback(() => {
        let previewUrl = '';
        if(window.konvaMainLayer) {
            const { x, y, width, height } = layout.props;
            const d = window.konvaMainLayer.toDataURL({
                x,
                y,
                height,
                width,
            });
            previewUrl = URL.createObjectURL(dataURItoBlob(d));
        }
        return previewUrl;
    }, [border, circle, fixed, layout, itemsObject, layers]);

    return {
        getActualData,
        getPreviewUrl
    };
};

export const useTemplate = (id: keyof NTemplates.Reducer['templates']) => {
    
    const dispatch = useDispatch();

    const template = useSelector(getTemplateById(id));

    const { getActualData, getPreviewUrl } = useKonvaData();

    const selectTemplate = () => {
        const konvaSettings = cloneDeep(template.data.konvaSettings);
        // konvaSettings.layout.props.x = 0 + konvaSettings.border;
        // konvaSettings.layout.props.y = 0 + konvaSettings.border;
        dispatch(settingsChangeReducer(konvaSettings));
        dispatch(itemsChangeReducer(template.data.items));
    };

    const updateTemplate = () => {
        const data = getActualData();
        const previewUrl = getPreviewUrl();
        dispatch(templatesUpdateTeamplate({
            id: id.toString(),
            name: template.name,
            date: new Date().toISOString(),
            data,
            previewUrl
        }));
    };

    const removeTemplate = () => {
        dispatch(templatesRemoveTemplate(id.toString()));
    };

    return {
        template,
        selectTemplate,
        updateTemplate,
        removeTemplate
    };
};

export const useTemplates = () => {

    const dispatch = useDispatch();

    const [ search, setSearch ] = useState('');

    const { list, templates } = useSelector(getTemplatesFields('list', 'templates'));

    const { getActualData, getPreviewUrl } = useKonvaData();

    const addTemplate = (name: string) => {
        const data = getActualData();
        const previewUrl = getPreviewUrl();
        dispatch(templatesAddTemplate({
            id: uuid(),
            date: new Date().toISOString(),
            name,
            previewUrl,
            data
        }));
    };

    const {
        getExtendTemplates
    } = usePryanikyEmbedding();

    const ref = useRef(false);

    useEffect(() => {
        if(!ref.current) {
            ref.current = true;
            // fetch('/Content/cards/templates.json', {
            //     method: 'GET',
            //     headers: {
            //         'Content-Type': 'application/json'   
            //     }
            // })
            // .then(d => d.json())
            // .then(d => {
            //     dispatch(templatesAddTemplates(d as any));
            //     console.log('templates: ', d)
            // })
            // dispatch(templatesAddTemplates([TemplateCard as any]));
            getExtendTemplates?.()
            .then(templs => {
                templs && dispatch(templatesAddTemplates(templs));
            })
            .catch(e => {
                console.error(e);
            });
        }
    }, []);

    const clearTemplates = () => {
        dispatch(templatesSetTemplatesList([]));
    };

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if(event.data.event === messageEventSet) {
                const data = event.data.data;
                dispatch(templatesSetTemplates(data));
            }
            if(event.data.event === messageEventAddOne) {
                const data = event.data.data;
                dispatch(addTemplate(data));
            }
            if(event.data.event === messageEventClear) {
                dispatch(clearTemplates());
            }
        });
    }, []);

    const serchedList = useMemo(() => {
        const searchedIds = Object.values(templates).filter((template) => template.name.toLowerCase().includes(search.toLowerCase())).map(el => el.id);
        return list.filter(el => searchedIds.includes(el));
    }, [search, list, templates]);
    
    return {
        list: serchedList,
        search,
        setSearch,
        addTemplate,
        clearTemplates
    };
};