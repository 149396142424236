import { NKonvaSettings } from './interface';

let baseBorder = 500;
// baseBorder = 500;

export const baseReducer: NKonvaSettings.Reducer = {
    layout: {
        id: 'main',
        type: 'Rect',
        props: {
            x: baseBorder,
            y: baseBorder,
            height: 400,
            width: 400
        }
    },
    edit: false,
    border: baseBorder,
    circle: false,
    fixed: false,
    save: null
}