import { NTemplates } from "./types.Templates";

export const TMPLS_SET_TEMPLATES = 'TMPLS_SET_TEMPLATES';
export const TMPLS_SET_TEMPLATES_LIST = 'TMPLS_SET_TEMPLATES_LIST';
export const TMPLS_ADD_TEMPLATE = 'TMPLS_ADD_TEMPLATE';
export const TMPLS_ADD_TEMPLATES = 'TMPLS_ADD_TEMPLATES';
export const TMPLS_REMOVE_TEMPLATE = 'TMPLS_REMOVE_TEMPLATE';
export const TMPLS_UPDATE_TEPMLATE = 'TMPLS_UPDATE_TEPMLATE';

export namespace NTemplatesActions {

    type TemplateItem = NTemplates.Reducer['templates'][keyof NTemplates.Reducer['templates']];


    type SetTemplatesPayload = TemplateItem[];

    type SetTemplates = {
        type: typeof TMPLS_SET_TEMPLATES;
        payload: SetTemplatesPayload;
    };


    type AddTemplatePayload = TemplateItem;

    type AddTemplate = {
        type: typeof TMPLS_ADD_TEMPLATE;
        payload: AddTemplatePayload;
    };


    type AddTemplates = {
        type: typeof TMPLS_ADD_TEMPLATES;
        payload: AddTemplatePayload[];
    };


    type RemoveTemplatePayload = TemplateItem['id'];

    type RemoveTemplate = {
        type: typeof TMPLS_REMOVE_TEMPLATE;
        payload: RemoveTemplatePayload;
    };


    type SetTemplatesListPayload = NTemplates.Reducer['list'];
    
    type SetTemplatesList = {
        type: typeof TMPLS_SET_TEMPLATES_LIST;
        payload: SetTemplatesListPayload;
    };


    type UpdateTemplatePayload = Partial<TemplateItem> & Pick<TemplateItem, 'id'>;

    type UpdateTemplate = {
        type: typeof TMPLS_UPDATE_TEPMLATE;
        payload: UpdateTemplatePayload;
    };


    export type Action<Type extends Actions['type']> = Extract<Actions, { type: Type }>;

    export type ActionFunction<Type extends Actions['type']> = (payload: Action<Type>['payload']) => Action<Type>;

    export type Actions =
        SetTemplates |
        AddTemplate |
        AddTemplates |
        RemoveTemplate |
        SetTemplatesList |
        UpdateTemplate;

};