import { put, takeLeading } from 'redux-saga/effects';
import { logError, select } from '../sagas';
import { ITEMS_CREATE, ITEMS_CREATE_EXTENSION, ITEMS_CREATE_FLATICON, ITEMS_SYSTEM_CREATE } from './actions.types';
import { getItemsField } from './selectors';
import { itemsAdd, itemsChangeField, itemsSystemSet } from './actions';
import { NItems } from './interface';
import { v1 as uuid } from 'uuid';
import { getKonvaSettings, getKonvaSettingsField } from '../konvaSettings/selectors';
// import { SETTINGS_CHANGE_LAYOUT } from '../konvaSettings/actions.types';
import { NKonvaSettings } from '../konvaSettings/interface';
import { settingsChangeLayout } from '../konvaSettings/actions';
import { baseProps } from '../../constants';

const sagaName = 'ItemsSaga';

type offsetType = {[s: string]: number};

const startPositionPrepare = <T extends typeof baseProps[keyof typeof baseProps]>(item: T, offset: offsetType = { x: 0, y: 0 }) => {
    return {
        ...item,
        x: item.x + offset.x,
        y: item.y + offset.y
    }
}

function newItem(type: NItems.IItemTypes, offset: offsetType = { x: 0, y: 0 }, id?: string) {
    const newId = id || uuid();
    return {
        id: newId,
        type,
        name: type,
        fixed: false,
        permamenltyFixed: false,
        props: {
            ...startPositionPrepare(baseProps[type], offset)
        }
    }
}

function* createItem(action: NItems.NActions.ItemsCreate) {
    try {
        const settings = yield* select(getKonvaSettings);
        const item = newItem(action.payload, settings.layout.props) as NItems.IItemObj[typeof action.payload];
        yield put(itemsAdd(item));
    } catch (error) {
        logError(sagaName, action.type, error);
    }
}

function* createItemFlatIcon(action: NItems.NActions.ItemsCreateFlatIcon) {
    try {
        const settings = yield* select(getKonvaSettings);
        const item = newItem('FlatIconsImage', settings.layout.props) as NItems.IItemObj['FlatIconsImage'];
        item.props.url = action.payload.url;
        yield put(itemsAdd(item));
    } catch (error) {
        logError(sagaName, action.type, error);
    }
}

function* createItemExtension(action: NItems.NActions.ItemsCreateExtension) {
    try {
        const settings = yield* select(getKonvaSettings);
        let item = newItem(action.payload.type, settings.layout.props) as NItems.IItemObj['FlatIconsImage'];
        item.props = {
            ...item.props,
            ...action.payload.props
        } as any;
        item.name = action.payload.name;
        item.fixed = action.payload.fixed;
        yield put(itemsAdd(item));
    } catch (error) {
        logError(sagaName, action.type, error);
    }
}

/**
 * action to create new system item and
 * @param action 
 */
function* createSystemItem(action: NItems.NActions.ItemsSystemCreate) {
    try {
        const systemCurrent = yield* select(getItemsField('systemLayers'));
        if(
            !action.payload ||
            (
                systemCurrent.length === 0 &&
                action.payload.length === 0
            )
        ) return ;
        const items = action.payload?.map(({ id, type, props }) => {
            const item = newItem(type) as NItems.IItemObj[typeof type];
            if(id) item.id = id;
            item.props = {
                ...item.props,
                ...props
            } as NItems.IItemObj[typeof type]['props'];
            return item;
        }) || [];
        yield put(itemsSystemSet(items));
    } catch (error) {
        logError(sagaName, action.type, error);
    }
}

// подумать, нужно ли сбрасывать положение холста и изменять положение элементов
function* _changeLayout(action: NKonvaSettings.NActions.ChangeLayout) {
    try {
        const border = yield* select(getKonvaSettingsField('border'));
        const layout = yield* select(getKonvaSettingsField('layout'));
        const items = yield* select(getItemsField('items'));
        const newItems: typeof items = Object.typedKeys(items).reduce((a, key) => {
            const item = items[key];
            return {
                ...a,
                [key]: {
                    ...item,
                    id: item.id,
                    type: item.type,
                    props: {
                        ...item.props,
                        x: ((item.props.x || 0) - layout.props.x) + border,
                        y: ((item.props.y || 0) - layout.props.y) + border
                    }
                }
            };
        }, {});
        yield put(settingsChangeLayout({
            ...layout,
            props: {
                ...layout.props,
                x: border,
                y: border
            }
        }));
        yield put(itemsChangeField({ field: 'items', value: newItems }));
    } catch (error) {
        logError(sagaName, action.type, error);
    }
}

export function* itemsSaga() {
    yield takeLeading(ITEMS_CREATE, createItem);
    yield takeLeading(ITEMS_CREATE_FLATICON, createItemFlatIcon);
    yield takeLeading(ITEMS_CREATE_EXTENSION, createItemExtension);
    yield takeLeading(ITEMS_SYSTEM_CREATE, createSystemItem);
    // yield takeLeading(SETTINGS_CHANGE_LAYOUT, changeLayout);
}