import React, { ComponentProps, FC, useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { mapSettingsItemActionToProps, mapSettingsItemStateToProps, NSettingsItem, cnSettingsItem } from './Item.index';
import { changers } from '../Changers';
import { Union } from '../../../utils.types';
import { fixedAllowedSettingsRenderers, settings } from '../../../constants';
import { MenuButton } from '../styled.components';
import { CropDialog } from '../../CropDialog/CropDialog';

import EditIcon from '@mui/icons-material/Edit';
import CropIcon from '@mui/icons-material/Crop';
import DeleteForever from '@mui/icons-material/DeleteForever';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';
import { i18next } from '../../../localization';


type OnChangePropsTypes = Parameters<ComponentProps<Union<typeof changers>>['onChange']>['0'];

const SettingsItemPresenter: FC<NSettingsItem.Props> = ({
    selectedItem,
    croppingItem,
    itemsChange,
    itemsChangeField,
    itemsMove,
    itemsRemove
}) => {

    if(!selectedItem) return null;

    let {
        id,
        type,
        fixed,
        avaliableEditors,
        props: selectedProps
    } = selectedItem;

    const dsd = settings[type];

    // const itemChange = (props: OnChangePropsTypes) => {
    const itemChange = (props: any) => {
        itemsChange({
            id,
            props
        });
    };

    const itemSetCrop = (props: any) => {
        CropDialog({ image: selectedProps.url })
        .then(({
            image,
            height,
            width
        }) => {
            itemsChange({
                id,
                props: {
                    url: image,
                    height,
                    width
                }
            });
        });
    };

    const itemSetIzindex = (props: any) => {
        itemsMove({
            id,
            direction: props
        });
    }

    const itemSetEdit = (props: any) => {
        itemsChangeField({
            field: 'edit',
            value: id
        });
    }

    const itemRemove = () => {
        itemsRemove(id);
    }

    const itemLock = () => {
        itemsChange({
            id,
            fixed: !fixed
        });
    }

    return (
        <>
            {
                settings[type].map((setting, idx) => {
                    const Component = changers[setting.renderer];
                    const params = Object.typedKeys(selectedProps).reduce((a, prop) => {
                        const param = setting.params as readonly (keyof typeof selectedProps)[];
                        if(param.includes(prop)) {
                            return { ...a, [prop]: selectedProps[prop] }
                        }
                        return a
                    }, {} as typeof selectedProps);
                    const buttonProps = {
                        title: i18next.t(`editor.item.settings.${setting.renderer.toLowerCase()}`)
                    }
                    let onChange = itemChange;
                    if(setting.renderer === 'Cropper') {
                        // params.isCropping = croppingItem === id;
                        params.icon = <CropIcon />;
                        onChange = itemSetCrop;
                    }
                    if(setting.renderer === 'Zindex') {
                        onChange = itemSetIzindex;
                    }
                    if(setting.renderer === 'Edit') {
                        params.icon = <EditIcon />;
                        onChange = itemSetEdit;
                    }
                    if(setting.renderer === 'Remove') {
                        params.icon = <DeleteForever />;
                        onChange = itemRemove;
                    }
                    if(setting.renderer === 'Fixer') {
                        params.icon = fixed ? <Lock /> : <LockOpen />
                        buttonProps.title = i18next.t(`editor.item.settings.${setting.renderer.toLowerCase()}.${fixed.toString()}`)
                        onChange = itemLock;
                    }
                    if(fixed && !fixedAllowedSettingsRenderers.includes(setting.renderer)) return null;
                    if(avaliableEditors && !avaliableEditors.includes(setting.renderer)) return null;
                    return (
                        <Component
                            key={idx}
                            ButtonProps={buttonProps}
                            {...params as any}
                            onChange={onChange}
                        />
                    )
                })
            }
        </>
    )
};

export const SettingsItem = connect(
    mapSettingsItemStateToProps,
    mapSettingsItemActionToProps
)(SettingsItemPresenter)