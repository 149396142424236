export const ITEMS_CHANGE_REUCER = 'ITEMS_CHANGE_REUCER';
export const ITEMS_CHANGE_FIELD = 'ITEMS_CHANGE_FIELD';
export const ITEMS_CHANGE = 'ITEMS_CHANGE';
export const ITEMS_ADD = 'ITEMS_ADD';
export const ITEMS_CREATE = 'ITEMS_CREATE';
export const ITEMS_CREATE_FLATICON = 'ITEMS_CREATE_FLATICON';
export const ITEMS_CREATE_EXTENSION = 'ITEMS_CREATE_EXTENSION';
export const ITEMS_SELECT = 'ITEMS_SELECT';
export const ITEMS_REMOVE_SELECTED = 'ITEMS_REMOVE_SELECTED';
export const ITEMS_REMOVE = 'ITEMS_REMOVE';
export const ITEMS_MOVE = 'ITEMS_MOVE';
export const ITEMS_SYSTEM_CREATE = 'ITEMS_SYSTEM_CREATE';
export const ITEMS_SYSTEM_ADD = 'ITEMS_SYSTEM_ADD';
export const ITEMS_SYSTEM_REMOVE = 'ITEMS_SYSTEM_REMOVE';
export const ITEMS_SYSTEM_SET = 'ITEMS_SYSTEM_SET';