import { cn } from '@bem-react/classname';
import { getKonvaSettings, getKonvaSettingsField  } from '../../redux/konvaSettings/selectors';
import { settingsChangeField, settingsChangeLayout } from '../../redux/konvaSettings/actions';
import { itemsSelect } from '../../redux/items/actions';
import { IStateType } from '../../redux/store';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';

export const cnSettingsMenu = cn('SettingsMenu');

export namespace NSettingsMenu {

    export interface Props extends OwnProps, ReturnType<typeof mapSettingsMenuStateToProps>, ReturnType<typeof mapSettingsMenuActionToProps> {
        
    };

    export interface OwnProps {
        
    };
};

export const mapSettingsMenuStateToProps = (state: IStateType, props: NSettingsMenu.OwnProps) => {
    return {
        layout: getKonvaSettingsField('layout')(state),
        settings: getKonvaSettings(state)
    }
};

export const mapSettingsMenuActionToProps = (d: Dispatch<AnyAction>) => bindActionCreators({
    settingsChangeField,
    settingsChangeLayout,
    itemsSelect
}, d);