import { useEnvironment } from "../environment"
import PrnExt from "pryaniky-ext-app";
import { KonvaComponents } from "../blocks/Konva";
import { NTemplates } from "../blocks/Templates/redux/types.Templates";
import { TFontsSelectorContext } from "../blocks/FontsSelector/FontSelector.context";

async function getAllowedComponents() {
    try {
        const components = await PrnExt.inst.customAction('top', 'getAllowedComponents', {}, 'setAllowedComponents') as (keyof typeof KonvaComponents)[] | null;
        console.log('allowed components: ', components);
        return components;
    } catch (error) {
        console.error('get allowed components error: ', error);
    }
    return undefined;
};

async function getAdditionalStyles() {
    try {
        const styles = await PrnExt.inst.customAction('top', 'getAdditionalStyles', {}, 'setAdditionalStyles') as string;
        const style = document.createElement('style');
        style.setAttribute('type', 'text/css');
        style.appendChild(document.createTextNode(styles));
        document.body.appendChild(style);
    } catch (error) {
        console.error('get styles error: ', error);
    }
};

async function getFontsSubset() {
    try {
        const newSubsets = await PrnExt.inst.customAction('top', 'getFontsSubset', {}, 'setFontsSubset') as TFontsSelectorContext['subset'];
        console.log('new subsets: ', newSubsets);
        return newSubsets;
    } catch (error) {
        console.error('get subsets error: ', error);
    }
    return undefined;
};

async function getExtendTemplates() {
    try {
        const templs = await PrnExt.inst.customAction('top', 'getExtendTemplates', {}, 'extendTemplates') as NTemplates.Reducer['templates'][keyof NTemplates.Reducer['templates']][];
        console.log('extend templates: ', templs);
        return templs;
    } catch (error) {
        console.error('get extend templates error: ', error);
    }
    return undefined;
};

export const usePryanikyEmbedding = () => {

    const { inFrame } = useEnvironment();

    if(!inFrame) return {};

    return {
        getAllowedComponents,
        getAdditionalStyles,
        getExtendTemplates,
        getFontsSubset
    };
}