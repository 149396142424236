import { base } from './reducer';
import { ModuleTypePrepare, ModuleReducerMapPrepare } from '../types';
import { ISagaModule } from 'redux-dynamic-modules-saga';

const reducers = {
    base
}

type BaseModuleType = ModuleTypePrepare<typeof reducers>;

type BaseReducerMapType = ModuleReducerMapPrepare<typeof reducers>;

export function getBaseModule(): ISagaModule<BaseModuleType> {
    return {
        id: "base",
        //   middlewares: [
        //     // routerMiddleware(history),
        //   ],
        reducerMap: reducers as BaseReducerMapType
        //   reducerMap as { [s in keyof IBaseReduxModule]: Reducer<ReturnType<IBaseReduxModule[s]>, AnyAction> },
        //   sagas
    };
  };