import React, { ComponentProps, FC } from "react";
import { connect } from "react-redux";
import { NSettingsLayer, cnSettingsLayer, mapSettingsLayerStateToProps, mapSettingsLayerActionsToProps } from './Layer.index';
import './Layer.scss';
import ListItem from '@mui/material/ListItem';
import {
    ListItemButton,
    ListItemIcon,
    ListItemText,
    IconButton
} from '@mui/material';
import {
    Delete
} from '@mui/icons-material';
import Lock from '@mui/icons-material/Lock';
import LockOpen from '@mui/icons-material/LockOpen';

const LayerPresenter: FC<NSettingsLayer.Props> = ({
    id,
    data,
    isSelected,
    itemsSelect,
    itemsRemove,
    itemsChange
}) => {

    const fixItem: ComponentProps<typeof IconButton>['onClick'] = (e) => {
        e.stopPropagation();
        itemsChange({
            id,
            fixed: !data.fixed
        });
    }

    const removeItem: ComponentProps<typeof IconButton>['onClick'] = (e) => {
        e.stopPropagation();
        itemsRemove(id);
    }

    return (
        <ListItemButton
            selected={isSelected}
            onClick={() => {
                !data.permamenltyFixed && !data.fixed && itemsSelect(id)
            }}
            className={cnSettingsLayer()}
        >
            <ListItemText>
                {data.type}
            </ListItemText>
            <ListItemIcon>
                <IconButton onClick={fixItem}>
                    {
                        data.fixed ? <Lock /> : <LockOpen />
                    }
                </IconButton>
                <IconButton onClick={removeItem}>
                    <Delete />
                </IconButton>
            </ListItemIcon>
        </ListItemButton>
    )
}

export const Layer = connect(
    mapSettingsLayerStateToProps,
    mapSettingsLayerActionsToProps
)(LayerPresenter);