import './shugar.types';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './window.extend';

import { Provider } from 'react-redux'
import { store } from './redux/store';

// Redecalare forwardRef
declare module "react" {
  function forwardRef<T, P = {}>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

/**
 * работа со слоями +
 * 
 * кропалка определенного объекта, от отдельного слоя(кропалка сделана отдельным модальным окном) +
 * 
 * сделать круглую основную форму
 * 
 * настройка формы окна создания:
 *  - изменение размеров +
 *  - размеры при резайзе +
 * 
 * найстройка элементов:
 *  - установка текста +
 *  - загрузка шрифтов ( селектор с ~1500 шрифтами ) +
 *  - прозрачность элемента +
 *  - выбор цвета заливки +
 *  - выбор цвета текста +
 *  - выбор шрифта +
 *  - выбор цвета и настройка границ +
 *  - круг +
 *  - эллипс +
 *  - квадрат + 
 *  - прямоугольник +
 *  - картинка +
 *  - удалить + иконка в слоях и кнопа при выбранном элементе + добавить кнопку удаления в меню элемента +
 *  - zoom по курсору +
 *  - выравнивание текста +
 * 
 * удобство использования:
 *  - визуализация при совпадении границ и центра других элементов при перетаскиваемым +
 *  - визуализация при совпадении границ и центра других элементов при ресайзе +
 *  - рендер визуализации совпадения грнниц, когда они полностью совпали +
 * 
 */

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
