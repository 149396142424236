import { BordersColor } from './BordersColor/BordersColor';
import { BackgroundColor } from './BackgroundColor/BackgroundColor';
import { TextColor } from './TextColor/TextColor';
import { Borders } from './Borders/Borders';
import { Opacity } from './Opacity/Opacity';
import { Font } from './Font/Font';
import { FontSize } from './FontSize/FontSize';
import { Zindex } from './Zindex/Zindex';
import { Align } from './Align/Align';
import { Clicker } from './Clicker/Clicker';


export const changers = {
    Borders,
    BackgroundColor,
    TextColor,
    BordersColor,
    Opacity,
    Font,
    FontSize,
    Cropper: Clicker,
    Fixer: Clicker,
    Zindex,
    Edit: Clicker,
    Align,
    Remove: Clicker
};

export default changers;