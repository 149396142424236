import React, { ComponentProps, FC } from 'react';
import { NReduxWrapper } from './ReduxWrapper.index';
import { KonvaComponents } from '../components';
import { Transformer } from "../Transformer/Transformer";
import { useItem, useSelectItemAction, useSystemActions } from '../../../redux/items/hooks';

const ReduxWrapperPresenter: FC<NReduxWrapper.Props> = ({
    dataId
}) => {

    const {
        item,
        isCropped,
        isEditing,
        isSelected,
        changeItem,
        setAsEdit
    } = useItem(dataId);

    const selectItem = useSelectItemAction();

    const {
        createSystem
    } = useSystemActions();

    if(!item) return null;

    const {
        id,
        type,
        fixed,
        permamenltyFixed,
        fixPosition,
        avaliableEditors,
        props,
    } = item;

    const onDblClick = () => {
        // edit only text field
        if(type === 'Text' || avaliableEditors?.includes('Edit')) {
            setAsEdit();
        }
    }

    return (
        <Transformer
            key={id}
            type={type}
            component={KonvaComponents[type]}
            componentProps={{
                key: id,
                id,
                ...props,
                reduxType: type,
                onClick: () => {
                    if(permamenltyFixed) {
                        selectItem(null);
                    } else {
                        selectItem(id)
                    }
                },
                onDragEnd: (e: any) => {
                    changeItem({
                        id,
                        props: {
                            x: e.target.x(),
                            y: e.target.y(),
                        }
                    });
                },
                onMouseOver: () => {
                    if(!permamenltyFixed) document.body.style.cursor = 'pointer';
                },
                onMouseOut: () => {
                    document.body.style.cursor = 'default';
                },
                onDblClick,
                draggable: !fixed && isSelected && !fixPosition
            }}
            isSelected={isSelected}
            isCropped={isCropped}
            fixed={fixed || fixPosition}
            fixPosition={fixPosition}
            shouldOverdrawWholeArea={true}
            onDblClick={onDblClick}
            onChange={(props: any) => {
                changeItem({
                    id,
                    props
                });
            }}
            addSystemLayers={(payload) => {
                createSystem(payload);
            }}
            id={id}
            hide={isEditing}
        />
    )
};

export const ReduxWrapper = ReduxWrapperPresenter;