import { pick } from 'lodash';
import { IStateType } from '../store';

export const getKonvaSettings = (state: IStateType) => state.konvaSettings;

/**
 * get a field value by name 
 * @param field 
 * @returns 
 */
export const getKonvaSettingsField = <F extends keyof ReturnType<typeof getKonvaSettings>> (field: F) => (state: IStateType) => getKonvaSettings(state)[field];

/**
 * get fields values by names
 * @param fields 
 * @returns 
 */
export const getKonvaSettingsFields = <FS extends keyof ReturnType<typeof getKonvaSettings>> (fields: FS[]) => (state: IStateType) => {
    const stateValue = getKonvaSettings(state);
    return pick(stateValue, ...fields) as Pick<typeof stateValue, typeof fields[number]>;
};

export const getKonvaSettingsLayoutSize = (state: IStateType) => getKonvaSettings(state).layout.props;