import { intersection } from "lodash";
import { createContext, useEffect, useState } from "react";
import { usePryanikyEmbedding } from "../../embed/pryaniky";

const subsets = [
    "cyrillic",
    "cyrillic-ext",
    "greek",
    "greek-ext",
    "hebrew",
    "latin",
    "latin-ext",
    "vietnamese"
] as const;

export type TFontsSelectorContext = {
    subset: (typeof subsets)[number] | (typeof subsets)[number][] | null;
};

const baseFontsSelectorContext: TFontsSelectorContext = {
    subset: null
};

export const FontsSelectorContext = createContext(baseFontsSelectorContext);

export const useFontSelectorContext = () => {

    const [ state, setState ] = useState(baseFontsSelectorContext);

    const {
        getFontsSubset
    } = usePryanikyEmbedding();

    useEffect(() => {
        getFontsSubset?.()
        .then((newSubsets) => {
            if(newSubsets) {
                if(
                    (
                        typeof newSubsets === 'string' &&
                        subsets.includes(newSubsets)
                    ) ||
                    (
                        Array.isArray(newSubsets) &&
                        !!intersection(subsets, newSubsets).length
                    )
                    ) {
                    setState({
                        subset: newSubsets
                    });
                } else {
                    console.log(`trying set subset ${newSubsets} but it is not in allowed list ${subsets.toString()}`);
                }
            } else {
                console.log('newSubsets is undefined')
            }
        })
        .catch((e) => {
            console.error(e);
        });
    });

    const Provider = FontsSelectorContext.Provider;

    const Consumer = FontsSelectorContext.Consumer;

    return {
        state,
        Provider,
        Consumer
    };
};