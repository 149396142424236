import React, { FC } from 'react';
import { NBorders } from './Borders.index';
import { Slider, TextField } from '@mui/material';
import { Wrapper } from '../Wrapper/Wrapper';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';

export const Borders: FC<NBorders.Props> = ({
    strokeWidth = 0,
    ButtonProps,
    onChange
}) => {

    return (
        <Wrapper
            ButtonProps={ButtonProps}
            icon={<CheckBoxOutlineBlankIcon />}
        >
            <TextField
                value={strokeWidth}
                size="small"
                onChange={(e) => {
                    const value = e.target.value;
                    const number = Number(value.replace(/\D/g, ''));
                    if(isNaN(number)) return ;
                    onChange({
                        strokeWidth: number
                    });
                }}
                sx={{
                    maxWidth: 60
                }}
                inputProps={{
                    style: {
                        textAlign: 'right'
                    }
                }}
            />
            <Slider
                aria-label="Small steps"
                defaultValue={1}
                value={strokeWidth}
                onChange={(e, value) => {
                    if(Array.isArray(value)) return ;
                    onChange({
                        strokeWidth: value
                    });
                }}
                step={1}
                marks
                min={0}
                max={25}
                valueLabelDisplay="auto"
            />
        </Wrapper>
    )
}