import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const App = styled(Box)({
    display: 'flex',
    height: 'calc(100vh - 104px)'
});

export const AppContent = styled(Box)({
    
});