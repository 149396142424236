import React, { FC, useState, useEffect, ComponentProps, forwardRef, useMemo, useContext } from 'react';
import { Autocomplete, Chip, TextField } from '@mui/material';
import { List, AutoSizer } from 'react-virtualized';
import './FontsSelector.scss';
import { cnFontsSelector, prepairedFonts, NFontsSelector } from './FontsSelector.index';
import { ListItemButton, ListItemText } from '@mui/material';
import { FontsSelectorContext } from './FontSelector.context';
import { intersection } from 'lodash';

const VirtuaizedList: FC<any> = forwardRef(({ children, ...props }, ref) => {
    const items = children;
    
    const rowHeight = 50;

    const getRow: ComponentProps<typeof List>['rowRenderer'] = ({ index, key, style }) => {
        const row = items[index];
        return (
            <div key={key} style={style}>
                {row}
            </div>
        )
    }

    const height = useMemo(() => {
        const maxHeigth = document.body.clientHeight * 0.4;
        const manyHeight = maxHeigth > 300 ? 300 : maxHeigth;
        const maxItems = Math.floor(manyHeight / rowHeight);
        return items.length > maxItems ? manyHeight : items.length * rowHeight;
    }, [items.length, rowHeight]);

    return (
        <ul ref={ref} {...props}>
            <AutoSizer
                className={cnFontsSelector('AutoSizer')}
                disableHeight
            >
                {({ width }) => {
                    return (
                        <List
                            width={width}
                            rowCount={items.length}
                            height={height}
                            rowHeight={rowHeight}
                            rowRenderer={getRow}
                        />
                    )
                }}
            </AutoSizer>
        </ul>
    )
});

const RenderName: FC<{
    data: (typeof prepairedFonts)[number];
    withExapmle?: boolean;
}> = ({
    data,
    withExapmle = true
}) => {
    return (
        <>
            {data.family}
            {
                withExapmle &&
                <span style={{
                    marginLeft: '8px',          
                    fontFamily: `${data.family}`
                }}>
                    Example
                </span>
            }
        </>
    )
}

const renderOption: NFontsSelector.TypedAutocompleteProps['renderOption'] = (props, option, state) => {
    delete props.className;
    return (
        <ListItemButton {...(props as any)}>
            <ListItemText className={cnFontsSelector('Item')} primary={<RenderName data={option} />} />
        </ListItemButton>
    )
}

const renderTags: NFontsSelector.TypedAutocompleteProps['renderTags'] = (tags, getTagsProps) => {
    return tags.map((tag, index) => {
        const props = getTagsProps({ index });
        delete (props as any).onDelete;
        return (
            <Chip
                variant='outlined'
                label={<RenderName data={tag} />}
                {...props}
            />
        )
    })
}

export const FontsSelector: FC<NFontsSelector.Props> = ({
    value: valueProp,
    onChange: onChangeProps,
    onFocus,
    onClick,
    onBlur,
    className,
    ...props
}) => {

    const value = useMemo(() => {
        const finded = prepairedFonts.find(el => el.family === valueProp);
        return finded || null;
    }, [valueProp]);

    const { subset } = useContext(FontsSelectorContext);

    const fonts = useMemo(() => {
        return prepairedFonts.filter(el => {
            if(!subset) {
                return true
            } else {
                return Array.isArray(subset) ? !!intersection(el.subsets, subset).length : el.subsets.includes(subset)
            }
        })
    }, [subset]);

    const [ search, setSearch ] = useState('');

    useEffect(() => {
        setSearch('');
    }, [valueProp]);

    const onInputChange = useMemo<NFontsSelector.TypedAutocompleteProps['onInputChange']>(() => (e, v, reason) => {
        if(reason === 'reset') {
            setSearch('');
        } else {
            setSearch(v);
        }
    }, []);

    return (
        <Autocomplete
            disablePortal
            {...props}
            className={cnFontsSelector({}, [className])}
            ListboxComponent={VirtuaizedList}
            options={fonts}
            renderOption={renderOption}
            renderTags={renderTags}
            onInputChange={onInputChange}
            inputValue={search || ''}
            renderInput={(params) => {
                return (
                    <TextField
                        {...params}
                        inputProps={{
                            ...params.inputProps,
                            style: {
                                ...params.inputProps.style,
                                minWidth: value ? '0px' : undefined,
                                paddingLeft: value ? '0px' : undefined,
                                paddingRight: value ? '0px' : undefined,
                            },
                            value: search
                        }}
                        label={'Font'}
                        InputProps={{
                            ...params.InputProps,
                            startAdornment: value && <Chip
                                    variant='outlined'
                                    label={<RenderName data={value} />}
                                />
                        }}
                    />
                );
            }}
            value={value}
            onChange={(event, value) => {
                onChangeProps(value?.family);
            }}
            getOptionLabel={(option) => option.family}
        />
    )
}