import { WithDialog } from "../DialogCreate";
import { cn } from '@bem-react/classname';

export const cnCropDialog = cn('CropDialog');

export namespace NCropDialog {

    interface PropsOwn {
        image: string;
    }

    interface Rezult extends PropsOwn {
        width: number;
        height: number;
    }

    export type Props = PropsOwn & WithDialog<Rezult>;
}