import { HTMLProps, useCallback } from "react";
import { NotNullableProps } from "./utils.types";
import { debounce } from 'lodash';
import { Layer as JSLayer } from 'konva/lib/Layer';

export function useDebounce<T extends (...args: any) => any>(func: T, delay: number, watch: any[] = []) {
    return useCallback(debounce<T>(func, delay), watch);
}

export function scaleTo(image: HTMLImageElement, canvasProps: NotNullableProps<HTMLProps<HTMLCanvasElement>, 'height' | 'width'>,  type: 'fill' | 'fit'){
    if(!image) return;
    let {
        width,
        height
    } = canvasProps;
    height = Number(height);
    width = Number(width);
    if(isNaN(height) || isNaN(width)) return;
    // get the scale
    var scale = 1;
    if(type === 'fill') scale = Math.max(width / image.width, height / image.height);
    if(type === 'fit') scale = Math.min(width / image.width, height / image.height);
    // get the top left position of the image
    var x = (width / 2) - (image.width / 2) * scale;
    var y = (height / 2) - (image.height / 2) * scale;
    return {
        x,
        y,
        width: image.width * scale,
        height: image.height * scale
    };
}

/**
 * compare val1 & (vla2 +- range)
 * @param val1 
 * @param val2 
 * @param range 
 */
export const untrulyEcuals = (val1: number, val2: number, range: number = 10) => {
    if(
        (val1 >= val2 && val1 <= val2 + range) ||
        (val1 <= val2 && val1 >= val2 - range)
    ) return true;
    return false;
}

/**
 * get average value of array elements
 * @param arr 
 * @returns 
 */
export const average = (arr: number[]) => {
    return arr.reduce((a, c) => a + c, 0) / arr.length;
}

export function dataURItoBlob(dataURI: string) {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    return new Blob([ia], {type:mimeString});
}

export const getConvaImage = (
    layer: JSLayer,
    x: number,
    y: number,
    height: number,
    width: number,
    callback?: (image: string) => void) => {
    layer.toDataURL({
        x,
        y,
        height,
        width,
        callback
    });
};