import { Dictionary, fromPairs } from "lodash";

const getCookiesObject = () => {
    return fromPairs(document.cookie.split(';').map(item => {
        const [ key, value ] = item.split(/=(.*)/s);
        return [ key?.trim(), value?.trim() ];
    })) as Dictionary<string | undefined>;
};

export const getCookies = () => getCookiesObject();

export const getCookieByName = (name: string) => getCookiesObject()[name];