import React, { ComponentProps, FC, useMemo, useEffect } from 'react';
import { NTransformer, KonvaNodes } from './Transformer.index';
import { Transformer as KonvaTransformer } from 'react-konva';
import { Portal } from 'react-konva-utils';
import { CropperPresenter as Cropper } from '../Cropper/Cropper';

import { Transformer as JSKonvaTransformer } from 'konva/lib/shapes/Transformer';
import { average, untrulyEcuals } from '../../../utils';
import { KonvaComponents } from '../components';

import { Star } from 'konva/lib/shapes/Star';
import { Ellipse } from 'konva/lib/shapes/Ellipse';
import { Circle } from 'konva/lib/shapes/Circle';
import { Image } from 'konva/lib/shapes/Image';
import { getCrop } from './Transformer.crop';

/**
 * create model to systel line component
 * @param props 
 * @param id 
 * @returns 
 */
const systemLine = (props: NTransformer.Props['componentProps'], id: string) => ({
    id,
    name: id,
    type: 'Line' as const,
    fixed: true,
    permamenltyFixed: true,
    fixPosition: false,
    avaliableEditors: [],
    props: {
        dash: [15, 5],
        opacity: .5,
        ...props
    } as ComponentProps<(typeof KonvaComponents)['Line']>
});

/**
 * get coordinates line rect from all used node types
 * @param node 
 * @returns 
 */
const getComponentCoordinates = (node: KonvaNodes) => {
    let left = Math.round(node.x());
    let top = Math.round(node.y());
    let right = Math.round(left + (node.width() * node.scaleX()));
    let bottom = Math.round(top + (node.height() * node.scaleY()));

    const type = node.getAttr('reduxType');
    switch (type) {
        case 'Star': {
            const ns = node as Star;
            left = Math.round(node.x() - (ns.outerRadius() * node.scaleX()));
            top = Math.round(node.y() - (ns.outerRadius() * node.scaleY()));
            right = Math.round(left + (ns.outerRadius() * 2 * node.scaleX()));
            bottom = Math.round(top + (ns.outerRadius() * 2 * node.scaleY()));
            break;
        }
        case 'Ellipse': {
            const ne = node as Ellipse;
            left = Math.round(node.x() - (ne.radiusX() * node.scaleX()));
            top = Math.round(node.y() - (ne.radiusY() * node.scaleY()));
            right = Math.round(left + (ne.radiusX() * 2 * node.scaleX()));
            bottom = Math.round(top + (ne.radiusY() * 2 * node.scaleY()));
            break;
        }
        case 'Circle': {
            const nc = node as Circle;
            left = Math.round(node.x() - (nc.radius() * node.scaleX()));
            top = Math.round(node.y() - (nc.radius() * node.scaleY()));
            right = Math.round(left + (nc.radius() * 2 * node.scaleX()));
            bottom = Math.round(top + (nc.radius() * 2 * node.scaleY()));
            break;
        }
    }

    const verticalMiddle = Math.round(average([left, right]));
    const horizontalMiddle = Math.round(average([top, bottom]));
    
    return {
        left,
        top,
        right,
        bottom,
        verticalMiddle,
        horizontalMiddle
    };
};

const getComponentCoordinatesFromProps = (type: ComponentProps<typeof Transformer>['type'], props: ComponentProps<typeof Transformer>['componentProps']) => {
    let left = Math.round(props.x || 0);
    let top = Math.round(props.y || 0);
    let right = Math.round(left + (props.width || 0));
    let bottom = Math.round(top + (props.height || 0));

    switch (type) {
        case 'Star': {
            left = Math.round(left - props.outerRadius);
            top = Math.round(top - props.outerRadius);
            right = Math.round(left + (props.outerRadius * 2));
            bottom = Math.round(top + (props.outerRadius * 2));
            break;
        }
        case 'Ellipse': {
            left = Math.round(left - props.radiusX);
            top = Math.round(top - props.radiusY);
            right = Math.round(left + (props.radiusX * 2));
            bottom = Math.round(top + (props.radiusY * 2));
            break;
        }
        case 'Circle': {
            left = Math.round(left - props.radius);
            top = Math.round(top - props.radius);
            right = Math.round(left + (props.radius * 2));
            bottom = Math.round(top + (props.radius * 2));
            break;
        }
    }

    const verticalMiddle = Math.round(average([left, right]));
    const horizontalMiddle = Math.round(average([top, bottom]));
    
    return {
        left,
        top,
        right,
        bottom,
        verticalMiddle,
        horizontalMiddle
    };
}

/**
 * get all nodes coordinates by type
 * @param node 
 */
const getNewCoordinates = (node: KonvaNodes, props: Pick<ComponentProps<typeof Transformer>['componentProps'], 'x' | 'y'>) => {
    const type = node.getAttr('reduxType');
    switch (type) {
        case 'Star': {
            const ne = node as Star;
            return Object.typedKeys(props).reduce((a, key) => ({
                ...a,
                ...(
                    key === 'x' ? { [key]: (props[key] || 0) + ne.outerRadius()} :
                    key === 'y' ? { [key]: (props[key] || 0) + ne.outerRadius()} :
                    { [key]: props[key] }
                    )
            }), {} as typeof props);
        }
        case 'Ellipse': {
            const ne = node as Ellipse;
            return Object.typedKeys(props).reduce((a, key) => ({
                ...a,
                ...(
                    key === 'x' ? { [key]: (props[key] || 0) + ne.radiusX()} :
                    key === 'y' ? { [key]: (props[key] || 0) + ne.radiusY()} :
                    { [key]: props[key] }
                    )
            }), {} as typeof props);
        }
        case 'Circle': {
            const nc = node as Circle;
            return Object.typedKeys(props).reduce((a, key) => ({
                ...a,
                ...(
                    key === 'x' ? { [key]: (props[key] || 0) + nc.radius()} :
                    key === 'y' ? { [key]: (props[key] || 0) + nc.radius()} :
                    { [key]: props[key] }
                    )
            }), {} as typeof props);
        }
    }
    return props;
};

/**
 * get all nodes props coordinates by type
 * @param node 
 */
const getNewSizesAndPositionsOnTransform = (
    node: KonvaNodes,
    position: Pick<ComponentProps<typeof Transformer>['componentProps'], 'x' | 'y'>,
    props: Pick<ComponentProps<typeof Transformer>['componentProps'], 'height' | 'width'>
) => {
    const type = node.getAttr('reduxType');
    let fixedPosition: typeof position = position;
    switch (type) {
        case 'Star':
        case 'Ellipse':
        case 'Circle': {
            fixedPosition = Object.typedKeys(position).reduce((a, key) => ({
                ...a,
                ...(
                    key === 'x' ? { x: (position[key] || 0) + ((props.width || 0) / 2) } :
                    key === 'y' ? { y: (position[key] || 0) + ((props.height || 0) / 2) } :
                    { [key]: props[key] }
                    )
            }), {} as typeof position);
            break;
        }
        default: {
            fixedPosition = position;
            break;
        }
    }
    switch (type) {
        case 'Star': {
            const ns = node as Star;
            const precentInnerToOuter = ns.innerRadius() / ns.outerRadius();
            const sizes = Object.typedKeys(props).reduce((a, key) => ({
                ...a,
                ...(
                    key === 'width' ? { outerRadius: (props[key] || 0) / 2 } :
                    key === 'height' ? { outerRadius: (props[key] || 0) / 2 } :
                    { [key]: props[key] }
                    )
            }), {} as typeof props);
            return {
                ...fixedPosition,
                ...sizes,
                innerRadius: precentInnerToOuter * ((sizes as any).outerRadius || 0)
            }
        }
        case 'Ellipse': {
            const sizes = Object.typedKeys(props).reduce((a, key) => ({
                ...a,
                ...(
                    key === 'width' ? { radiusX: (props[key] || 0) / 2 } :
                    key === 'height' ? { radiusY: (props[key] || 0) / 2 } :
                    { [key]: props[key] }
                    )
            }), {} as typeof props);
            return {
                ...fixedPosition,
                ...sizes
            }
        }
        case 'Circle': {
            const sizes = Object.typedKeys(props).reduce((a, key) => ({
                ...a,
                ...(
                    key === 'width' || key === 'height' ? { radius: (props[key] || 0) / 2 } :
                    { [key]: props[key] }
                    )
            }), {} as typeof props);
            return {
                ...fixedPosition,
                ...sizes
            } 
        }
    }
    return {
        ...fixedPosition,
        ...props
    };
};

type AlignItemSides =
    'left' |
    'top' |
    'right' |
    'bottom' |
    'vertical-middle' |
    'horizontal-middle'

/**
 * get node align to shape
 * @param node 
 * @param shape 
 */
const getAlignTo = (node: ReturnType<typeof getComponentCoordinates>, shape: ReturnType<typeof getComponentCoordinates>, range?: number) => {

    const {
        left: nodeLeft,
        top: nodeTop,
        right: nodeRight,
        bottom: nodeBottom,
        verticalMiddle: nodeVerticalMiddle,
        horizontalMiddle: nodeHorizontalMiddle
    } = node;
                
    const {
        left: shapeLeft,
        top: shapeTop,
        right: shapeRight,
        bottom: shapeBottom,
        verticalMiddle: shapeVerticalMiddle,
        horizontalMiddle: shapeHorizontalMiddle
    } = shape;
    const sidesEcuals: AlignItemSides[] = [];

    const monitoringTop = () => {
        // check top side
        if(untrulyEcuals(nodeTop, shapeTop, range)) {
            sidesEcuals.push('top');
        }
    }

    const monitoringBottom = () => {
        // check bottom side
        if(untrulyEcuals(nodeBottom, shapeBottom, range)) {
            sidesEcuals.push('bottom');
        }
    }

    const monitoringLeft = () => {
        // check left side
        if(untrulyEcuals(nodeLeft, shapeLeft, range)) {
            sidesEcuals.push('left');
        }
    }

    const monitoringRight = () => {
        // check right side
        if(untrulyEcuals(nodeRight, shapeRight, range)) {
            sidesEcuals.push('right');
        }
    }

    const monitoringMiddle = () => {
        const sidesTrully: ('vertical' | 'horizontal')[] = [];

        // check vertical middle
        if(untrulyEcuals(nodeVerticalMiddle, shapeVerticalMiddle, range)) {
            sidesTrully.push('vertical');
            sidesEcuals.push('vertical-middle');
        };
    
        // check horizontal middle
        if(untrulyEcuals(nodeHorizontalMiddle, shapeHorizontalMiddle, range)) {
            sidesTrully.push('horizontal');
            sidesEcuals.push('horizontal-middle');
        };
    };
    
    monitoringTop();
    monitoringBottom();
    monitoringLeft();
    monitoringRight();
    monitoringMiddle();

    return {
        sidesEcuals
    };
}

const getSystemLayers = (node: KonvaNodes, sides: AlignItemSides[]) => {

    const {
        left: nodeLeft,
        top: nodeTop,
        right: nodeRight,
        bottom: nodeBottom,
        verticalMiddle: nodeVerticalMiddle,
        horizontalMiddle: nodeHorizontalMiddle
    } = getComponentCoordinates(node);

    return sides.map(side => {
        switch (side) {
            case 'top': {
                return systemLine({
                    points: [
                        0,
                        nodeTop,
                        node?.getLayer()?.getWidth() || 0,
                        nodeTop
                    ]
                }, `${node.getAttr('id')}-horizontal-top`)
            };
            case 'bottom': {
                return systemLine({
                    points: [
                        0,
                        nodeBottom,
                        node?.getLayer()?.getWidth(),
                        nodeBottom
                    ]
                }, `${node.getAttr('id')}-horizontal-bottom`)
            };
            case 'left': {
                return systemLine({
                    points: [
                        nodeLeft,
                        0,
                        nodeLeft,
                        node?.getLayer()?.getHeight() || 0
                    ]
                }, `${node.getAttr('id')}-vertical-left`)
            };
            case 'right': {
                return systemLine({
                    points: [
                        nodeRight,
                        0,
                        nodeRight,
                        node?.getLayer()?.getHeight()
                    ]
                }, `${node.getAttr('id')}-vertical-right`)
            };
            case 'horizontal-middle': {
                return systemLine({
                    points: [
                        0,
                        nodeHorizontalMiddle,
                        node?.getLayer()?.getWidth(),
                        nodeHorizontalMiddle
                    ]
                }, `${node.getAttr('id')}-horizontal-middle`)
            };
            case 'vertical-middle': {
                return systemLine({
                    points: [
                        nodeVerticalMiddle,
                        0,
                        nodeVerticalMiddle,
                        node?.getLayer()?.getHeight()
                    ]
                }, `${node.getAttr('id')}-vertical-middle`)
            };
        }
    }).filter(e => e);
}


export const Transformer: FC<NTransformer.Props> = ({
    id,
    type,
    component: Component,
    componentProps,
    isSelected,
    isCropped,
    hide,
    fixed,
    fixPosition,
    onChange,
    addSystemLayers,
    ...props
}) => {

    const shapeRef = React.createRef<KonvaNodes>();
    const trRef = React.createRef<JSKonvaTransformer>();

    useEffect(() => {
        if (isSelected) {
            if(!shapeRef.current) return ;
            // we need to attach transformer manually
            trRef.current?.nodes([shapeRef.current]);
            trRef.current?.getLayer()?.batchDraw();
        }
    }, [isSelected, shapeRef]);

    useEffect(() => {
        if(hide) {
            shapeRef.current?.hide();
            trRef.current?.hide();
        } else {
            shapeRef.current?.show();
            trRef.current?.show();
        }
    }, [hide, shapeRef, trRef]);

    useEffect(() => {
        if(!shapeRef.current) return ;
        switch (type) {
            case 'Text':{
                onTransform();
                // initProps();
                // onTransformEnd();
                break;
            }
            // case 'Image':{
            //     onTransformEnd();
            //     break;
            // }
        }
    }, [shapeRef]);

    useEffect(() => {
        if(!shapeRef.current) return ;
        switch (type) {
            case 'Text': {
                initProps();
                break;
            }
        }
    }, []);

    useEffect(() => {
        if(!trRef.current) return ;
        if(isCropped) {
            trRef.current.hide();
        } else {
            trRef.current.show();
        }
    }, [isCropped]);

    const clearSystemLayers = () => {
        addSystemLayers([]);
    }

    const onTransformEnd = () => {
        clearSystemLayers();
        // transformer is changing scale of the node
        // and NOT its width or height
        // but in the store we have only width and height
        // to match the data better we will reset scale on transform end
        const node = shapeRef.current as any;
        // node?.scaleX
        if(!node) return;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);

        let props: Parameters<typeof onChange>['0'] = {
            x: Math.round(node.x()),
            y: Math.round(node.y()),
        };

        switch (type) {
            case 'Text':{
                props = {
                    ...props,
                    width: Math.round(Math.max(node.width() * scaleX, 20)),
                    padding: node.padding(),
                    lineHeight: node.lineHeight(),
                    textAlign: node.align(),
                    rotation: node.rotation()
                };
                break;
            }
            case 'Star':{
                props = {
                    ...props,
                    innerRadius: Math.round(node.innerRadius() * scaleY),
                    outerRadius: Math.round(node.outerRadius() * scaleY),
                    rotation: node.rotation()
                };
                break;
            }
            case 'Ellipse': {
                props = {
                    ...props,
                    radiusX: Math.round(node.radiusX() * scaleX),
                    radiusY: Math.round(node.radiusY() * scaleY),
                    rotation: node.rotation()
                }
                break;
            }
            case 'Circle': {
                props = {
                    ...props,
                    radius: Math.round(Math.max(node.radius() * scaleY)),
                    rotation: node.rotation()
                }
                break;
            }
            default: {
                props = {
                    ...props,
                    width: Math.round(Math.max(5, node.width() * scaleX)),
                    height: Math.round(Math.max(5, node.height() * scaleY)),
                    rotation: node.rotation() || 0
                };
                break;
            }
        }

        onChange(props);
    }

    /**
     * use to draw system line to view align about other items on field
     * @param event 
     * @returns 
     */
    const makeSystemLinesOnMove: ComponentProps<typeof KonvaTransformer>['onDragMove'] = (event) => {
        console.log('system on move');
        // clear all line if presed ctrl key 
        if(event.evt.ctrlKey) {
            clearSystemLayers();
            return ;
        }

        const node = shapeRef.current;

        const lines = node?.getLayer()?.children?.map(shape => {
            // if not shape then not check sides and line is empty array
            if(shape.getType() !== 'Shape') return [];
            if(id !== shape.getAttr('id')) {

                if(
                    node.rotation() !== shape.rotation()
                ) return [];

                // get current dragging shape borders & center
                const nodeCoordinates = getComponentCoordinates(node);

                // get other shape borders & center
                const shapeCoordinates = getComponentCoordinates(shape as KonvaNodes);
                
                const {
                    left: shapeLeft,
                    top: shapeTop,
                    right: shapeRight,
                    bottom: shapeBottom,
                    verticalMiddle: shapeVerticalMiddle,
                    horizontalMiddle: shapeHorizontalMiddle
                } = shapeCoordinates;
                
                // prepare align value
                const {
                    sidesEcuals
                } = getAlignTo(nodeCoordinates, shapeCoordinates);
                
                // posistioning item by sidesEcuals defined
                if(sidesEcuals.includes('top')) node.y(getNewCoordinates(node, { y: shapeTop }).y || node.y());
                if(sidesEcuals.includes('bottom')) node.y(getNewCoordinates(node, { y: shapeBottom - Math.round(node.height() * node.scaleY()) }).y || node.y());
                if(sidesEcuals.includes('left')) node.x(getNewCoordinates(node, { x: shapeLeft }).x || node.x());
                if(sidesEcuals.includes('right')) node.x(getNewCoordinates(node, { x: shapeRight - Math.round(node.width() * node.scaleX()) }).x || node.x());
                if(sidesEcuals.includes('horizontal-middle')) node.y(getNewCoordinates(node, { y: shapeHorizontalMiddle - (Math.round(node.height() * node.scaleY()) / 2) }).y || node.y());
                if(sidesEcuals.includes('vertical-middle')) node.x(getNewCoordinates(node, { x: shapeVerticalMiddle - (Math.round(node.width() * node.scaleX()) / 2) }).x || node.x());

                // get new current dragging shape borders & center
                const newNodeCoordinates = getComponentCoordinates(node);

                // prepare full equals align value
                const {
                    sidesEcuals: fullEcualsSides
                } = getAlignTo(newNodeCoordinates, shapeCoordinates, 1);

                const systemLayers = getSystemLayers(node, fullEcualsSides);

                // return all layers for shape
                return systemLayers;
            }
            // if 'id' shape as node then return empty lines
            return [];
        }).reduce((a, c) => {
            // define to simple array
            return [...a, ...c]
        }, []);

        lines && addSystemLayers(lines);
    }

    
    /**
     * make system lines when transform element
     * @param event 
     */
    const makeSystemLinesOnTransform: ComponentProps<typeof KonvaTransformer>['onTransform'] = (event) => {
        console.log('system on transform');
        // clear all line if presed ctrl key 
        // "as type" because in konva that type typed with mistakes
        if((event.evt as typeof event.evt & { ctrlKey: boolean } ).ctrlKey) {
            clearSystemLayers();
            return ;
        }

        const node = shapeRef.current;

        const lines = node?.getLayer()?.children?.map(shape => {
            // if type not shape then return empty lines
            if(shape.getType() !== 'Shape') return [];
            if(id !== shape.getAttr('id')) {

                const changingSides: AlignItemSides[] = [];

                if(
                    (componentProps.rotation || 0) !== node.rotation() ||
                    node.rotation() !== shape.rotation()
                ) return [];

                // get current transforming shape borders & center
                const nodeCoordinates = getComponentCoordinates(node);
                const {
                    left: nodeLeft,
                    top: nodeTop,
                    right: nodeRight,
                    bottom: nodeBottom,
                    verticalMiddle: nodeVerticalMiddle,
                    horizontalMiddle: nodeHorizontalMiddle
                } = nodeCoordinates;

                const cProps = getComponentCoordinatesFromProps(type, componentProps);

                const {
                    left: cLeft,
                    top: cTop,
                    right: cRight,
                    bottom: cBottom,
                } = cProps;

                if(nodeLeft !== cLeft) changingSides.push('left');
                if(nodeTop !== cTop) changingSides.push('top');
                if(nodeRight !== cRight) changingSides.push('right');
                if(nodeBottom !== cBottom) changingSides.push('bottom');

                // get other shape borders & center
                const shapeCoordinates = getComponentCoordinates(shape as KonvaNodes);
                const {
                    left: shapeLeft,
                    top: shapeTop,
                    right: shapeRight,
                    bottom: shapeBottom,
                    verticalMiddle: shapeVerticalMiddle,
                    horizontalMiddle: shapeHorizontalMiddle
                } = shapeCoordinates;

                let {
                    sidesEcuals
                } = getAlignTo(nodeCoordinates, shapeCoordinates);

                sidesEcuals = sidesEcuals.filter(el => changingSides.includes(el));

                // // posistioning item by sidesEcuals defined
                if(sidesEcuals.includes('top')) {
                    const newProps = getNewSizesAndPositionsOnTransform(node, { y: shapeTop }, { height: cBottom - shapeTop });
                    node.scaleY(1);
                    node.setAttrs(newProps);
                }
                if(sidesEcuals.includes('bottom')) {
                    const newProps = getNewSizesAndPositionsOnTransform(node, { y: nodeTop }, { height: shapeBottom - nodeTop });
                    node.scaleY(1);
                    node.setAttrs(newProps);
                }
                if(sidesEcuals.includes('left')) {
                    const newProps = getNewSizesAndPositionsOnTransform(node, { x: shapeLeft }, { width: cRight - shapeLeft });
                    node.scaleX(1);
                    node.setAttrs(newProps);
                }
                if(sidesEcuals.includes('right')) {
                    const newProps = getNewSizesAndPositionsOnTransform(node, { x: nodeLeft }, { width: shapeRight - nodeLeft });
                    node.scaleX(1);
                    node.setAttrs(newProps);
                }

                const systemLayers = getSystemLayers(node, sidesEcuals);

                // return all layers for shape
                return systemLayers;
            }
            // if 'id' shape as node then return empty lines
            return [];
        }).reduce((a, c) => [...a, ...c], []);

        lines && addSystemLayers(lines);
    }

    const onDragStart: ComponentProps<typeof KonvaTransformer>['onDragStart'] = (e) => {
        makeSystemLinesOnMove(e);
    }

    const onDragMove: ComponentProps<typeof KonvaTransformer>['onDragMove'] = (e) => {
        makeSystemLinesOnMove(e);
    }

    const onDragEnd: ComponentProps<typeof KonvaTransformer>['onDragEnd'] = (e) => {
        clearSystemLayers();
    }

    const onTransform = () => {
        const node = shapeRef.current;
        if(!node) return;
        const scaleX = node.scaleX();
        // const scaleY = node.scaleY();

        let props: Parameters<typeof onChange>['0'] = {};

        switch (type) {
            case 'Text':{
                props = {
                    ...props,
                    scaleX: 1,
                    scaleY: 1,
                    width: Math.max(node.width() * scaleX, 20)
                };
                break;
            }
        }

        node.setAttrs(props);
        // makeSystemLines();
    };

    const initProps = () => {
        const node = shapeRef.current;
        // node?.scaleX
        if(!node) return;
        const scaleX = node.scaleX();
        // const scaleY = node.scaleY();

        let props: Parameters<typeof onChange>['0'] = {};

        switch (type) {
            case 'Text':{
                props = {
                    ...props,
                    scaleX: 1,
                    scaleY: 1,
                    width: Math.max(node.width() * scaleX, 20)
                };
                break;
            }
        }

        onChange(props);
    }

    // const onTransform = useDebounce(onTransformEnd, 10, [shapeRef]);

    let anchors: ComponentProps<typeof KonvaTransformer>['enabledAnchors'] = useMemo(() => {
        if(fixed) return [];
        switch (type) {
            case 'Star':
            case 'Circle':
            case 'Image':
                return ['top-left', 'top-right', 'bottom-left', 'bottom-right'];
            case 'Text':
                return ['middle-left', 'middle-right'];
            case 'Line':
                return ['middle-left', 'middle-right']
        }
        return undefined;
    }, [type, fixed]);

    return <>
        <Component 
            {...{
                ...componentProps,
                ref: shapeRef
            } as any}
            strokeScaleEnabled={false}
        />
        {
            isCropped &&
            <Cropper componentRef={shapeRef} />
        }
        <Portal selector=".overflow" enabled={isSelected}>
            {isSelected && (
                <KonvaTransformer
                    {...props}
                    listening={!fixed}
                    ignoreStroke={true}
                    ref={trRef}
                    onTransform={(e) => {
                        onTransform();
                        // makeSystemLinesOnTransform(e);
                    }}
                    onDragStart={onDragStart}
                    onDragMove={onDragMove}
                    onDragEnd={onDragEnd}
                    onTransformEnd={onTransformEnd}
                    enabledAnchors={anchors}
                    rotateEnabled={!fixed}
                    rotateAnchorOffset={25}
                    shouldOverdrawWholeArea={true}
                    onMouseOver={() => {
                        document.body.style.cursor = 'pointer';
                    }}
                    onMouseOut={() => {
                        document.body.style.cursor = 'default';
                    }}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            )}
        </Portal>
    </>
};