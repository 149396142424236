
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import { useItemActions } from "../../redux/items/hooks";
import { getItemsFields } from "../../redux/items/selectors";
import { settingsChangeField } from "../../redux/konvaSettings/actions";
import { useKonvaLayout } from "../../redux/konvaSettings/hooks";
import { getKonvaSettingsFields } from "../../redux/konvaSettings/selectors";

export const useKonva = () => {

    const dispatch = useAppDispatch();

    const {
        layout,
        onChange: onLayoutChange
    } = useKonvaLayout();

    const itemsFields = useAppSelector(getItemsFields(['layers', 'systemLayers', 'edit', 'select']));

    const konvaSettingsFields = useAppSelector(getKonvaSettingsFields(['save', 'border']));

    const {
        changeItem
    } = useItemActions();

    const changeSettingsField = (...args: Parameters<typeof settingsChangeField>) => {
        dispatch(settingsChangeField(...args));
    };

    return {
        ...itemsFields,
        ...konvaSettingsFields,
        layout,
        onLayoutChange,
        changeSettingsField,
        itemsChange: changeItem
    }

};