import { createContext, useContext } from "react";
import { ReactZoomPanPinchContentRef } from 'react-zoom-pan-pinch';

export const useZoomerContextRef = () => {
    const state = useContext(ZoomerContext);
    return state.ref;
};

export const useZoomerContextChange = () => {
    const state = useContext(ZoomerContext);
    return state.changeZoomerContext;
};

export type ZoomerState = {
    ref: ReactZoomPanPinchContentRef | null;
    changeZoomerContext: (ref: ZoomerState['ref']) => void;
};

export const initialZoomerState: ZoomerState = {
    ref: null,
    changeZoomerContext: () => {}
};

/**
 * context to ref for zoomer to make actions in external components
 */
export const ZoomerContext = createContext(initialZoomerState);