import React, { ComponentProps, createRef, FC, useContext, useEffect, useMemo, useState } from 'react';
import { NKonvaLayout } from './Layout.index';
import { connect } from 'react-redux';
import { Portal } from 'react-konva-utils';
import { Line, Layer, Rect, Circle, Transformer, Text } from 'react-konva';
import { Background } from "../Background/Background";
import { Rect as RectJS } from 'konva/lib/shapes/Rect';
import { Layer as JSLayer } from 'konva/lib/Layer';
import { Transformer as TransformerJS } from 'konva/lib/shapes/Transformer';
import { Text as TextJS } from 'konva/lib/shapes/Text';
import { useDidUpdateEffect } from '../../../hooks';
import { dataURItoBlob, getConvaImage } from '../../../utils';
import { EnvironmentContext } from '../../../environment';
import { useEmbedActions } from '../../../embed/other';
import { useItemActions } from '../../../redux/items/hooks';
import { useKonvaLayout, useKonvaSettings } from '../../../redux/konvaSettings/hooks';

const removeKeyCodes = ['Backspace', 'Delete'];

function downloadURI(uri: string, name: string) {
    var link = document.createElement('a');
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
}

const LayoutPresenter: FC<NKonvaLayout.Props> = ({
    children
}) => {

    const { inFrame } = useContext(EnvironmentContext);

    const {
        settings
    } = useKonvaSettings();

    const {
        // layout,
        edit,
        save,
        circle,
    } = settings;

    const {
        layout,
        onChange
    } = useKonvaLayout();

    const itemActions = useItemActions();

    const mainLayerRef = createRef<JSLayer>();

    useEffect(() => {
        window.konvaMainLayer = mainLayerRef.current;
    }, [mainLayerRef.current]);

    const {
        saveImage,
        giveImage
    } = useEmbedActions();

    useDidUpdateEffect(() => {
        if(mainLayerRef.current) {
            if(saveImage) {
                saveImage(
                    propsX,
                    propsY,
                    propsHeight,
                    propsWidth,
                )
            } else {
                getConvaImage(
                    mainLayerRef.current,
                    propsX,
                    propsY,
                    propsHeight,
                    propsWidth,
                    (img) => {
                        downloadURI(img, 'image.png');
                    }
                );
            }
        }
    }, [save]);

    useEffect(() => {
        window.addEventListener('keyup', (e) => {
            if(removeKeyCodes.includes(e.key) && !(window as any).settingsIsActive && !(window as any).editIsActive) {
                itemActions.removeSelected();
            }
        });
    }, []);
    
    const {
        props
    } = layout;

    const { width: propsWidth, height: propsHeight, x: propsX, y: propsY } = props;

    useEffect(() => {
        const giveImagePostMessage = (event: MessageEvent<any>) => {
            if(window.konvaMainLayer && giveImage && event.data.event === 'getCurrentImage') {
                console.log('getCurrentImage');
                giveImage(
                    propsX,
                    propsY,
                    propsHeight,
                    propsWidth
                );
            }
        };
        window.addEventListener("message", giveImagePostMessage);
        return () => {
            window.removeEventListener('message', giveImagePostMessage);
        };
    }, [propsX, propsY, propsHeight, propsWidth]);

    const [ state, setState ] = useState({
        x: propsX,
        y: propsY,
        width: propsWidth,
        height: propsHeight
    });

    useEffect(() => {
        setState({
            x: propsX,
            y: propsY,
            width: propsWidth,
            height: propsHeight
        });
    }, [propsX, propsY, propsWidth, propsHeight]);

    const { x, y, height, width } = state;

    const removeSelect = () => itemActions.selectItem(null);

    const rectRef = React.createRef<RectJS>();
    const trRef = React.createRef<TransformerJS>();
    const infoTextRef = React.createRef<TextJS>();

    // useEffect(() => {
    //     if(!rectRef.current) return;
    //     rectRef.current?.getLayer().cli
    // }, []);

    React.useEffect(() => {
        if (edit) {
        // we need to attach transformer manually
            // rectRef.current?.draggable(false);
            rectRef.current && trRef.current?.nodes([rectRef.current]);
            // trRef.current?.draggable(false);
            trRef.current?.getLayer()?.batchDraw();
        } else {
            onChange({
                ...layout,
                props: {
                    x,
                    y,
                    width,
                    height
                }
            });
        }
    }, [edit]);

    const fill = '#fff';

    const clipFunc: ComponentProps<typeof Layer>['clipFunc'] = (ctx) => {
        if(circle) {
            ctx.arc(x + (width / 2), y + (width / 2), width / 2, 0, Math.PI * 2, false);
        } else {
            ctx.rect(x, y, width, height);
        }
    };

    let anchors: ComponentProps<typeof Transformer>['enabledAnchors'] = useMemo(() => {
        if(circle) return ['bottom-right'];
        return ['bottom-center', 'bottom-right', 'middle-right'];
    }, [circle]);

    useEffect(() => {
        if(circle) {
            setState({
                x,
                y,
                width,
                height: width
            });
        }
    }, [circle]);

    return (
        <Layer
            ref={mainLayerRef}
            clipFunc={clipFunc}
        >
            <Portal selector=".under" enabled={true}>
                <Rect
                    x={0}
                    y={0}
                    width={width + (x * 2)}
                    height={height + (y * 2)}
                    onClick={removeSelect}
                />
            </Portal>
            <Rect
                id={'main'}
                ref={rectRef}
                onClick={removeSelect}
                x={x}
                y={y}
                width={width}
                height={height}
            />
            {children}
            <Portal selector=".layout" enabled={true}>
                {
                    circle ?
                    <Circle
                        x={(x - 1) + ((width + 2) / 2)}
                        y={(y - 1) + ((width + 2) / 2)}
                        radius={(width + 2) / 2}
                        listening={edit}
                        stroke={'black'}
                        strokeWidth={1}
                    />
                    :
                    <Rect
                        x={x - 1}
                        y={y - 1}
                        width={width + 2}
                        height={height + 2}
                        listening={edit}
                        stroke={'black'}
                        strokeWidth={1}
                    />
                }
                {
                    edit && 
                    <Text
                        ref={infoTextRef}
                        x={x}
                        y={y}
                        width={width}
                        height={height}
                        text={`${height}x${width}`}
                        listening={false}
                        fontSize={20}
                        align={'center'}
                        verticalAlign={'middle'}
                    />
                }
                {
                    edit && 
                    <Transformer
                        ref={trRef}
                        rotateEnabled={false}
                        enabledAnchors={anchors}
                        onTransform={(e) => {
                            const node = rectRef.current;
                            const textNode = infoTextRef.current;
                            if(!node || !textNode) return;
                            const scaleX = node.scaleX();
                            const scaleY = node.scaleY();

                            const width = Math.round(Math.max(5, node.width() * scaleX) || layout.props.width);
                            const height = Math.round(Math.max(5, node.height() * scaleY) || layout.props.width);

                            textNode.setText(`${height}x${width}`);
                        }}
                        onTransformEnd={(e) => {
                            // transformer is changing scale of the node
                            // and NOT its width or height
                            // but in the store we have only width and height
                            // to match the data better we will reset scale on transform end
                            const node = rectRef.current;
                            // node?.scaleX
                            if(!node) return;
                            const scaleX = node.scaleX();
                            const scaleY = node.scaleY();
                    
                            // we will reset it back
                            node.scaleX(1);
                            node.scaleY(1);
                            // node.x(border);
                            // node.y(border);
                            // trRef.current?.x(border);
                            // trRef.current?.y(border);
                            setState({
                                x: node.x(),
                                y: node.y(),
                                width: Math.round(Math.max(5, node.width() * scaleX) || layout.props.width),
                                height: Math.round(Math.max(5, node.height() * scaleY) || layout.props.width)
                            })
                        }}
                    />
                }
            </Portal>
        </Layer>
    )
}

export const Layout = LayoutPresenter;