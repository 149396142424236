import { cn } from '@bem-react/classname';

export const cnTextEditor = cn('TextEditor');

export namespace NTextEditor {

    export interface Props extends OwnProps {

    }

    export interface OwnProps {
        editAccept: (data: string) => void;
        editCancel: () => void;
    }
};