import { NKonvaSettings } from './interface';
import {
    SETTINGS_CHANGE_FIELD,
    SETTINGS_CHANGE_LAYOUT,
    SETTINGS_CHANGE_REDUCER
} from './actions.types';
import { baseReducer } from './constants';

export const konvaSettings = (state: NKonvaSettings.Reducer = baseReducer, action: NKonvaSettings.Actions): NKonvaSettings.Reducer => {
    switch (action.type) {
        case SETTINGS_CHANGE_FIELD: {
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }
        }
        case SETTINGS_CHANGE_LAYOUT: {
            return {
                ...state,
                layout: action.payload
            }
        }
        case SETTINGS_CHANGE_REDUCER: {
            return {
                ...state,
                ...action.payload
            }
        }
        default:
            return state;
    }
}