import React, { FC, useEffect } from 'react';
import { NEditorWrapper, cnEditorWrapper } from './EditorWrapper.index';
import './EditorWrapper.scss';
import { TextEditor } from '../Text/Editor/TextEditor';
import { Box } from '@mui/system';
import { useItem, useItemsActions } from '../../../redux/items/hooks';
import { useAppSelector } from '../../../redux/hooks';
import { getItemsField } from '../../../redux/items/selectors';

const EditorWrapperPresenter: FC<NEditorWrapper.Props> = ({
}) => {

    const editItemId = useAppSelector(getItemsField('edit'));

    const {
        item,
        changeItem
    } = useItem(editItemId || '');

    const itemsActions = useItemsActions();

    useEffect(() => {
        (window as any).editIsActive = true;
        return () => {
            (window as any).editIsActive = false;
        }
    }, []);

    const editCancel = () => itemsActions.changeField({ field: 'edit', value: null });

    if(!item) return null;

    const { id, type } = item;

    return (
        <Box className={cnEditorWrapper()} sx={{
            left: item.props.x,
            top: item.props.y,
            fontSize: item.props.fontSize,
            fontFamily: item.props.fontFamily,
            width: item.props.width,
            height: item.props.height,
            padding: item.props.padding,
            lineHeight: item.props.lineHeight || `${item.props.fontSize}px`,
            textAlign: item.props.align,
            transform: item.props.rotation ? `rotateZ(${item.props.rotation}deg)` : undefined,
            transformOrigin: '0 0 0'
        }}>
            {
                type === 'Text' &&
                <TextEditor
                    editAccept={(data: string) => {
                        changeItem({
                            id,
                            props: {
                                text: data
                            }
                        });
                        editCancel();
                    }}
                    editCancel={editCancel}
                />
            }
        </Box>
    )
};

export const EditorWrapper = EditorWrapperPresenter;

// import { TextEditor as Text } from './Text/Editor/TextEditor';
// import { ComponentProps } from 'react';

// export const KonvaEditorsTypes = [
//     'Text',
// ] as const;

// export const KonvaEditors = {
//     Text
// }

// type KonvaEditorsType = typeof KonvaEditors;

// type IItems = {
//     [Key in keyof KonvaEditorsType]: KonvaEditorsType[Key];
// };

// export type KonvaEditorsUnion = IItems[keyof IItems];

// export type KonvaEditorsUnionProps = ComponentProps<IItems[keyof IItems]>;