import React, { FC, useState, ComponentProps } from 'react';
import { NBordersColor } from './BordersColor.index';
import { SketchPicker } from 'react-color';
import { useDebounce } from '../../../../utils';
import { Wrapper } from '../Wrapper/Wrapper';
import BorderColorIcon from '@mui/icons-material/BorderColor';

export const BordersColor: FC<NBordersColor.Props> = ({
    stroke = '#ffffff',
    ButtonProps,
    onChange
}) => {

    type Dfss = Required<Pick<ComponentProps<typeof SketchPicker>, 'onChange'>>;

    const onColorChange = useDebounce<Dfss['onChange']>((color, e) => {
        onChange({
            stroke: color.hex
        });
    }, 5, []);

    return (
        <Wrapper
            icon={<BorderColorIcon />}
            ButtonProps={{
                ...ButtonProps,
                sx: {
                    color: stroke !== '#ffffff' ? stroke : undefined
                }
            }}
            PopoverProps={{
                PaperProps: {
                    sx: {
                        width: 'auto'
                    }
                }
            }}
        >
            <SketchPicker
                color={stroke}
                onChange={onColorChange}
            />
        </Wrapper>
    )
}