import { cn } from '@bem-react/classname';
import React, { FC, useMemo } from 'react';
import { IClassNameProps } from '../../../types.base';
import { MenuButton, StyledButtonGroup } from '../../ItemsMenu/ItemsMenu.styled';
import { useTemplate, useTemplates } from '../Templates.hooks';
import { NTemplates } from '../redux/types.Templates';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import { useDispatch, useSelector } from 'react-redux';
import { getBaseField } from '../../../redux/base/selectors';
import { baseChangeField } from '../../../redux/base/actions';
import { styled } from '@mui/material';
import { i18next } from '../../../localization';

const cnTemplatesMenu = cn('TemplatesMenu');
const cnTemplatesMenuItem = cn('TemplatesMenuItem');

const TemlatesMenuBox = styled('div')({
    display: 'flex',
    justifyContent: 'center'
});

const TemplateMenuItem: FC<IClassNameProps & {
    id: keyof NTemplates.Reducer['templates']
}> = ({
    id,
    className
}) => {

    const {
        template,
        selectTemplate
    } = useTemplate(id);

    return (
        <MenuButton
            variant="outlined"
            onClick={selectTemplate}
            className={cnTemplatesMenuItem({}, [className])}
            title={template.name}
        >   
            <img src={template.previewUrl} />
        </MenuButton>
    );
};

const TemplatesMenuPr: FC<IClassNameProps> = ({
    className
}) => {

    const dispatch = useDispatch();

    const openedMenu = useSelector(getBaseField('openedMenu'));

    const {
        list
    } = useTemplates();

    const firstSixTemplates = list.slice(0, 5);

    return (
        <TemlatesMenuBox
            className={cnTemplatesMenu({}, [className])}
        >   
            <StyledButtonGroup>
            {
                firstSixTemplates.map((id) => {
                    return (
                        <TemplateMenuItem key={id} id={id} />
                    );
                })
            }
                <MenuButton
                    key={'bookmarksButton'}
                    variant={openedMenu === 'templates' ? "contained" : "outlined"}
                    title={i18next.t('editor.templates')}
                    onClick={() => {
                        dispatch(
                            baseChangeField({
                                field: 'openedMenu',
                                value: openedMenu === 'templates' ? null : 'templates'
                            })
                        );
                    }}
                >
                    <BookmarkBorderIcon />
                </MenuButton>
            </StyledButtonGroup>
        </TemlatesMenuBox>
    );
};

export const TemplatesMenu = TemplatesMenuPr;