import { createContext, useContext, useState } from "react";
import { Omit } from "./utils.types";

type TEnvironmentContext = {
    inFrame: boolean;
}

const baseEnvironmentContext: TEnvironmentContext = {
    inFrame: window.self !== window.top
};

export const EnvironmentContext = createContext(baseEnvironmentContext);

export const useEnvironment = () => {

    const [ environment, setEnvironment ] = useState<TEnvironmentContext>(baseEnvironmentContext);

    return environment;
    
};