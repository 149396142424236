import React, { ComponentProps, FC, useMemo } from 'react';
import { NSystemWrapper } from './SystemWrapper.index';
import { connect } from 'react-redux';
import { KonvaComponents } from '../components';
import { useItem } from '../../../redux/items/hooks';

/**
 * compoenent to render system layers
 * @param param0 
 * @returns 
 */
const SystemWrapperPresenter: FC<NSystemWrapper.Props> = ({
    dataId,
}) => {

    const {
        item,
    } = useItem(dataId);

    const {
        id,
        type,
        props,
    } = item;

    const Component = useMemo(() => KonvaComponents[type], [dataId, type]);

    return (
        <Component
            {...props as any}
            key={id}
            id={id}
            listening={false}
        />
    )
};

export const SystemWrapper = SystemWrapperPresenter;