import React, { ComponentProps, FC, useState } from 'react';
import { cnSelectUrl, NSelectUrl } from './SelectUrlDialog.index';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { createDialog } from '../DialogCreate';
import './SelectUrlDialog.scss';

const IconItem: FC<{
    url: NSelectUrl.Props['urls'][number];
    buttonProps?: ComponentProps<typeof Button>;
}> = ({
    url,
    buttonProps
}) => {

    if(!url) return null;

    return (
        <Button
            {...buttonProps}
            variant={buttonProps?.variant || 'outlined'}
            className={cnSelectUrl('Icon', {}, [buttonProps?.className])}
        >
            <img src={url} />
        </Button>
    )
};

/**
 * 
 * @param param0 
 * @returns 
 */
const SelectUrlPr: FC<NSelectUrl.Props> = ({
    urls,
    handleAccept,
    handleClose
}) => {

    const [ selectedItem, setSelectedItem ] = useState<string>('');

    return (
        <>
            <DialogTitle>
                Select image
            </DialogTitle>
            <DialogContent className={cnSelectUrl('Content')}>
                <div className={cnSelectUrl('Icons')}>
                    {
                        urls.map((url, idx) => {
                            return (
                                <IconItem
                                    key={idx}
                                    url={url}
                                    buttonProps={{
                                        onClick: () => setSelectedItem(url),
                                        onDoubleClick: () => handleAccept({ url }),
                                        color: selectedItem === url ? 'secondary' : 'primary'
                                    }}
                                />
                            )
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                >
                    Close
                </Button>
                <Button
                    onClick={() => {
                        const url = urls.find(url => url.toString() === selectedItem);
                        if(url) handleAccept({ url });
                    }}
                >
                    Accept
                </Button>
            </DialogActions>
        </>
    )
};

export const SelectUrl = createDialog(SelectUrlPr);