import { cn } from '@bem-react/classname';
import React, { ComponentProps, createContext, FC, useEffect, useRef, useState } from 'react';
import { ItemsMenu } from '../ItemsMenu/ItemsMenu';
import { TemplatesMenu } from '../Templates/TemplatesMenu';
import { Menu } from './TopMenu.styled';

const cnTopMenu = cn('TopMenu');

const TopMenuPr: FC<ComponentProps<typeof Menu>> = ({
    className,
    children,
    ...props
}) => {

    return (
        <Menu
            {...props}
            className={cnTopMenu({}, [className])}
        >
            <ItemsMenu />
            <TemplatesMenu />
            {children}
        </Menu>
    );
};

export const TopMenu = TopMenuPr;