import React, { FC } from 'react';
import { useSelectItem } from '../../../../redux/items/hooks';
import { SelectPositionWrapper } from '../../SelectPositionWrapper/SelectPositionWrapper';
import CompareIcon from '@mui/icons-material/Compare';
import { Button, styled } from '@mui/material';
import { SelectUrl } from '../../../SelectUrlDialog/SelectUrlDialog';

const StyledButton = styled(Button)({
    background: '#fff',
    padding: 5,
    minWidth: 0
});


export const ImageChange: FC<{}> = ({

}) => {

    const {
        item,
        changeSelectedItem
    } = useSelectItem();

    if(!item || item.type !== 'Image' || !item.props?.urls || !item.props?.urls.length) return null;

    return (
        <SelectPositionWrapper>
            <StyledButton
                variant={'outlined'}
                onClick={() => {
                    SelectUrl({
                        urls: item.props?.urls
                    })
                    .then(({ url }) => {
                        console.log('edit');
                        changeSelectedItem({
                            props: {
                                url
                            }
                        });
                    })
                    .catch(() => {})
                }}
            >
                <CompareIcon />
            </StyledButton>
        </SelectPositionWrapper>
    );
};