import {
    TMPLS_ADD_TEMPLATE,
    TMPLS_SET_TEMPLATES,
    TMPLS_REMOVE_TEMPLATE,
    TMPLS_SET_TEMPLATES_LIST,
    TMPLS_UPDATE_TEPMLATE,
    TMPLS_ADD_TEMPLATES
} from './actions.types.Templates';
import { NTemplates } from './types.Templates';

export const templatesSetTemplates: NTemplates.ActionFunction<'TMPLS_SET_TEMPLATES'> = (payload) => ({
    type: TMPLS_SET_TEMPLATES,
    payload
});

export const templatesAddTemplate: NTemplates.ActionFunction<'TMPLS_ADD_TEMPLATE'> = (payload) => ({
    type: TMPLS_ADD_TEMPLATE,
    payload
});

export const templatesAddTemplates: NTemplates.ActionFunction<'TMPLS_ADD_TEMPLATES'> = (payload) => ({
    type: TMPLS_ADD_TEMPLATES,
    payload
});

export const templatesRemoveTemplate: NTemplates.ActionFunction<'TMPLS_REMOVE_TEMPLATE'> = (payload) => ({
    type: TMPLS_REMOVE_TEMPLATE,
    payload
});

export const templatesSetTemplatesList: NTemplates.ActionFunction<'TMPLS_SET_TEMPLATES_LIST'> = (payload) => ({
    type: TMPLS_SET_TEMPLATES_LIST,
    payload
});

export const templatesUpdateTeamplate: NTemplates.ActionFunction<'TMPLS_UPDATE_TEPMLATE'> = (payload) => ({
    type: TMPLS_UPDATE_TEPMLATE,
    payload
});