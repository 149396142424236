import React, { FC, useState } from 'react';
import { NFontSize } from './FontSize.index';
import { FormatSize } from '@mui/icons-material';
import { Wrapper } from '../Wrapper/Wrapper';
import { TextField } from '@mui/material';


export const FontSize: FC<NFontSize.Props> = ({
    fontSize,
    ButtonProps,
    onChange
}) => {

    return (
        <Wrapper
            ButtonProps={ButtonProps}
            icon={<FormatSize />}
        >
            <TextField
                fullWidth
                value={fontSize || '1'}
                size="small"
                label={"FontSize"}
                onChange={(e) => {
                    const value = e.target.value;
                    let number = Number(value.replace(/\D/g, ''));
                    if(isNaN(number)) number = 20;
                    onChange({
                        fontSize: number
                    });
                }}
                inputProps={{
                    style: {
                        textAlign: 'right'
                    }
                }}
            />
        </Wrapper>
    )
}