import { cn } from '@bem-react/classname';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getItemsField, getItemsItemById } from '../../../redux/items/selectors';
import { itemsSelect, itemsRemove, itemsChange } from '../../../redux/items/actions';
import { IStateType } from '../../../redux/store';

export const cnSettingsLayer = cn('SettingsLayer');

export namespace NSettingsLayer {

    export interface Props extends OwnProps, ReturnType<typeof mapSettingsLayerStateToProps>, ReturnType<typeof mapSettingsLayerActionsToProps> {

    }

    export interface OwnProps {
        id: string;
    }
}

export const mapSettingsLayerStateToProps = (state: IStateType, props: NSettingsLayer.OwnProps) => ({
    data: getItemsItemById(props.id)(state),
    isSelected: getItemsField('select')(state) === props.id
});

export const mapSettingsLayerActionsToProps = (d: Dispatch<AnyAction>) => bindActionCreators({
    itemsSelect,
    itemsChange,
    itemsRemove
}, d);