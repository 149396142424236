import { cn } from '@bem-react/classname';
import { IClassNameProps } from '../../types.base';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import { itemsCreate, itemsCreateFlatIcon, itemsCreateExtension } from '../../redux/items/actions';
import { IStateType } from '../../redux/store';
import { getBaseField } from '../../redux/base/selectors';
import { baseChangeField } from '../../redux/base/actions';

export const cnItemsMenu = cn('ItemsMenu');

export namespace NItemsMenu {

    export type Props = IClassNameProps & ReturnType<typeof mapItemsMenuStateToProps> & ReturnType<typeof mapItemsMenuActionsToProps>;
}

export const mapItemsMenuStateToProps = (state: IStateType) => ({
    openedMenu: getBaseField('openedMenu')(state)
});

export const mapItemsMenuActionsToProps = (d: Dispatch<AnyAction>) => bindActionCreators({
    itemsCreate,
    itemsCreateFlatIcon,
    itemsCreateExtension,
    baseChangeField
}, d);