import { keyBy } from "lodash";

export const imageSelectorId = 'ImageSelector';

export const defaultPosition = {
    x: 40,
    y: 40,
};

export const defaultFill = {
    fill: '#fff'
};

export const defaultStroke = {
    stroke: 'black',
    strokeWidth: 1,
};

export const baseProps = {
    Star: {
        ...defaultPosition,
        ...defaultStroke,
        ...defaultFill,
        numPoints: 5,
        innerRadius: 20,
        outerRadius: 40
    },
    Rect: {
        ...defaultPosition,
        ...defaultStroke,
        ...defaultFill,
        width: 50,
        height: 50,
    },
    Text: {
        ...defaultPosition,
        text: 'text',
        fontSize: 20
    },
    Circle: {
        ...defaultPosition,
        ...defaultStroke,
        ...defaultFill,
        radius: 30
    },
    Ellipse: {
        ...defaultPosition,
        ...defaultStroke,
        ...defaultFill,
        radiusX: 20,
        radiusY: 30
    },
    Image: {
        ...defaultPosition
    },
    FlatIconsImage: {
        ...defaultPosition
    },
    Line: {
        x: 0,
        y: 0,
        ...defaultStroke,
        points: [ 20, 20, 60, 20 ]
    }
};

export const settingsRenderers = [
    'Borders',
    'BordersColor',
    'BackgroundColor',
    'TextColor',
    'Opacity',
    'Font',
    'FontSize',
    'Cropper',
    'Zindex',
    'Edit',
    'Remove',
    'Align',
    'Fixer'
] as const;

export const settingsRenderersObject = settingsRenderers.reduce((a, c) => ({ ...a, [c]: c }), {} as {[Key in typeof settingsRenderers[number]]: Key});

const bordersWidthSetting = {
    renderer: settingsRenderersObject.Borders,
    params: ['strokeWidth']
} as const;

const bordersColorSettings = {
    renderer: settingsRenderersObject.BordersColor,
    params: [ 'stroke' ]
} as const;

const backgroundColorSettings = {
    renderer: settingsRenderersObject.BackgroundColor,
    params: [ 'fill' ]
} as const;

const textColorSettings = {
    renderer: settingsRenderersObject.TextColor,
    params: [ 'fill' ]
} as const;

const opacitySetting = {
    renderer: settingsRenderersObject.Opacity,
    params: ['opacity']
} as const;

const fontSetting = {
    renderer: settingsRenderersObject.Font,
    params: ['fontFamily']
} as const;

const fontSizeSetting = {
    renderer: settingsRenderersObject.FontSize,
    params: ['fontSize']
} as const;

const cropperSettings = {
    renderer: settingsRenderersObject.Cropper,
    params: []
} as const;

const zindexSettings = {
    renderer: settingsRenderersObject.Zindex,
    params: []
} as const;

const editSettings = {
    renderer: settingsRenderersObject.Edit,
    params: []
} as const;

const removeSettings = {
    renderer: settingsRenderersObject.Remove,
    params: []
} as const;

const alignSettings = {
    renderer: settingsRenderersObject.Align,
    params: ['align']
} as const;

const fixedSettings = {
    renderer: settingsRenderersObject.Fixer,
    params: []
} as const;

export const fixedAllowedSettingsRenderers = [
    settingsRenderersObject.Fixer,
    settingsRenderersObject.Zindex
] as typeof settingsRenderers[number][];

export const settings = {
    Star: [ fixedSettings, zindexSettings, bordersWidthSetting, bordersColorSettings, backgroundColorSettings, opacitySetting, removeSettings ],
    Rect: [ fixedSettings, zindexSettings, bordersWidthSetting, bordersColorSettings, backgroundColorSettings, opacitySetting, removeSettings ],
    Text: [ fixedSettings, zindexSettings, opacitySetting, fontSetting, fontSizeSetting, textColorSettings, alignSettings, editSettings, removeSettings ],
    Circle: [ fixedSettings, zindexSettings, bordersWidthSetting, bordersColorSettings, backgroundColorSettings, opacitySetting, removeSettings ],
    Ellipse: [ fixedSettings, zindexSettings, bordersWidthSetting, bordersColorSettings, backgroundColorSettings, opacitySetting, removeSettings ],
    Image: [ fixedSettings, zindexSettings, opacitySetting, cropperSettings, removeSettings ],
    FlatIconsImage: [ fixedSettings, zindexSettings, opacitySetting, cropperSettings, removeSettings ],
    Line: [ fixedSettings, zindexSettings, opacitySetting, bordersWidthSetting, bordersColorSettings, removeSettings ],
} as const;