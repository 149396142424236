import { useAppSelector } from "../../redux/hooks";
import { getItemsField } from "../../redux/items/selectors";
import { getKonvaSettingsField } from "../../redux/konvaSettings/selectors";

export const useZoomerDisabled = () => {

    const editMainLayer = useAppSelector(getKonvaSettingsField('edit'));

    const selectedAnyItem = useAppSelector(getItemsField('select'));

    const disabled = editMainLayer || Boolean(selectedAnyItem);

    return disabled;
};