import { pick } from "lodash";
import { baseTemplatesReducer } from "./base.Templates";
import { NTemplates } from "./types.Templates";

type WithTemplatesReducer = { templates: NTemplates.Reducer };

export const getTemplatesState = <S extends WithTemplatesReducer = WithTemplatesReducer> (state: S) => state.templates || baseTemplatesReducer;

type GetTemplatesParameters = Parameters<typeof getTemplatesState>;

export const getTemplatesFields = <Keys extends (keyof ReturnType<typeof getTemplatesState>)[]> (...keys: Keys) => (...args: GetTemplatesParameters) => pick(getTemplatesState(...args), keys) as Pick<ReturnType<typeof getTemplatesState>, Keys[number]>;

export const getTemplateById = (id: keyof ReturnType<typeof getTemplatesState>['templates']) => (...args: GetTemplatesParameters) => {
    const { templates } = getTemplatesFields('templates')(...args);
    return templates[id];
};