import { WithDialog } from "../DialogCreate";
import { cn } from '@bem-react/classname';
import { NItems } from '../../redux/items/interface';

export const cnSelectUrl = cn('SelectUrlDialog');

export namespace NSelectUrl {

    interface PropsOwn {
        urls: string[];
    }

    export interface Rezult {
        url: PropsOwn['urls'][number];
    }

    export type Props = PropsOwn & WithDialog<Rezult>;
}