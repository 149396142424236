import { Box, Button } from "@mui/material";
import { createContext, useContext, useEffect, useState } from "react";
import { getCookieByName } from "../cookie";

const eventName = 'debug';

const baseDebugContext = {
    enableDownloadTemplate: false
};

export const DebugContext = createContext(baseDebugContext);

export const useDebug = () => {

    const [ debugState, setDebugState ] = useState(baseDebugContext);

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if(event.data.event === eventName) {
                const data = event.data.data;
                setDebugState({
                    ...debugState,
                    ...data
                });
            }
        });
    }, [debugState]);

    return debugState;
};

export const DebugMenu = () => {

    const { enableDownloadTemplate } = useContext(DebugContext);

    const [ show ] = useState(getCookieByName('debug') || false);

    if(!show) return null;

    return (
        <Box sx={{
            position: 'fixed',
            bottom: 0,
            right: 0,
            padding: '8px',
            display: 'flex',
            flexDirection: 'column',
            gap: '8px',
            background: '#fff',
        }}>
            <Button
                variant={enableDownloadTemplate ? 'contained' : 'outlined'}
                onClick={() => {
                    window.postMessage({
                        event: 'debug',
                        data: {
                            enableDownloadTemplate: !enableDownloadTemplate
                        }
                    });
                }}
            >
                enable download
            </Button>
        </Box>
    )
};