import React, { FC } from "react";
import { cnMenu, NMenu, mapMenuStateToProps, mapMenuActionsToProps } from './Menu.index';
import './Menu.scss';
import { Button } from '@mui/material';
import { KonvaComponents } from '../Konva';
import { connect } from 'react-redux';
import { LayersSettings } from '../Layers/Layers';
import Circle from './imgs/circle.png';
import Ellipse from './imgs/ellipse.png';
import Rect from './imgs/rect.png';
import Star from './imgs/star.png';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { Templates } from "../Templates/Templates";

export const MenuPr: FC<NMenu.Props> = ({
    openedMenu,
    className,
    itemsCreate,
    openedValue,
    ...props
}) => {

    return (
        <div className={cnMenu({ opened: openedMenu === openedValue }, [className])} {...props} />
    );
};

export const Menu = connect(
    mapMenuStateToProps,
    mapMenuActionsToProps
)(MenuPr);

export const LayersMenu: FC<NMenu.OwnProps> = ({
    ...props
}) => {
    return (
        <Menu {...props}>
            <LayersSettings />
        </Menu>
    );
};

export const TemplatesMenu: FC<NMenu.OwnProps> = ({
    ...props
}) => {
    return (
        <Menu {...props}>
            <Templates />
        </Menu>
    );
};