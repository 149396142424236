import {
    SETTINGS_CHANGE_FIELD,
    SETTINGS_CHANGE_LAYOUT,
    SETTINGS_CHANGE_REDUCER
} from './actions.types';
import { NKonvaSettings } from './interface';

export const settingsChangeField = (payload: NKonvaSettings.NActions.ChangeFieldPayload): NKonvaSettings.Actions => ({
    type: SETTINGS_CHANGE_FIELD,
    payload
});

export const settingsChangeLayout = (payload: NKonvaSettings.NActions.ChangeLayoutPayload): NKonvaSettings.Actions => ({
    type: SETTINGS_CHANGE_LAYOUT,
    payload
});

export const settingsChangeReducer = (payload: NKonvaSettings.NActions.ChangeReducer['payload']): NKonvaSettings.Actions => ({
    type: SETTINGS_CHANGE_REDUCER,
    payload
});