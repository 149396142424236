import { pick } from 'lodash';
import { IStateType } from '../store';

export const getItems = (state: IStateType) => state.items;

/**
 * get a field value by name
 * @param field 
 * @returns 
 */
export const getItemsField = <F extends keyof ReturnType<typeof getItems>> (field: F) => (state: IStateType) => getItems(state)[field];

/**
 * get fields values by names
 * @param fields 
 * @returns 
 */
export const getItemsFields = <FS extends keyof ReturnType<typeof getItems>> (fields: FS[]) => (state: IStateType) => {
    // current reducer state value
    const stateValue = getItems(state);
    return pick(stateValue, ...fields) as Pick<typeof stateValue, typeof fields[number]>;
};

export const getItemsItemById = (id: string) => (state: IStateType) => getItemsField('items')(state)[id];