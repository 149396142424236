import { useEffect, useState, useRef, useLayoutEffect } from 'react';

/**
 * fn is call only when inputs change, not call on did mount
 * @param fn 
 * @param inputs 
 */
export function useDidUpdateEffect(fn: () => void, inputs: any[]) {
    const didMountRef = useRef(false);
    useEffect(() => {
        if (didMountRef.current) { 
            return fn();
        }
        didMountRef.current = true;
    }, inputs);
}


// position: absolute;
// top: -1000000%;
// left: -1000000%;
// z-index: -5;
// visibility: hidden;
// pointer-events: none;


export const useImage = (
    url: string,
    {
        onLoad
    }: {
        onLoad?: (target: HTMLImageElement) => void;
    },
    crossOrigin?: string
) => {
    
    const statusRef = useRef<'loading' | 'loaded' | 'failed'>('loading');

    const imageRef = useRef<HTMLImageElement>();
    const [sizes, setSizes] = useState({ height: 0, width: 0, aspect: 1});

    // we are not going to use token
    // but we need to just to trigger state update
    const [_, setStateToken] = useState(0);

    useLayoutEffect(
      function () {
        if (!url) return;
        var img = document.createElement('img');

        img.style.position = 'absolute';
        img.style.top = '-1000000%';
        img.style.left = '-1000000%';
        img.style.zIndex = '-5';
        img.style.visibility = 'hidden';
        img.style.pointerEvents = 'none';

        document.body.appendChild(img);
  
        function onLoadEvent() {
          statusRef.current = 'loaded';
          imageRef.current = img;
          setSizes({
            height: img.clientHeight,
            width: img.clientWidth,
            aspect: img.clientHeight / img.clientWidth
          });
          if(onLoad) onLoad(img);
          setStateToken(Math.random());
        }
  
        function onErrorEvent() {
          statusRef.current = 'failed';
          imageRef.current = undefined;
          setStateToken(Math.random());
        }
  
        img.addEventListener('load', onLoadEvent);
        img.addEventListener('error', onErrorEvent);
        crossOrigin && (img.crossOrigin = crossOrigin);
        img.src = url;
  
        return function cleanup() {
          img.removeEventListener('load', onLoadEvent);
          img.removeEventListener('error', onErrorEvent);
          img.remove();
        };
      },
      [url, crossOrigin]
    );

    return [imageRef.current, statusRef.current, sizes] as const;
}