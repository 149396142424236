import React, { ComponentProps, FC, useState } from 'react';
import { cnSelectItem, NSelectItem } from './SelectItemDialog.index';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { createDialog } from '../DialogCreate';
import './SelectItemDialog.scss';

const IconItem: FC<{
    itemData: NSelectItem.Props['items'][number];
    buttonProps?: ComponentProps<typeof Button>;
}> = ({
    itemData,
    buttonProps
}) => {

    if(!itemData.previewUrl) return null;

    return (
        <Button
            {...buttonProps}
            variant={buttonProps?.variant || 'outlined'}
            className={cnSelectItem('Icon', {}, [buttonProps?.className])}
        >
            <img src={itemData.previewUrl} />
        </Button>
    )
};

const SelectItemPr: FC<NSelectItem.Props> = ({
    items,
    handleAccept,
    handleClose
}) => {

    const [ selectedItem, setSelectedItem ] = useState<string>('');

    return (
        <>
            <DialogTitle>
                Select icon
            </DialogTitle>
            <DialogContent className={cnSelectItem('Content')}>
                <div className={cnSelectItem('Icons')}>
                    {
                        items.map((item, idx) => {
                            return (
                                <IconItem
                                    key={item.id}
                                    itemData={item}
                                    buttonProps={{
                                        onClick: () => setSelectedItem(item.id),
                                        onDoubleClick: () => handleAccept({ item }),
                                        color: selectedItem === item.id ? 'secondary' : 'primary'
                                    }}
                                />
                            )
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                >
                    Close
                </Button>
                <Button
                    onClick={() => {
                        const item = items.find(item => item.id.toString() === selectedItem);
                        if(item) handleAccept({ item });
                    }}
                >
                    Accept
                </Button>
            </DialogActions>
        </>
    )
};

export const SelectItem = createDialog(SelectItemPr);