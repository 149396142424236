import React, { FC, ComponentProps } from "react";
import { NStar } from './Star.index';
import { Star as KonvaStar } from 'react-konva';
import useImage from 'use-image';

const StarPr: FC<NStar.Props> = ({
    forwardRef,
    ...props
}) => {

    // console.log('Star props', props);
    const [ image ] = useImage('https://konvajs.org/assets/yoda.jpg', 'anonymous');

    if(image?.style) {
        // image.style.width = props.outerRadius * 2 + 'px';
        // image.style.height = props.outerRadius * 2 + 'px';
        image?.addEventListener('load', () => {
            console.log('imageLoad!')
        })
    }


    // console.log('image', image);
    // console.log('image', image?.clientHeight);
    // console.log('image', image?.clientWidth);
    // console.log('image.style.width', image?.style.width);
    // console.log('image.style.height', image?.style.height);
    
    
    return (
        <KonvaStar
            shadowColor="black"
            shadowOpacity={0.6}
            {...props}
            numPoints={5}
            ref={forwardRef}
            // fillPatternImage={
            //     image
            // }
            // // fillPatternX={-props.outerRadius}
            // // fillPatternY={-props.outerRadius}
            // fillPatternOffset={{
            //     x: props.outerRadius,
            //     y: props.outerRadius
            // }}
            // onDragMove={(evt) => {
            //     // console.log('---');
            //     // console.log('x', evt.target.x());
            //     // console.log('y', evt.target.y());
            // }}
            // fillPatternRepeat={'no-repeat'}
        />
    )
};

export const Star = React.forwardRef<ComponentProps<typeof KonvaStar>['ref'], NStar.Props>((props, ref) => {
    return <StarPr {...props} forwardRef={ref} />
});