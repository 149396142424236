import { createStore } from "redux-dynamic-modules";
import { getSagaExtension } from "redux-dynamic-modules-saga";
import { getBaseModule } from "./base/module";
import { getItemsModule } from "./items/module";
import { getKonvaSettingsModule } from './konvaSettings/module';
import { getUsersDataModule } from "./usersData/module";

export const store = createStore(
    {
      initialState: {
        // ...JSON.parse(localStorage.getItem('KonvaStore') || '{}')
        /** initial state */
      },
      enhancers: [
        // window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (data: any) => data
        // sagaMiddleware
        // routerMiddleware(history), // for dispatching history actions
        // applyMiddleware(
        //   // _AIChallngeMiddleware,
        //   // eventMiddleware,
        //   // titleMiddleware,
        //   // , 
        //   sagaMiddleware,
        //   thunk
        //   // ... other middlewares ...
        // ),
        // compose(
        //   window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (data: any) => data
        // )
          /** enhancers to include */ 
      ], 
      extensions: [
        getSagaExtension(),
        (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (data: any) => data
        /** extensions to include i.e. getSagaExtension(), getThunkExtension() */
      ],
    },
    getKonvaSettingsModule(),
    getItemsModule(),
    getBaseModule(),
    getUsersDataModule()
    // getBaseModule(),
    // getI18nModule(),
);

store.subscribe(() => {
  localStorage.setItem('KonvaStore', JSON.stringify(store.getState()));
});
  
export type IStateType = ReturnType<typeof store.getState>;