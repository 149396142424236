import React, { ComponentProps, ComponentType, FC, ReactNode, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import MUIDialog, { DialogProps } from '@mui/material/Dialog';
import { useStore, Provider } from 'react-redux';
import { Backdrop } from '@mui/material';
import { store } from '../../redux/store';

type DialogActions<R> = {
    handleAccept: (value: R) => void;
    handleClose: () => void;
};

export type WithDialog<R> = DialogActions<R>;

type OmitedDialogProps = Omit<DialogProps, 'onClose' | 'open'>;

const rootDialogId = 'RootModal';
const storeProviderKey = `${rootDialogId}_store`;

const prepareRoot = () => {
    let rootEl = document.getElementById(rootDialogId);
    if (!rootEl) {
        rootEl = document.createElement('div');
        rootEl.setAttribute('id', rootDialogId);
        document.body.appendChild(rootEl);
    }
    return rootEl;
};

export const defaultDialogProps: OmitedDialogProps = {
    fullWidth: true,
    maxWidth: 'md',
    scroll: 'body'
}

const initRender = (node: ReactNode) => {
    const rootEl = prepareRoot();
    ReactDOM.render(<Provider key={storeProviderKey} store={store} >
        {node}
    </Provider>, rootEl);
}

export const createDialog = <Props, R>(Component: ComponentType<Props & WithDialog<R>>, dialogProps: OmitedDialogProps = defaultDialogProps) => {

    const Dialog: FC<Props & WithDialog<R>> = ({
        handleAccept: resolve,
        handleClose: reject,
        ...props
    }) => {

        const [ open, setOpen ] = useState(false);

        useEffect(() => {
            setOpen(true);
        }, [resolve]);

        const handleClose = () => {
            setOpen(false);
        };

        const handleAccept = (value: R) => {
            handleClose();
            resolve(value);
        };

        return (
            <MUIDialog
              {...dialogProps}
              open={open}
              onClose={handleClose}
              BackdropComponent={Backdrop}
            >
                <Component
                    {...props as any}
                    handleClose={handleClose}
                    handleAccept={handleAccept}
                />
            </MUIDialog>
        )
    }

    return (props: Omit<Props, keyof WithDialog<R>>) => {
        return new Promise<R>((rezolve, reject) => {
            initRender(<Dialog {...props as any} handleAccept={rezolve} handleClose={reject}/>);
        });
    }
}