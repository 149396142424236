import { items } from './reducer';
import { ModuleTypePrepare, ModuleReducerMapPrepare } from '../types';
import { ISagaModule } from 'redux-dynamic-modules-saga';
import { itemsSaga } from './saga';

const reducers = {
    items
}

type ItemsModuleType = ModuleTypePrepare<typeof reducers>;

type ItemsReducerMapType = ModuleReducerMapPrepare<typeof reducers>;

export function getItemsModule(): ISagaModule<ItemsModuleType> {
    return {
        id: "items",
        middlewares: [],
        reducerMap: reducers as ItemsReducerMapType,
        sagas: [
            itemsSaga
        ]
    };
  };