import React, { FC, ReactNode } from 'react';
import { NAlign } from './Align.index';
import { Button, ButtonGroup } from '@mui/material';
import { Wrapper } from '../Wrapper/Wrapper';
import FormatAlignLeftIcon from '@mui/icons-material/FormatAlignLeft';
import FormatAlignCenterIcon from '@mui/icons-material/FormatAlignCenter';
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import { i18next } from '../../../../localization';

const icons: { [key in Required<NAlign.Props>['align']]: ReactNode } = {
    left: <FormatAlignLeftIcon />,
    center: <FormatAlignCenterIcon />,
    right: <FormatAlignRightIcon />
}

export const Align: FC<NAlign.Props> = ({
    align = 'left',
    ButtonProps,
    onChange
}) => {

    const onChangeAlign = (value: typeof align) => () => {
        onChange({ align: value });
    };

    return (
        <Wrapper
            icon={icons[align]}
            ButtonProps={ButtonProps}
            PopoverProps={{
                PaperProps: {
                    sx: {
                        width: 'unset'
                    }
                }
            }}
        >
            <ButtonGroup variant={'outlined'}>
                {
                    Object.typedKeys(icons).map(key => 
                        <Button onClick={onChangeAlign(key)}>
                            {icons[key]}
                        </Button>
                    )
                }
            </ButtonGroup>
        </Wrapper>
    )
}