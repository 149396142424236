import React, { ChangeEventHandler, FC, HTMLProps } from 'react';

export const FileSelector: FC<HTMLProps<HTMLInputElement>> = ({
    accept,
    onChange,
    ...props
}) => {

    const onChangeHandle: ChangeEventHandler<HTMLInputElement> = (e) => {
        onChange && onChange(e);
        e.target.value = '';
    }

    return (
        <input
            {...props}
            style={{
                display: 'none',
                ...props.style
            }}
            type='file'
            accept={accept}
            onChange={onChangeHandle}
        />
    )
}