import React, { FC, ComponentProps } from "react";
import { Line as KonvaLine } from 'react-konva';
import { NLine } from './Line.index';

const LinePresenter: FC<NLine.Props> = ({
    forwardRef,
    ...props
}) => {

    return (
        <KonvaLine
            {...props}
            ref={forwardRef}
        />
    )
}

export const Line = React.forwardRef<ComponentProps<typeof KonvaLine>['ref'], NLine.Props>((props, ref) => {
    return <LinePresenter {...props} forwardRef={ref} />
});