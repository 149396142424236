import { cn } from '@bem-react/classname';
import React, { FC, useContext } from 'react';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getTemplatesModule } from './redux/module.Templates';
import { NTemplates } from './redux/types.Templates';
import { useTemplate, useTemplates } from './Templates.hooks';
import {
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    IconButton
} from '@mui/material';
import {
    Delete,
    SaveOutlined,
    SaveAlt
} from '@mui/icons-material';
import { TemplatesSaveButton, TemplatesSearch, TemplatesWrapper } from './Templates.styled';
import { SaveDialog } from './SaveDialog/SaveDialog';
import { DebugContext } from '../../debug/debug';
import { ImageIcon } from '../ImageIcon';

const cnTepmlates = cn('Templates');
const cnTepmlate = cn('Template');

const TemplateItem: FC<{
    id: keyof NTemplates.Reducer['templates']
}> = ({
    id
}) => {

    const debug = useContext(DebugContext);

    const {
        template,
        selectTemplate,
        updateTemplate,
        removeTemplate
    } = useTemplate(id);

    const {
        name
    } = template;

    return (
        <ListItemButton className={cnTepmlate()} onClick={() => selectTemplate()}>
            {
                template.previewUrl &&
                <ListItemIcon>
                    <ImageIcon url={template.previewUrl} />
                </ListItemIcon>
            }
            <ListItemText>
                {name}
            </ListItemText>
            <ListItemIcon>
                {
                    debug.enableDownloadTemplate &&
                    <IconButton onClick={(e) => {
                        var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(template));
                        var dlAnchorElem = document.getElementById('downloadAnchorElem');
                        if(!dlAnchorElem) return ;
                        dlAnchorElem.setAttribute("href", dataStr );
                        dlAnchorElem.setAttribute("download", `${template.name}.json`);
                        dlAnchorElem.click();
                    }}>
                        <SaveAlt />
                    </IconButton>
                }
                <IconButton onClick={(e) => {
                    e.stopPropagation();
                    updateTemplate();
                }}>
                    <SaveOutlined />
                </IconButton>
                <IconButton onClick={(e) => {
                    e.stopPropagation();
                    removeTemplate();
                }}>
                    <Delete />
                </IconButton>
            </ListItemIcon>
        </ListItemButton>
    );
};

const TemplatesPr: FC<{}> = ({
}) => {

    const {
        list,
        search,
        setSearch,
        addTemplate
    } = useTemplates();

    return (
        <TemplatesWrapper>
            <TemplatesSearch
                label={'Search'}
                size={'small'}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
            />
            <List className={cnTepmlates()}>
                {
                    list.map(el => {
                        return (
                            <TemplateItem key={el} id={el} />
                        )
                    })
                }
            </List>
            <TemplatesSaveButton
                variant={'contained'}
                onClick={() => {
                    SaveDialog({}).then(d => {
                        addTemplate(d.name);
                    });
                }}
            >
                Save
            </TemplatesSaveButton>
        </TemplatesWrapper>
    )
};

const TemplatesModule: FC<{}> = ({
    ...props
}) => {

    return (
        <DynamicModuleLoader modules={[getTemplatesModule()]} >
            <TemplatesPr {...props} />
        </DynamicModuleLoader>
    )
};

export const Templates = TemplatesModule;