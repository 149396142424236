import { cn } from '@bem-react/classname';
import { IClassNameProps } from '../../types.base';

export const cnKonva = cn('KonvaWrapper');

export namespace NKonvaWrapper {

    export interface Props extends IClassNameProps {

    }

    export type SearchParams = {
        circle: boolean;
        height: number;
        width: number;
        fixed: boolean;
    } 
};