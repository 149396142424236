import React, { FC, ComponentProps } from "react";
import { Circle as KonvaCircle } from 'react-konva';
import { NCircle } from './Circle.index';

const CirclePresenter: FC<NCircle.Props> = ({
    forwardRef,
    ...props
}) => {

    return (
        <KonvaCircle
            {...props}s
            ref={forwardRef}
        />
    )
}

export const Circle = React.forwardRef<ComponentProps<typeof KonvaCircle>['ref'], NCircle.Props>((props, ref) => {
    return <CirclePresenter {...props} forwardRef={ref} />
});