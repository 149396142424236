import { usersData } from './reducer';
import { ModuleTypePrepare, ModuleReducerMapPrepare } from '../types';
import { ISagaModule } from 'redux-dynamic-modules-saga';

const reducers = {
    usersData
}

type UsersDataModuleType = ModuleTypePrepare<typeof reducers>;

type UsersDataReducerMapType = ModuleReducerMapPrepare<typeof reducers>;

export function getUsersDataModule(): ISagaModule<UsersDataModuleType> {
    return {
        id: "usersData",
        //   middlewares: [
        //     // routerMiddleware(history),
        //   ],
        reducerMap: reducers as UsersDataReducerMapType
        //   reducerMap as { [s in keyof IUsersDataReduxModule]: Reducer<ReturnType<IUsersDataReduxModule[s]>, AnyAction> },
        //   sagas
    };
  };