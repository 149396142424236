import { useCallback } from "react";
import { dataURItoBlob, getConvaImage } from "../utils";
import { useEnvironment } from "../environment";

const postImage = (action: string) => (propsX: number, propsY: number, propsHeight: number, propsWidth: number) => {
    if(!window.konvaMainLayer) {
        console.error('konvaMainLayer isn\'t defined in window');
        return;
    }
    getConvaImage(
        window.konvaMainLayer,
        propsX,
        propsY,
        propsHeight,
        propsWidth,
        (img) => {
            const blob = dataURItoBlob(img);
            console.log('embed action', action);
            window.top?.postMessage({
                event: action,
                data: blob
            }, '*');
        }
    )
}

export const useEmbedActions = () => {

    const { inFrame } = useEnvironment();

    const saveImage = useCallback(postImage('saveConvaImage'), []);

    const giveImage = useCallback(postImage('giveCurrentImage'), []);

    if(!inFrame) return {};

    return {
        saveImage,
        giveImage
    }
}