import React, { FC, ComponentProps, useEffect, useState, useMemo } from "react";
import { NFlatIconsImage } from './FlatIconsImage.index';
import { Image as KonvaImage } from 'react-konva';
import { useImage } from "../../../hooks";
import { imageSelectorId } from "../../../constants";
// import useImage from 'use-image';

const FlatIconsImagePr: FC<NFlatIconsImage.Props> = ({
    forwardRef,
    url,
    width,
    height,
    ...props
}) => {
    
    const [ image, status, sizes ] = useImage(url, {}, 'anonymous');
    
    return (
        <KonvaImage
            {...props}
            width={width || sizes.width}
            height={height || sizes.height}
            ref={forwardRef}
            image={image}
        />
    )
};

export const FlatIconsImage = React.forwardRef<ComponentProps<typeof KonvaImage>['ref'], NFlatIconsImage.Props>((props, ref) => {
    return <FlatIconsImagePr {...props} forwardRef={ref} />
});