import { Box, styled } from '@mui/material';

export const Menu = styled('div')({
    height: 104,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: 8,
    boxSizing: 'border-box'
});