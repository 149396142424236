import React, { ComponentProps, FC, useEffect, useState } from 'react';
import { FlatIcon } from '../../requests/extends/flaticons/flaticons.settings';
import { MenuButton } from '../../blocks/ItemsMenu/ItemsMenu.styled';
import FlatIconIcon from './icon/flaticon_negative.svg';
import { SelectIcon } from './SelectIcon/SelectIcon';
import { NSelectIcon } from './SelectIcon/SelectIcon.index';

export const FlatIcons: FC<ComponentProps<typeof MenuButton> & {
    onIconClick: (icon: NSelectIcon.Rezult['icon']) => void;
    onInit?: () => void;
}> = ({
    onIconClick,
    onInit,
    ...props
}) => {

    const [ inited, setInited ] = useState(false);
    
    useEffect(() => {
        if(window.flatIconKey) {
            FlatIcon.apikey = window.flatIconKey;
            setInited(true);
            onInit && onInit();
        }
        window.addEventListener("message", (event) => {
            if(event.data.event === 'initFlatIcon') {
                const data = event.data.data;
                FlatIcon.apikey = data;
                setInited(true);
                onInit && onInit();
            }
        });
    }, []);

    if(!inited) return null;

    return (
        <MenuButton
            {...props}
            onClick={() => {
                SelectIcon({}).then(d => {
                    onIconClick(d.icon);
                });
            }}
        >
            <img src={FlatIconIcon} style={{ width: '26px' }} />
        </MenuButton>
    );
};