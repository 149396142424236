import { WithDialog } from "../../../blocks/DialogCreate";
import { cn } from '@bem-react/classname';
import { FlatIconsSearchItemsResponse } from "../../../requests/extends/flaticons/flaticons.models";

export const cnSelectIcon = cn('SelectIcon');

export namespace NSelectIcon {

    interface PropsOwn {
    }

    export interface Rezult extends PropsOwn {
        icon: FlatIconsSearchItemsResponse['data'][number]
    }

    export type Props = PropsOwn & WithDialog<Rezult>;
}