import { Button, TextField } from "@mui/material";
import { styled } from "@mui/system";

export const TemplatesWrapper = styled('div')({
    display: 'flex',
    flexDirection: 'column',
    gap: 8,
    maxHeight: '100vh'
});

export const TemplatesSearch = styled(TextField)({
    margin: 16,
    marginBottom: 0
});

export const TemplatesSaveButton = styled(Button)({
    marginLeft: 16,
    marginRight: 16
});