import React, { ComponentProps, FC, useState } from 'react';
import { cnSelectIcon, NSelectIcon } from './SelectIcon.index';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import { createDialog } from '../../../blocks/DialogCreate';
import { TextField } from '@mui/material';
import { useDebounce } from '../../../utils';
import './SelectIcon.scss';
import { FlatIconsSearchItemsResponse } from '../../../requests/extends/flaticons/flaticons.models';
import { useDidUpdateEffect } from '../../../hooks';
import { flatIconsSearchItem } from '../../../requests/extends/flaticons/flaticons.api';

const IconItem: FC<{
    iconData: FlatIconsSearchItemsResponse['data'][number]
    buttonProps?: ComponentProps<typeof Button>
}> = ({
    iconData,
    buttonProps
}) => {
    return (
        <Button
            {...buttonProps}
            variant={buttonProps?.variant || 'outlined'}
            className={cnSelectIcon('Icon', {}, [buttonProps?.className])}
        >
            <img src={iconData.images[64]} />
        </Button>
    )
};

const SelectIconPr: FC<NSelectIcon.Props> = ({
    handleAccept,
    handleClose
}) => {

    const [ search, setSearch ] = useState('');

    const onSearchChange = useDebounce((string: string) => {
        setSearch(string);
    }, 300, []);

    const [ selectedIcon, setSelectedIcon ] = useState<string>('');

    const [ icons, setIcons ] = useState<FlatIconsSearchItemsResponse['data']>([]);

    useDidUpdateEffect(() => {
        flatIconsSearchItem({
            q: search
        }).then(d => {
            try {
                setIcons(d.data || []);
            } catch (error) {
                
            }
        });
    }, [search]);

    return (
        <>
            <DialogTitle>
                Select icon
            </DialogTitle>
            <DialogContent className={cnSelectIcon('Content')}>
                <TextField
                    fullWidth
                    defaultValue={search}
                    label={'Search'}
                    onChange={(e) => {
                        const value = e.target.value;
                        onSearchChange(value || '');
                    }}
                />
                <div className={cnSelectIcon('Icons')}>
                    {
                        icons.map((icon, idx) => {
                            return (
                                <IconItem
                                    key={icon.id}
                                    iconData={icon}
                                    buttonProps={{
                                        onClick: () => setSelectedIcon(icon.id.toString()),
                                        onDoubleClick: () => handleAccept({ icon }),
                                        color: selectedIcon === icon.id.toString() ? 'secondary' : 'primary'
                                    }}
                                />
                            )
                        })
                    }
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleClose}
                >
                    Close
                </Button>
                <Button
                    onClick={() => {
                        const icon = icons.find(icon => icon.id.toString() === selectedIcon);
                        if(icon) handleAccept({ icon });
                    }}
                >
                    Accept
                </Button>
            </DialogActions>
        </>
    )
};

export const SelectIcon = createDialog(SelectIconPr);