import { cn } from '@bem-react/classname';
import { AnyAction, bindActionCreators, Dispatch } from 'redux';
import { getItemsField } from '../../redux/items/selectors';
import { itemsChangeField } from '../../redux/items/actions';
import { IStateType } from '../../redux/store';

export const cnLayersSettings = cn('LayersSettings');

export namespace NLayersSettings {

    export interface Props extends OwnProps, ReturnType<typeof mapLayersSettingsStateToProps>, ReturnType<typeof mapLayersSettingsActionsToProps> {

    }

    export interface OwnProps {

    }
}

export const mapLayersSettingsStateToProps = (state: IStateType, props: NLayersSettings.OwnProps) => ({
    layers: getItemsField('layers')(state),

});

export const mapLayersSettingsActionsToProps = (d: Dispatch<AnyAction>) => bindActionCreators({
    itemsChangeField
}, d);