import React, { FC, useState } from 'react';
import { NWrapper } from './Wrapper.index';
import { Box, TextField, Popover } from '@mui/material';
import { MenuButton, SettingsPaper } from '../../styled.components';


export const Wrapper: FC<NWrapper.Props> = ({
    icon,
    ButtonProps,
    PopoverProps,
    children
}) => {

    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };
  
    const handleClose = () => {
      setAnchorEl(null);
    };
  
    const open = Boolean(anchorEl);

    return (
        <>
            <MenuButton
                {...ButtonProps}
                onClick={handleClick}
            >
                {icon}
            </MenuButton>
            <Popover 
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                {...PopoverProps}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                PaperProps={{
                    component: SettingsPaper,
                    ...PopoverProps?.PaperProps
                } as any}
            >
                {children}
            </Popover>
        </>
    )
}