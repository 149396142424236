import React, { FC, useContext, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { ButtonGroup } from '@mui/material';
import { Menu, MenuButton } from './styled.components';
import { Layers, Check } from '@mui/icons-material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { cnSettingsMenu, mapSettingsMenuStateToProps, mapSettingsMenuActionToProps, NSettingsMenu } from './SettingsMenu.index';
import './SettingsMenu.scss';
import { SettingsItem } from './Item/Item';
import SaveIcon from '@mui/icons-material/Save';
import CheckIcon from '@mui/icons-material/Check';
import RectangleIcon from '@mui/icons-material/Rectangle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import { EnvironmentContext } from '../../environment';
import { useZoomerContextRef } from '../Zoomer/Zoomer.context';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import { i18next } from '../../localization';

const SettingsMenuPresenter: FC<NSettingsMenu.Props> = ({
    settings,
    settingsChangeField,
    itemsSelect
}) => {

    const { inFrame } = useContext(EnvironmentContext);

    const IconSaveButton = useMemo(() => {
        return inFrame ? CheckIcon : SaveIcon;
    }, [inFrame]);

    const zoomerRef = useZoomerContextRef();

    return (
        <ClickAwayListener onClickAway={() => {
            (window as any).settingsIsActive = false;
        }}>
            <Menu
                className={cnSettingsMenu()}
                onClick={() => {
                    (window as any).settingsIsActive = true;
                }}
            >   
                <ButtonGroup
                    orientation='vertical'
                >   
                    {
                        !settings.fixed &&
                        <MenuButton
                            variant='contained'
                            fullWidth
                            title={
                                settings.edit
                                ? i18next.t('editor.settings.layout.save')
                                : i18next.t('editor.settings.layout')
                            }
                            onClick={() => {
                                settingsChangeField({ field: 'edit', value: !settings.edit });
                                itemsSelect(null);
                            }}
                        >   
                            {
                                settings.edit ?
                                <Check />
                                :
                                <Layers />
                            }
                        </MenuButton>
                    }
                    {
                        settings.edit &&
                        <MenuButton
                            fullWidth
                            title={
                                settings.circle
                                ? i18next.t('editor.settings.layout.rect')
                                : i18next.t('editor.settings.layout.circle')
                            }
                            onClick={() => {
                                itemsSelect(null);
                                settingsChangeField({ field: 'circle', value: !settings.circle })
                            }}
                        >   
                            {
                                settings.circle ?
                                <RectangleIcon />
                                :
                                <RadioButtonUncheckedIcon />
                            }
                        </MenuButton>
                    }
                    {
                        !settings.edit &&
                        <MenuButton
                            fullWidth
                            title={i18next.t('editor.settings.save')}
                            onClick={() => {
                                itemsSelect(null);
                                settingsChangeField({ field: 'save', value: new Date().toISOString() })
                            }}
                        >
                            <IconSaveButton />
                        </MenuButton>
                    }
                    {
                        zoomerRef &&
                        <>
                            <MenuButton
                                fullWidth
                                title={i18next.t('editor.settings.panCenter')}
                                onClick={() => {
                                    zoomerRef.centerView();
                                }}
                            >
                                <CenterFocusStrongIcon />
                            </MenuButton>
                            <MenuButton
                                fullWidth
                                title={i18next.t('editor.settings.zoomin')}
                                onClick={() => {
                                    zoomerRef.zoomIn();
                                }}
                            >
                                <ZoomInIcon />
                            </MenuButton>
                            <MenuButton
                                fullWidth
                                title={i18next.t('editor.settings.zoomout')}
                                onClick={() => {
                                    zoomerRef.zoomOut();
                                }}
                            >
                                <ZoomOutIcon />
                            </MenuButton>
                        </>
                    }
                    <SettingsItem />
                </ButtonGroup>
            </Menu>
        </ClickAwayListener>
    )
}

export const SettingsMenu = connect(
    mapSettingsMenuStateToProps,
    mapSettingsMenuActionToProps
)(SettingsMenuPresenter);