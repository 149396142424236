import { Box, Button, Paper, styled } from '@mui/material';

export const Menu = styled(Box)({
    flex: 1,
    position: 'absolute',
    top: 16,
    right: 16,
    zIndex: 2
});

export const MenuButton = styled(Button)((props) => ({
    background: props.variant === 'contained' ? undefined : '#fff'
}));

export const SettingsPaper = styled(Paper)({
    width: 300,
    padding: 16,
    boxSizing: 'border-box'
})