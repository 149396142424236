import {
    select as sagaSelect,
    call as sagaCall
} from 'redux-saga/effects';

export function* select<S, T>(fn: (state: S) => T) {
    const res: T = yield sagaSelect(fn);
    return res;
};

type ThenArg<T> = T extends PromiseLike<infer U> ? U : T;

export function* call<Fn extends (...args: any[]) => any>(fn: Fn, ...args: Parameters<Fn>) {
    const res: ThenArg<ReturnType<typeof fn>> = yield sagaCall(fn, ...args);
    return res;
};

export const logError = (sagaName: string, action: string, error: any) => {
    console.error(`Saga error ${sagaName} action ${action}: ${error}`);
};