import { cn } from '@bem-react/classname';
import { AutocompleteProps } from '@mui/material';
import { fonts } from '../../base/fonts';

export const cnFontsSelector = cn('FontsSelector');

export const prepairedFonts = fonts.items.map(el => ({
    ...el,
    files: el.variants.reduce((a, variant) => ({
        ...a,
        [variant]:
        `${process.env.PUBLIC_URL}/fonts/${el.family.replace(/\s/g, '_').trim()}/${variant}.ttf`
    }), {} as {[s: string]: string})
}));

export namespace NFontsSelector {

    type Item = typeof prepairedFonts[number];

    export type TypedAutocompleteProps = AutocompleteProps<Item, false, false, false>;

    type PropsToOmit = 'multiple' | 'options' | 'renderOption' | 'renderTags' | 'renderInput' | 'defaultValue' | 'value' | 'onChange';
    
    type OmitedAutocomplieteProps = Omit<TypedAutocompleteProps, PropsToOmit>;

    export interface Props extends OmitedAutocomplieteProps {
        value?: string;
        onChange: (value: Props['value']) => void;
    }
}

prepairedFonts.forEach((font) => {
    const family = font.family;
    const item = document.getElementById(family.replace(/\s/g, '_'));
    if(!item) {
        const style = document.createElement('style');
        style.setAttribute('id', family.replace(/\s/g, '_'));
        style.setAttribute('type', 'text/css');
        style.setAttribute('media', 'screen, print');
        let familyString = '';
        font.variants.forEach((variant, idx) => {
            let urlsString = '';
            let weight = 400;
            let style = 'normal';
            if(!isNaN(Number(variant))) {
                weight = Number(variant);
            } else {
                if(variant === 'italic') {
                    style = 'italic';
                }
                if(variant === 'regular' || variant === 'italic') {
                    weight = 400;
                } else {
                    if(variant.includes('italic')) {
                        style = 'italic';
                        if(!isNaN(Number(variant.replace(/\D+/g, '')))) {
                            weight = Number(variant.replace(/\D+/g, ''));
                        }
                    }
                }
            }
            urlsString += `url("${font.files[variant]}") format('truetype')`;
            familyString += `
                @font-face {
                    font-family: "${family}";
                    font-weight: ${weight};
                    font-style: ${style};
                    src: ${urlsString};
                }
            `;
        });
        style.textContent = familyString;
        document.body.appendChild(style);
    }
});