import React, { FC, useEffect, useState } from 'react';
import { NCropper, cnCropper } from './Cropper.index';
import { Transformer as KonvaTransformer, Rect as KonvaRect, Image as KonvaImage } from 'react-konva';
import { Portal } from 'react-konva-utils';
import { useImage } from '../../../hooks';

import { Rect } from 'konva/lib/shapes/Rect';
import { Star } from 'konva/lib/shapes/Star';
import { Ellipse } from 'konva/lib/shapes/Ellipse';
import { Circle } from 'konva/lib/shapes/Circle';
import { Image } from 'konva/lib/shapes/Image';
import { Line } from 'konva/lib/shapes/Line';
import { Transformer } from 'konva/lib/shapes/Transformer';
import Background from './background.svg';

type KonvaNodes = Rect | Star | Ellipse | Circle | Image | Line;

export const CropperPresenter: FC<NCropper.Props> = ({
    componentRef
}) => {

    const shapeRef = React.createRef<Image>();
    const trRef = React.createRef<Transformer>();

    useEffect(() => {
        // we need to attach transformer manually
        if(!shapeRef.current) return ;
        trRef.current?.nodes([shapeRef.current]);
        trRef.current?.getLayer()?.batchDraw();
    }, [shapeRef]);

    const [ sizes, setSizes ] = useState<NCropper.Sizes>({
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        rotation: 0
    });

    useEffect(() => {
        if(!componentRef?.current || !shapeRef.current || !trRef.current) return ;
        const targetComponent = componentRef?.current as KonvaNodes;
        setSizes({
            x: targetComponent.x(),
            y: targetComponent.y(),
            height: targetComponent.height(),
            width: targetComponent.width(),
            rotation: targetComponent.rotation()
        });
        // shapeRef.current?.x()
    }, [componentRef]);
    
    const onTransformEnd = () => {
        
        const node = shapeRef.current;
        
        if(!node) return;
        const scaleX = node.scaleX();
        const scaleY = node.scaleY();

        // we will reset it back
        node.scaleX(1);
        node.scaleY(1);

        let props = {
            x: node.x(),
            y: node.y(),
            width: Math.max(5, node.width() * scaleX),
            height: Math.max(5, node.height() * scaleY),
        };

        setSizes({
            ...sizes,
            ...props
        });
    }

    const betweenRange = (value: number, [min, max]: [number | undefined, number | undefined]) => {
        if(!min && !max) return value;
        if(!min && max !== undefined) return value >= max ? max : value;
        if(!max && min !== undefined) return value <= min ? min : value;
        if(min !== undefined && max !== undefined) return value < min ? min : value > max ? max : value;
        return value;
    }

    const onTransform = () => {
        if(!componentRef || !shapeRef.current) return null;
        const component = componentRef.current as KonvaNodes;
        const componentParams = {
            ...component.getPosition(),
            ...component.getSize()
        };

        const shape = shapeRef.current;
        const shapeScale = shape.scale();

        const minSize = 20;
        
        const minTop = componentParams.y;
        const maxTop = componentParams.y + componentParams.height;
        const minLeft = componentParams.x;
        const maxLeft = componentParams.x + componentParams.width;

        const x = betweenRange(shape.x(), [minLeft, maxLeft - minSize]);
        const y = betweenRange(shape.y(), [minTop, maxTop - minSize]);
        const width = betweenRange(shape.width() * shapeScale.x, [minSize, maxLeft - x]);
        const height = betweenRange(shape.height() * shapeScale.y, [minSize, maxTop - y]);

        shape.setAttrs({
            scaleX: 1,
            scaleY: 1,
            width,
            height,
            x,
            y
            // width: notMoreSize(Math.max(shape.width() * shapeScale.x, minSize), componentParams.width),
            // height: notMoreSize(Math.max(shape.height() * shapeScale.y, minSize), componentParams.height),
            // x: notMorePosition(componentParams.x, shape.x()),
            // y: notMorePosition(componentParams.y, shape.y())
        });

        // shape.setAttrs({
        //     scaleX: 1,
        //     scaleY: 1,
        //     // x: newProps.x < (componentParams.x + componentParams.width) - minSize ? newProps.x : (componentParams.x + componentParams.width) - minSize,
        //     // y: newProps.y < (componentParams.y + componentParams.height) - minSize ? newProps.y : (componentParams.y + componentParams.height) - minSize,
        // });
        
    }
    
    const [ image ] = useImage(Background, {});

    if(image) {
        image.style.width = '50px';
        image.style.height = '50px';

    }

    return (
        <>
            <KonvaImage
                image={image}
                ref={shapeRef}
                {...sizes}
                // opacity={.5}
                // fill={'#000'}
                // fillPatternImage={image}
                // fillPatternOffset={{
                //     x: 0,
                //     y: 0,
                // }}
                // fillPatternRepeat={'no-repeat'}
                draggable={false}
            />
            <Portal selector=".overflow" enabled={true}>
                <KonvaTransformer
                    draggable={false}
                    ignoreStroke={true}
                    ref={trRef}
                    rotateEnabled={false}
                    onTransform={onTransform}
                    onTransformEnd={onTransformEnd}
                    anchorCornerRadius={50}
                    // enabledAnchors={anchors}
                    // rotateAnchorOffset={25}
                    // shouldOverdrawWholeArea={true}
                    // onMouseOver={() => {
                    //     document.body.style.cursor = 'pointer';
                    // }}
                    // onMouseOut={() => {
                    //     document.body.style.cursor = 'default';
                    // }}
                    boundBoxFunc={(oldBox, newBox) => {
                        // limit resize
                        if (newBox.width < 5 || newBox.height < 5) {
                            return oldBox;
                        }
                        return newBox;
                    }}
                />
            </Portal>
        </>
    )
}