import { cn } from "@bem-react/classname";
import { AnyAction, bindActionCreators, Dispatch } from "redux";
import { IClassNameProps } from "../../types.base";
import { itemsCreate } from '../../redux/items/actions';
import { IStateType } from "../../redux/store";
import { getBaseField } from "../../redux/base/selectors";
import { NBase } from "../../redux/base/interface";

export const cnMenu = cn('Menu');

export namespace NMenu {

    export interface Props extends OwnProps, ReturnType<typeof mapMenuStateToProps>, ReturnType<typeof mapMenuActionsToProps> {

    }

    export interface OwnProps extends IClassNameProps {
        openedValue: NBase.Reducer['openedMenu']
    }
}

export const mapMenuStateToProps = (state: IStateType) => ({
    openedMenu: getBaseField('openedMenu')(state)
});

export const mapMenuActionsToProps = (d: Dispatch<AnyAction>) => bindActionCreators({
    itemsCreate
}, d);