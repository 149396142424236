import React, { ComponentProps, FC, useMemo } from 'react';
import { connect } from 'react-redux';
import { Layer } from './Layer/Layer';
import { NLayersSettings, cnLayersSettings, mapLayersSettingsStateToProps, mapLayersSettingsActionsToProps } from './Layers.index';
import List from '@mui/material/List';
import { Container, Draggable } from 'react-smooth-dnd';
import { drop } from 'lodash';

const LayersSettingsPresenter: FC<NLayersSettings.Props> = ({
    layers,
    itemsChangeField
}) => {

    const reversed = useMemo(() => {
        const reversedLayers = [ ...layers ];
        reversedLayers.reverse();
        return reversedLayers;
    }, [layers]);
    
    const onDrop: ComponentProps<typeof Container>['onDrop'] = ({ removedIndex, addedIndex }) => {
        let newLayers = [ ...reversed ];
        let itemToMove: string | undefined;
        if(removedIndex !== null) itemToMove = drop(newLayers, removedIndex)[0];
        if(addedIndex !== null && itemToMove) {
            newLayers = newLayers.filter(e => e !== itemToMove);
            newLayers.splice(addedIndex, 0, itemToMove);
            newLayers.reverse();
            itemsChangeField({ field: 'layers', value: newLayers });
        }
    };

    return (
        <List className={cnLayersSettings()}>
            <Container
                dragHandleSelector={'.drag-handle'}
                lockAxis={'y'}
                onDrop={onDrop}
            >
            {
                reversed.map(el => {
                    return (
                        <Draggable key={el} className={'drag-handle'}>
                            <Layer key={el} id={el} />
                        </Draggable>
                    )
                })
            }
            </Container>
        </List>
    )
}

export const LayersSettings = connect(
    mapLayersSettingsStateToProps,
    mapLayersSettingsActionsToProps
)(LayersSettingsPresenter);