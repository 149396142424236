import { useEffect, useState, createContext } from "react";
import { Block } from './Blocks.types';

const eventName = 'extendItem';

const baseBlocks: Block[] = [];

type ExtensionBlocksContext = {
    blocks: typeof baseBlocks;
}

export const ExtensionBlocksContext = createContext<ExtensionBlocksContext>({
    blocks: []
});

export const useBlocks = () => {

    const [ blocks, setBlocks ] = useState<typeof baseBlocks>([]);

    useEffect(() => {
        window.addEventListener("message", (event) => {
            if(event.data.event === eventName) {
                const data = event.data.data as typeof baseBlocks;
                setBlocks(data);
            }
        });
    }, []);

    useEffect(() => {
        console.log('blocks', blocks);
    }, [ blocks ]);

    return {
        blocks
    };
};

const testImage = 'https://upload.wikimedia.org/wikipedia/commons/9/9a/Gull_portrait_ca_usa.jpg';

const extendsBlockPostMessage: {
    event: 'extendItem';
    data: ExtensionBlocksContext['blocks'];
} = {
    event: 'extendItem',
    data: [
        {
            id: '23123123',
            img: testImage,
            name: 'test',
            items: [
                {
                    id: 'dasdasd',
                    fixed: false,
                    name: 'ExtendItem',
                    type: 'Image',
                    previewUrl: testImage,
                    permamenltyFixed: false,
                    fixPosition: false,
                    avaliableEditors: [],
                    props: {
                        url: testImage
                    } as any
                }
            ]
        }
    ]
};

// window.postMessage(extendsBlockPostMessage);