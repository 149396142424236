import {
    ITEMS_CHANGE_FIELD,
    ITEMS_CHANGE,
    ITEMS_ADD,
    ITEMS_SELECT,
    ITEMS_CREATE,
    ITEMS_REMOVE,
    ITEMS_REMOVE_SELECTED,
    ITEMS_MOVE,
    ITEMS_SYSTEM_CREATE,
    ITEMS_SYSTEM_ADD,
    ITEMS_SYSTEM_REMOVE,
    ITEMS_SYSTEM_SET,
    ITEMS_CREATE_FLATICON,
    ITEMS_CREATE_EXTENSION,
    ITEMS_CHANGE_REUCER
} from './actions.types';
import { NItems } from './interface';

/**
 * set items reducer
 * @param payload 
 * @returns 
 */
export const itemsChangeReducer = (payload: NItems.NActions.ItemsChangeReducer['payload']): NItems.Actions => ({
    type: ITEMS_CHANGE_REUCER,
    payload
});

/**
 * set items reducer field
 * @param payload 
 * @returns 
 */
export const itemsChangeField = (payload: NItems.NActions.ItemsChangeField['payload']): NItems.Actions => ({
    type: ITEMS_CHANGE_FIELD,
    payload
});

/**
 * change reducer item in items object
 * @param payload 
 * @returns 
 */
export const itemsChange = (payload: NItems.NActions.ItemsChangePayload): NItems.Actions => ({
    type: ITEMS_CHANGE,
    payload
});

/**
 * add new item in items object
 * @param payload 
 * @returns 
 */
export const itemsAdd = (payload: NItems.NActions.ItemsAddPayload): NItems.Actions => ({
    type: ITEMS_ADD,
    payload
});

/**
 * create new item in saga by type in payload
 * @param payload 
 * @returns 
 */
export const itemsCreate = (payload: NItems.NActions.ItemsCreatePayload): NItems.Actions => ({
    type: ITEMS_CREATE,
    payload
});

/**
 * create new item in saga by type in payload
 * @param payload 
 * @returns 
 */
export const itemsCreateFlatIcon = (payload: NItems.NActions.ItemsCreateFlatIconPayload): NItems.Actions => ({
    type: ITEMS_CREATE_FLATICON,
    payload
});

/**
 * create new item in saga by type in payload
 * @param payload 
 * @returns 
 */
export const itemsCreateExtension = (payload: NItems.NActions.ItemsCreateExtensionPayload): NItems.Actions => ({
    type: ITEMS_CREATE_EXTENSION,
    payload
});

/**
 * select item to change props
 * @param payload 
 * @returns 
 */
export const itemsSelect = (payload: NItems.NActions.Select['payload']): NItems.Actions => ({
    type: ITEMS_SELECT,
    payload
});

/**
 * clear selected item
 * @returns 
 */
export const itemsRemoveSelected = (): NItems.Actions => ({
    type: ITEMS_REMOVE_SELECTED,
});

/**
 * remoce item from layers array, in items object item not remove
 * @param payload 
 * @returns 
 */
export const itemsRemove = (payload: NItems.NActions.ItemsRemove['payload']): NItems.Actions => ({
    type: ITEMS_REMOVE,
    payload
});

/**
 * sort leyers array
 * @param payload 
 * @returns 
 */
export const itemsMove = (payload: NItems.NActions.ItemsMove['payload']): NItems.Actions => ({
    type: ITEMS_MOVE,
    payload
});

/**
 * add system item to system layers array for user comfort
 * @param payload 
 * @returns 
 */
export const itemsSystemCreate = (payload: NItems.NActions.ItemsSystemCreate['payload']) => ({
    type: ITEMS_SYSTEM_CREATE,
    payload
});

/**
 * add system item to system layers array for user comfort
 * @param payload 
 * @returns 
 */
export const itemsSystemAdd = (payload: NItems.NActions.ItemsSystemAdd['payload']) => ({
    type: ITEMS_SYSTEM_ADD,
    payload
});

/**
 * remove system item from system layers
 * @param payload 
 * @returns 
 */
export const itemsSystemRemove = (payload: NItems.NActions.ItemsSystemRemove['payload']) => ({
    type: ITEMS_SYSTEM_REMOVE,
    payload
});

/**
 * set system layers or clear it if payload null
 * @param payload 
 * @returns 
 */
export const itemsSystemSet = (payload: NItems.NActions.ItemsSystemSet['payload']) => ({
    type: ITEMS_SYSTEM_SET,
    payload
});