import { flatIconsAuthentication } from "./flaticons.api";
import { FlatIconSettigns } from "./flaticons.models";

class FlatIconCl {
    private _settings: FlatIconSettigns = {
        api: '',
        token: '',
        expires: 0
    };

    public set apikey(data: Required<FlatIconSettigns>['api']) {
        this._settings.api = data;
    };

    public get apikey() {
        return this._settings.api;
    };

    // public get settings() {
    //     return this._settings;
    // };

    public async token() {

        if(!this.apikey) {
            console.error('FlatIcon apikey is undefined');
            return '';
        };

        if(!this._settings.token || this._settings.expires < new Date().getTime()) {
            const d = await flatIconsAuthentication(this.apikey);
            this._settings = {
                ...this._settings,
                ...d.data
            };
        };

        return this._settings.token;

    };

};

export const FlatIcon = new FlatIconCl();