import { Layer as JSLayer } from 'konva/lib/Layer';
export const asd = '';

declare global {
    interface Window {
        flatIconKey: string | null;
        konvaMainLayer: JSLayer | null;
        debugConsole?: boolean;
    }
};

window.konvaMainLayer = null;