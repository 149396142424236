import React, { ComponentProps, FC } from 'react';
import { createSvgIcon, styled } from '@mui/material';

const ImageIconMUIIcon = createSvgIcon(
    <path d="" />,
    'ImageIcon',
);

const ImageIconPr: FC<{
    url: string
} & ComponentProps<typeof ImageIconMUIIcon>>= ({
    url,
    ...props
}) => {
    return <ImageIconMUIIcon {...props}/>
};

export const ImageIcon = styled(ImageIconPr)(({
    url
}) => ({
    background: `url(${url})`,
    backgroundSize: 'contain'
}));