import { keyBy, union } from "lodash";
import { Reducer } from "react";
import { TMPLS_ADD_TEMPLATE, TMPLS_ADD_TEMPLATES, TMPLS_REMOVE_TEMPLATE, TMPLS_SET_TEMPLATES, TMPLS_SET_TEMPLATES_LIST, TMPLS_UPDATE_TEPMLATE } from "./actions.types.Templates";
import { baseTemplatesReducer } from "./base.Templates";
import { NTemplates } from "./types.Templates";

export const templatesReducer: Reducer<NTemplates.Reducer, NTemplates.Actions> = (state = baseTemplatesReducer, action) => {
    switch (action.type) {
        case TMPLS_SET_TEMPLATES: {
            return {
                ...state,
                templates: keyBy(action.payload, (template) => template.id),
                list: action.payload.map(template => template.id)
            };
        };
        case TMPLS_ADD_TEMPLATE: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    [action.payload.id]: action.payload
                },
                list: union([ ...state.list, action.payload.id ])
            };
        };
        case TMPLS_ADD_TEMPLATES: {
            const templates = keyBy(action.payload, 'id');
            return {
                ...state,
                templates: {
                    ...state.templates,
                    ...templates
                },
                list: union([ ...state.list, ...action.payload.map(el => el.id) ])
            };
        };
        case TMPLS_REMOVE_TEMPLATE: {
            return {
                ...state,
                list: state.list.filter(id => id !== action.payload)
            };
        };
        case TMPLS_SET_TEMPLATES_LIST: {
            return {
                ...state,
                list: action.payload
            };
        };
        case TMPLS_UPDATE_TEPMLATE: {
            return {
                ...state,
                templates: {
                    ...state.templates,
                    [action.payload.id]: {
                        ...state.templates[action.payload.id],
                        ...action.payload
                    }
                }
            }
        }
        default: {
            return state;
        };
    };
};