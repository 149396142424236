import React, { FC, useState, ComponentProps } from 'react';
import { NBackgroundColor } from './BackgroundColor.index';
import { SketchPicker } from 'react-color';
import { useDebounce } from '../../../../utils';
import { Wrapper } from '../Wrapper/Wrapper';
import FormatColorFill from '@mui/icons-material/FormatColorFill';
import { styled } from '@mui/system';

const StyledFormatColorFillIcon = styled(FormatColorFill)({
    filter: 'drop-shadow(0px 0px 1px rgb(0 0 0 / 0.4))'
});

export const BackgroundColor: FC<NBackgroundColor.Props> = ({
    fill = '#ffffff',
    ButtonProps,
    onChange
}) => {

    type Dfss = Required<Pick<ComponentProps<typeof SketchPicker>, 'onChange'>>;

    const onColorChange = useDebounce<Dfss['onChange']>((color, e) => {
        onChange({
            fill: color.hex
        });
    }, 5, []);

    return (
        <Wrapper
            icon={<StyledFormatColorFillIcon />}
            ButtonProps={{
                ...ButtonProps,
                sx: {
                    color: fill !== '#ffffff' ? fill : undefined
                }
            }}
            PopoverProps={{
                PaperProps: {
                    sx: {
                        width: 'auto'
                    }
                }
            }}
        >
            <SketchPicker
                color={fill}
                onChange={onColorChange}
            />
        </Wrapper>
    )
}