import { cn } from '@bem-react/classname';
import { getItemsField, getItemsItemById } from '../../../redux/items/selectors';
import { itemsChange, itemsChangeField, itemsMove, itemsRemove } from '../../../redux/items/actions';
import { IStateType } from '../../../redux/store';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';

export const cnSettingsItem = cn('SettingsItem');

export namespace NSettingsItem {

    export interface Props extends OwnProps, ReturnType<typeof mapSettingsItemStateToProps>, ReturnType<typeof mapSettingsItemActionToProps> {
        
    };

    export interface OwnProps {
        
    };
};

export const mapSettingsItemStateToProps = (state: IStateType, props: NSettingsItem.OwnProps) => {
    const selected = getItemsField('select')(state);
    return {
        selectedItem: selected && getItemsItemById(selected)(state) || null,
        croppingItem: getItemsField('crop')(state)
    }
};

export const mapSettingsItemActionToProps = (d: Dispatch<AnyAction>) => bindActionCreators({
    itemsChange,
    itemsChangeField,
    itemsMove,
    itemsRemove
}, d);