import { useAppDispatch, useAppSelector } from "../hooks";
import { settingsChangeField, settingsChangeLayout } from "./actions";
import { getKonvaSettings, getKonvaSettingsField } from "./selectors";

/**
 * hook for base konva settings reducer with actions
 * @returns 
 */
export const useKonvaSettings = () => {

    const dispatch = useAppDispatch();

    const settings = useAppSelector(getKonvaSettings);

    const changeSettingsField = (...args: Parameters<typeof settingsChangeField>) => {
        dispatch(settingsChangeField(...args));
    };

    return {
        settings,
        changeSettingsField
    };
};

/**
 * hook for konva layout settings and change it
 */
export const useKonvaLayout = () => {

    const dispatch = useAppDispatch();

    const layout = useAppSelector(getKonvaSettingsField('layout'));

    const onChange = (...args: Parameters<typeof settingsChangeLayout>) => {
        dispatch(settingsChangeLayout(...args));
    };

    return {
        layout,
        onChange
    };
};