import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { NItemsMenu, cnItemsMenu, mapItemsMenuStateToProps, mapItemsMenuActionsToProps } from './ItemsMenu.index';
import { MenuButton, StyledButtonGroup, ItemsMenuWrapper } from './ItemsMenu.styled';
import { KonvaComponents } from '../Konva';
import Circle from './imgs/circle.png';
import Ellipse from './imgs/ellipse.png';
import Rect from './imgs/rect.png';
import Star from './imgs/star.png';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { connect } from 'react-redux';
import DynamicFeedIcon from '@mui/icons-material/DynamicFeed';
import { FlatIcons } from '../../extensions/FlatIcons/FlatIcons';
import { ExtensionBlocksContext } from '../../extensions/Blocks/Blocks.hooks';
import { SelectItem } from '../SelectItemDialog/SelectItemDialog';
import { usePryanikyEmbedding } from '../../embed/pryaniky';
import { i18next } from '../../localization';

const typeIcons = {
    Image: <UploadFileIcon />,
    Text: <TextFieldsIcon />
}

const typeImgs = {
    Circle,
    Ellipse,
    Rect,
    Star
}

const hideComponents: (keyof typeof KonvaComponents)[] = [ 'Line', 'FlatIconsImage' ];

const ItemsMenuPr: FC<NItemsMenu.Props> = ({
    openedMenu,
    itemsCreate,
    itemsCreateFlatIcon,
    itemsCreateExtension,
    baseChangeField,
    className,
    ...props
}) => {

    const { blocks } = useContext(ExtensionBlocksContext);

    const [ allowedComponents, setAllowedComponents ] = useState<(keyof typeof KonvaComponents)[] | null>(null);

    const { 
        getAllowedComponents
    } = usePryanikyEmbedding();

    useEffect(() => {
        getAllowedComponents?.()
        .then(d => {
            setAllowedComponents(d || null);
        })
        .catch(e => {
            console.error(e);
        });
    }, []);

    return (
        <ItemsMenuWrapper
            className={cnItemsMenu({}, [className])}
        >   
            <StyledButtonGroup>
            {
                Object.typedKeys(KonvaComponents).filter(e => !hideComponents.includes(e)).map((type) => {
                    const icon = typeIcons[type as keyof typeof typeIcons];
                    const img = typeImgs[type as keyof typeof typeImgs];
                    let isAllowed = true;
                    if(allowedComponents && !allowedComponents.includes(type)) isAllowed = false;
                    return (
                        <MenuButton
                            key={type}
                            variant="outlined"
                            onClick={() => {
                                isAllowed && itemsCreate(type);
                            }}
                            disabled={!isAllowed}
                            title={i18next.t(`editor.${type.toLowerCase()}`)}
                        >   
                            {
                                icon ? 
                                icon :
                                img ?
                                <img src={img} /> :
                                type
                            }
                        </MenuButton>
                    )
                })
            }
                <FlatIcons
                    key={'flatIconsButton'}
                    onIconClick={(e) => {
                        itemsCreateFlatIcon({ url: e.images[128] });
                    }}
                />
            {
                blocks.map(block => {
                    return (
                        <MenuButton
                            key={block.name || block.id}
                            variant="outlined"
                            onClick={() => {
                                if(block.items.length === 1) {
                                    itemsCreateExtension(block.items[0]);
                                } else {
                                    SelectItem({ items: block.items }).then(({ item }) => {
                                        itemsCreateExtension(item);
                                    })
                                }
                            }}
                        >   
                            <img src={block.items.length === 1 ? block.items[0].previewUrl : block.img || block.img} />
                        </MenuButton>
                    )
                })
            }
                <MenuButton
                    key={'layersButton'}
                    variant={openedMenu === 'layers' ? "contained" : "outlined"}
                    title={i18next.t('editor.layers')}
                    onClick={() => {
                        baseChangeField({
                            field: 'openedMenu',
                            value: openedMenu === 'layers' ? null : 'layers'
                        });
                    }}
                >   
                    <DynamicFeedIcon />
                </MenuButton>
            </StyledButtonGroup>
        </ItemsMenuWrapper>
    )
}

export const ItemsMenu = connect(
    mapItemsMenuStateToProps,
    mapItemsMenuActionsToProps
)(ItemsMenuPr);