import { Box, Button, ButtonGroup, Paper, styled } from '@mui/material';

export const ItemsMenuWrapper = styled('div')({
    display: 'flex',
    justifyContent: 'center'
});

export const MenuButton = styled(Button)((props) => ({
    background: props.variant === 'contained' ? undefined : '#fff',
    fontSize: 32,
    width: 64,
    "& img": {
        maxWidth: 32,
        maxHeight: 32
    },
    "&:hover": {
        background: props.variant === 'contained' ? undefined : '#fff',
        boxShadow: '0px 0px 3px #000',
        zIndex: 1
    }
}));

export const SettingsPaper = styled(Paper)({
    width: 300,
    padding: 16,
    boxSizing: 'border-box'
});

export const StyledButtonGroup = styled(ButtonGroup)({
    minHeight: 44
});