import React, { FC, useState, ComponentProps } from 'react';
import { NTextColor } from './TextColor.index';
import { SketchPicker } from 'react-color';
import { useDebounce } from '../../../../utils';
import { Wrapper } from '../Wrapper/Wrapper';
import FormatColorText from '@mui/icons-material/FormatColorText';

export const TextColor: FC<NTextColor.Props> = ({
    fill = '#ffffff',
    ButtonProps,
    onChange
}) => {

    type Dfss = Required<Pick<ComponentProps<typeof SketchPicker>, 'onChange'>>;

    const onColorChange = useDebounce<Dfss['onChange']>((color, e) => {
        onChange({
            fill: color.hex
        });
    }, 5, []);

    return (
        <Wrapper
            icon={<FormatColorText />}
            ButtonProps={{
                ...ButtonProps,
                sx: {
                    color: fill !== '#ffffff' ? fill : undefined
                }
            }}
            PopoverProps={{
                PaperProps: {
                    sx: {
                        width: 'auto'
                    }
                }
            }}
        >
            <SketchPicker
                color={fill}
                onChange={onColorChange}
            />
        </Wrapper>
    )
}