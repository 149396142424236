import { WithDialog } from "../DialogCreate";
import { cn } from '@bem-react/classname';
import { NItems } from '../../redux/items/interface';

export const cnSelectItem = cn('SelectItemDialog');

export namespace NSelectItem {

    interface PropsOwn {
        items: (NItems.IItemObj[keyof NItems.IItemObj])[]
    }

    export interface Rezult {
        item: PropsOwn['items'][number];
    }

    export type Props = PropsOwn & WithDialog<Rezult>;
}