import React, { FC } from 'react';
import { NClicker } from './Clicker.index';
import { MenuButton } from '../../styled.components';
import EditIcon from '@mui/icons-material/Edit';

export const Clicker: FC<NClicker.Props> = ({
    icon,
    ButtonProps,
    onChange
}) => {

    return (
        <>
            <MenuButton
                {...ButtonProps}
                onClick={() => onChange()}
            >
                {icon}
            </MenuButton>
        </>
    )
}