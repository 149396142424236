import React, { ComponentProps, FC, HTMLProps, useState, useRef, createRef } from 'react';
import { createDialog } from '../../DialogCreate';
import { NSaveDialog, cnSaveDialog } from './SaveDialog.index';
import './SaveDialog.scss';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button, TextField } from '@mui/material';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const SaveDialogPr: FC<NSaveDialog.Props> = ({
    handleAccept,
    handleClose
}) => {

    const [ name, setName ] = useState('');

    return (
        <>
            <DialogTitle>Saving</DialogTitle>
            <DialogContent className={cnSaveDialog('Content')}>
                <TextField
                    fullWidth
                    label={'Name'}
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={() => {
                    handleAccept({
                        name
                    });
                }}>Accept</Button>
            </DialogActions>
        </>
    )
}

export const SaveDialog = createDialog(SaveDialogPr);