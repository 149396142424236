import { NUsersData } from './interface';
import {
    USERSDATA_CHANGE_FIELD,
} from './actions.types';
import { baseReducer } from './constants';

export const usersData = (state: NUsersData.Reducer = baseReducer, action: NUsersData.Actions): NUsersData.Reducer => {
    switch (action.type) {
        case USERSDATA_CHANGE_FIELD: {
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }
        }
        default:
            return state;
    }
}