import React, { FC, useEffect, useRef } from 'react';
import { TransformWrapper, TransformComponent, ReactZoomPanPinchContentRef } from "react-zoom-pan-pinch";
import { useZoomerDisabled } from './Zoomer.hooks';
import { useZoomerContextChange } from './Zoomer.context';

export const Zoomer: FC<{}> = ({
    children
}) => {

    const disabled = useZoomerDisabled();

    const ref = useRef<ReactZoomPanPinchContentRef>(null);

    const zoomerContextChange = useZoomerContextChange();

    useEffect(() => {
        if(ref.current) zoomerContextChange(ref.current);
    }, []);

    return (
        <TransformWrapper
            centerOnInit
            minScale={.5}
            disabled={disabled}
            ref={ref}
        >
            <TransformComponent>
                {children}
            </TransformComponent>
        </TransformWrapper>
    );
};