import queryString from 'query-string';
import {
    FlatIconSettigns,
    FlatIconSettignsResponse,
    FlatIconsGetItemParams,
    FlatIconsGetItemResponse,
    FlatIconsGetPackParams,
    FlatIconsGetPackResponse,
    FlatIconsSearchItemsParams,
    FlatIconsSearchItemsResponse,
    FlatIconsSearchPacksParams,
    FlatIconsSearchPacksResponse,
} from './flaticons.models';
import { FlatIcon } from './flaticons.settings';

export const flatIconsAuthentication = (apikey: FlatIconSettigns['api']) => {

    return flatIconFetch<FlatIconSettignsResponse>({
        method: 'POST',
        url: '/app/authentication',
        headers: {
            'Content-Type':'multipart/form-data',
            Authorization: ''
        },
        body: {
            apikey
        },
        ignoreToken: true
    })
}

type Params = {
    method: 'POST' | 'GET';
    url: string;
    headers?: {[key: string]: any};
    params?: {[key: string]: any};
    body?: any;
    version?: number;
    arrayFormat?: 'bracket' | 'index' | 'comma' | 'none';
    ignoreToken?: boolean
};

// export const flatIconFetch = async <R extends {[s: string]: any} = {}> ({
//     method,
//     url,
//     headers = {},
//     params = {},
//     body = {},
//     version = 3,
//     arrayFormat = 'none'
// }: Params): Promise<R> => {
//     try {
//         return flatIconFetchOld({
//             method,
//             url,
//             headers,
//             params,
//             body,
//             version,
//             arrayFormat
//         });
//     } catch (error) {
//         console.error('get FlatIcons fetch errror');
//         return new Promise((rez, rej) => {

//         });
//     }
// }

export const flatIconFetch = async <R extends {[s: string]: any} = {}> ({
    method,
    url,
    headers = {},
    params = {},
    body = {},
    version = 3,
    arrayFormat = 'none',
    ignoreToken = false
}: Params): Promise<R> => {

    if(!AbortController) return flatIconXMLHttp<R>({ method, url, headers, params, body, version, arrayFormat });

    let token = '';

    if(!ignoreToken) {
        token = await FlatIcon.token();
    }
    
    if(!token && !ignoreToken) throw new Error('FlatIcon token error: "token is empty"');

    const opt: RequestInit = {
        headers: {
            'Accept':'application/json',
            'Authorization': `Bearer ${token}`,
            ...headers,
        },
        method
    };

    const stringParams = '?' +  queryString.stringify(params, { arrayFormat });

    switch (method) {
        case 'GET': {
            return fetch(`https://api.flaticon.com/v${version}` + url + stringParams, opt).then(d => d.json());
        };
        case 'POST': {
            if(headers['Content-Type'] === 'multipart/form-data') {
                delete (opt.headers as any)['Content-Type'];
                const formData = new FormData();
                for(const name in body) {
                  formData.append(name, body[name]);
                }
                opt.body = formData;
            } else {
                opt.body = JSON.stringify(body);
            }
            return fetch(`https://api.flaticon.com/v${version}` + url + stringParams, opt).then(d => d.json());
        };
    };

};

export const flatIconXMLHttp = async <R extends {[s: string]: any} = {}> ({
    method,
    url,
    headers = {},
    params = {},
    body = {},
    version = 3,
    arrayFormat = 'none',
    ignoreToken = false
}: Params): Promise<R> => {

    let token = '';

    if(!ignoreToken) {
        token = await FlatIcon.token();
    }

    if(!token && !ignoreToken) throw new Error('FlatIcon token error: "token is empty"');

    const stringParams = '?' +  queryString.stringify(params, { arrayFormat });

    const xhr: XMLHttpRequest = new XMLHttpRequest();

    xhr.open(method, `https://api.flaticon.com/v${version}` + url + stringParams);

    return new Promise<R>((resolve, reject) => {

        xhr.setRequestHeader('Authorization', token);

        for (const hname in headers) {
          xhr.setRequestHeader(hname, headers[hname]);
        }

        xhr.onload = function () {
          if (this.status >= 200 && this.status < 300) {
            resolve(JSON.parse(xhr.response));
          } else {
            reject({
              message: xhr.statusText,
              status: xhr.status,
              statusText: xhr.statusText,
            });
          }
        };

        xhr.onerror = function () {
          reject({
            message: xhr.statusText,
            status: xhr.status,
            statusText: xhr.statusText,
          });
        };

        switch (method) {
            case 'GET': {
                xhr.send();
                break;
            }
            case 'POST': {
                xhr.send(JSON.stringify(body));
                break;
            }
        }
    });
}

export const flatIconsSearchItem = async ({ orderBy = 'priority', ...params }: FlatIconsSearchItemsParams) => {
    return flatIconFetch<FlatIconsSearchItemsResponse>({
        method: 'GET',
        url: `/search/icons/${orderBy}`,
        params
    });
};

export const flatIconsSearchPack = async (params: FlatIconsSearchPacksParams) => {
    return flatIconFetch<FlatIconsSearchPacksResponse>({
        method: 'GET',
        url: `/search/packs`,
        params
    });
};

export const flatIconsGetItem = async ({ id }: FlatIconsGetItemParams) => {
    return flatIconFetch<FlatIconsGetItemResponse>({
        method: 'GET',
        url: `/item/icon/${id}`
    });
};

export const flatIconsGetPack = async ({ id }: FlatIconsGetPackParams) => {
    return flatIconFetch<FlatIconsGetPackResponse>({
        method: 'GET',
        url: `/search/icons/${id}`
    });
};