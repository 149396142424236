import { NItems } from './interface';
import {
    ITEMS_ADD,
    ITEMS_CHANGE,
    ITEMS_CHANGE_FIELD,
    ITEMS_CHANGE_REUCER,
    ITEMS_MOVE,
    ITEMS_REMOVE,
    ITEMS_REMOVE_SELECTED,
    ITEMS_SELECT,
    ITEMS_SYSTEM_ADD,
    ITEMS_SYSTEM_REMOVE,
    ITEMS_SYSTEM_SET
} from './actions.types';
import { baseReducer } from './constants';
import { union } from 'lodash';

export const items = (state: NItems.Reducer = baseReducer, action: NItems.Actions): NItems.Reducer => {
    switch (action.type) {
        case ITEMS_CHANGE_REUCER: {
            return {
                ...state,
                ...action.payload
            }
        }
        case ITEMS_ADD: {
            return {
                ...state,
                select: action.payload.permamenltyFixed ? state.select : action.payload.id,
                items: {
                    ...state.items,
                    [action.payload.id]: action.payload
                },
                layers: [...state.layers, action.payload.id]
            }
        }
        // need refactor
        case ITEMS_CHANGE: {
            const { payload } = action as any;
            if(!payload.id) return state;
            return {
                ...state,
                items: {
                    ...state.items,
                    [payload.id]: {
                        ...state.items[payload.id],
                        ...payload,
                        props: {
                            ...state.items[payload.id].props,
                            ...payload.props
                        }
                    }
                }
            }
        }
        case ITEMS_SELECT: {
            return {
                ...state,
                crop: null,
                edit: null,
                select: action.payload
            }
        }
        case ITEMS_CHANGE_FIELD: {
            return {
                ...state,
                [action.payload.field]: action.payload.value
            }
        }
        case ITEMS_REMOVE_SELECTED: {
            if(!state.select) return state;
            return {
                ...state,
                select: null,
                crop: null,
                edit: state.edit === state.select ? null : state.edit,
                layers: state.layers.filter(el => el !== state.select),
                systemLayers: state.systemLayers.filter(el => state.select && !el.includes(state.select) || true)
            }
        }
        case ITEMS_REMOVE: {
            return {
                ...state,
                crop: action.payload === state.edit ? null : state.edit,
                edit: action.payload === state.edit ? null : state.edit,
                select: action.payload === state.select ? null : state.select,
                layers: state.layers.filter(el => el !== action.payload),
                systemLayers: state.systemLayers.filter(el => !el.includes(action.payload))
            }
        }
        case ITEMS_MOVE: {
            const moveFrom = state.layers.findIndex(e => e === action.payload.id);
            if(moveFrom === -1) return state;
            const moveTo = action.payload.direction === 'up' ? moveFrom + 1 : moveFrom - 1;
            const layers = [ ...state.layers.filter(e => e !== action.payload.id) ];
            layers.splice(moveTo, 0, action.payload.id);
            // newLayers.slice();
            return {
                ...state,
                layers
            }
        }
        case ITEMS_SYSTEM_ADD: {
            return {
                ...state,
                systemLayers: [ ...state.systemLayers, action.payload.id ],
                items: {
                    ...state.items,
                    [action.payload.id]: action.payload
                }
            }
        }
        case ITEMS_SYSTEM_REMOVE: {
            return {
                ...state,
                systemLayers: state.systemLayers.filter(el => el !== action.payload)
            }
        }
        case ITEMS_SYSTEM_SET: {
            return {
                ...state,
                systemLayers: union(action.payload?.map(el => el.id) || []),
                items: {
                    ...state.items,
                    ...(action.payload?.reduce((a, c) => ({ ...a, [c.id]: c }), {} as typeof state.items) || {})
                }
            }
        }
        default:
            return state;
    }
}