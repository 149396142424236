import React, { FC, useEffect, useMemo } from 'react';
import { NSelectPositionWrapper, cnSelectPositionWrapper } from './SelectPositionWrapper.index';
import { Box } from '@mui/system';
import { useSelectItem } from '../../../redux/items/hooks';
import { styled } from '@mui/material';

const StyledBox = styled(Box)({
    position: 'absolute',
    zIndex: 1,
    transformOrigin: '0 0 0',
    width: 0,
    height: 0
});

const SelectPositionWrapperPresenter: FC<NSelectPositionWrapper.Props> = ({
    children
}) => {
    
    const {
        item
    } = useSelectItem();

    const position = useMemo(() => {
        if(item?.props?.positionByCenter) {
            return {
                left: (item?.props?.x || 0) + ((item?.props?.width || 0) / 2),
                top: (item?.props?.y || 0) - ((item?.props?.height || 0) / 2),
            };
        }
        return {
            left: (item?.props?.x || 0) + (item?.props?.width || 0),
            top: item?.props?.y,
        };
    }, [item?.props?.x, item?.props?.width, item?.props?.y, item?.props?.positionByCenter]);

    if(!item) return null;

    return (
        <StyledBox className={cnSelectPositionWrapper()} sx={{
            ...position,
            // left: (item.props.x || 0) + (item.props.width || 0),
            // top: item.props.y,
            transform: `rotateZ(${item.props.rotation}deg)`
        }}>
            {children}
        </StyledBox>
    )
};

export const SelectPositionWrapper = SelectPositionWrapperPresenter;