import React, { FC, ComponentProps } from "react";
import { Ellipse as KonvaEllipse } from 'react-konva';
import { NEllipse } from './Ellipse.index';

const EllipsePresenter: FC<NEllipse.Props> = ({
    forwardRef,
    ...props
}) => {

    return (
        <KonvaEllipse
            {...props}
            ref={forwardRef}
        />
    )
}

export const Ellipse = React.forwardRef<ComponentProps<typeof KonvaEllipse>['ref'], NEllipse.Props>((props, ref) => {
    return <EllipsePresenter {...props} forwardRef={ref} />
});