// import { Background } from './Background/Background';
import { Star } from './Star/Star';
import { Rect } from './Rect/Rect';
import { Line } from './Line/Line';
// import { Group } from './Group/Group';
import { Text } from './Text/Text';
import { Circle } from './Circle/Circle';
import { Ellipse } from './Ellipse/Ellipse';
import { Image } from './Image/Image';
import { FlatIconsImage } from './FlatIconsImage/FlatIconsImage';
import { ComponentProps } from 'react';

export const KonvaComponents = {
    Star,
    // Background,
    Rect,
    Text,
    Circle,
    Ellipse,
    Image,
    Line,
    FlatIconsImage
}

type KonvaComponentsType = typeof KonvaComponents;

type IItems = {
    [Key in keyof KonvaComponentsType]: KonvaComponentsType[Key];
};

export type KonvaComponentsUnion = IItems[keyof IItems];

export type KonvaComponentsUnionProps = ComponentProps<IItems[keyof IItems]>;