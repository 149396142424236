import React, { FC, ComponentProps } from 'react';
import { NOpacity } from './Opacity.index';
import { Box, Slider, TextField } from '@mui/material';
import { useDebounce } from '../../../../utils';
import { Wrapper } from '../Wrapper/Wrapper';
import OpacityIcon from '@mui/icons-material/Opacity';

export const Opacity: FC<NOpacity.Props> = ({
    opacity = 1,
    ButtonProps,
    onChange
}) => {

    type Dfss = Required<Pick<ComponentProps<typeof Slider>, 'onChange'>>;

    const onOpacityChange = useDebounce<Dfss['onChange']>((e, value) => {
        if(Array.isArray(value)) return ;
        onChange({
            opacity: value
        });
    }, 4, []);

    return (
        <Wrapper
            ButtonProps={ButtonProps}
            icon={<OpacityIcon />}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between'
                }}
            >
                <TextField
                    value={opacity}
                    size="small"
                    onChange={(e) => {
                        const match = e.target.value.match(/[\d\.]+/);
                        const number = Number(match && match[0] || undefined);
                        // setvl2(match && match[0] || '');
                        if(isNaN(number) || number > 1) return ;
                        onChange({
                            opacity: number
                        });
                    }}
                    sx={{
                        maxWidth: 60
                    }}
                    inputProps={{
                        style: {
                            textAlign: 'right'
                        }
                    }}
                />
            </Box>
            <Slider
                aria-label="Small steps"
                defaultValue={1}
                value={Number(opacity) || 1}
                onChange={onOpacityChange}
                step={0.01}
                // marks
                min={0}
                max={1}
                valueLabelDisplay="auto"
            />
        </Wrapper>
    )
}