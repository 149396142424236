import React, { ComponentProps, FC, HTMLProps, useState, useRef, createRef } from 'react';
import { createDialog } from '../DialogCreate';
import { NCropDialog, cnCropDialog } from './CropDialog.index';
import './CropDialog.scss';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
import ReactCrop, { Crop } from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

const precentByValue = (value: number, max: number) => (value * 100) / max;
const valueByPrecent = (precent: number, max: number) => (precent * max) / 100;

const CropDialogPr: FC<NCropDialog.Props> = ({
    handleAccept,
    handleClose,
    image
}) => {

    const rezRef = useRef('');

    const [crop, setCrop] = useState<Crop>();

    const imgRef = createRef<HTMLImageElement>();

    const realSize = useRef({
        width: 0,
        height: 0
    });

    const onLoad: HTMLProps<HTMLImageElement>['onLoad'] = (e) => {
        const img = e.target as HTMLImageElement;
        img.classList.add('FullSizeImage');
        realSize.current = {
            height: img.clientHeight,
            width: img.clientWidth
        };
        img.classList.remove('FullSizeImage');
    };

    const onCompletePromise = (crop: Crop) => {

        // calculate new sizes if real image size is biggest then in view;
        const realCrop = { ...crop };
        realCrop.height = valueByPrecent(precentByValue(crop.height, imgRef.current?.clientHeight || crop.height), realSize.current.height);
        realCrop.width = valueByPrecent(precentByValue(crop.width, imgRef.current?.clientWidth || crop.width), realSize.current.width);
        realCrop.x = valueByPrecent(precentByValue(crop.x, imgRef.current?.clientWidth || crop.x), realSize.current.width);
        realCrop.y = valueByPrecent(precentByValue(crop.y, imgRef.current?.clientHeight || crop.x), realSize.current.height);
        
        const canvas = document.createElement('canvas');
        canvas.width = realCrop.width;
        canvas.height = realCrop.height;
        const ctx = canvas.getContext('2d');
        
        // draw cropped image
        if(ctx && imgRef.current) {
            ctx.drawImage(
                imgRef.current,
                realCrop.x,
                realCrop.y,
                realCrop.width,
                realCrop.height,
                0,
                0,
                realCrop.width,
                realCrop.height,
            );
        }

        return new Promise<{ url: string, blob: Blob }>((resolve, reject) => {
            canvas.toBlob(blob => {
                if(blob) {
                    resolve({
                        url: URL.createObjectURL(blob),
                        blob
                    });
                } else {
                    reject();
                }
            }, 'image/png');
        });
    }

    const onComplete: ComponentProps<typeof ReactCrop>['onComplete'] = (crop, ...args) => {
        onCompletePromise(crop).then(({ url }) => {
            rezRef.current = url;
            setCrop(crop);
        });
    }

    return (
        <>
            <DialogTitle>Optional sizes</DialogTitle>
            <DialogContent className={cnCropDialog('Content')}>
                <ReactCrop crop={crop} onChange={c => setCrop(c)} onComplete={onComplete}>
                    <img ref={imgRef} src={image} onLoad={onLoad} />
                </ReactCrop>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Close</Button>
                <Button onClick={() => {
                    if(crop) {
                        handleAccept({
                            image: rezRef.current,
                            height: crop.height,
                            width: crop.width
                        });
                    }
                }}>Accept</Button>
            </DialogActions>
        </>
    )
}

export const CropDialog = createDialog(CropDialogPr);